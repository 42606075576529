import { useState } from 'react';

import { useLanguageStore } from '@trustyou/shared';

import { useResponseAIProfile, useTranslateViaResponseAI } from '../../../../hooks';
import type { Language, ResponseTextFieldId } from '../../../../types';

export function useTranslateResponseAI({
  selectedResponse,
  setSelectedResponse,
  getValues,
  setValue,
}: {
  selectedResponse: ResponseTextFieldId;
  setSelectedResponse: (response: ResponseTextFieldId) => void;
  getValues: (field: ResponseTextFieldId) => string | undefined;
  setValue: (
    field: ResponseTextFieldId,
    value: string | undefined,
    options?: { shouldDirty: boolean }
  ) => void;
}) {
  const { locale } = useLanguageStore();
  const shortLocale = locale.substring(0, 2);

  const translateViaResponseAI = useTranslateViaResponseAI();
  const { data: settings } = useResponseAIProfile();
  const preferredLanguage = (
    settings?.translation_language ?? shortLocale
  ).toLowerCase() as Language;

  const [isTranslateCalled, setIsTranslateCalled] = useState(false);
  const [firstFieldLanguage, setFirstFieldLanguage] = useState<Language>(preferredLanguage);
  const [secondFieldLanguage, setSecondFieldLanguage] = useState<Language>(preferredLanguage);

  // Translates the text returned by the responseAI and sets it to the second field.
  const translate = () => {
    setIsTranslateCalled(true);
    const targetLanguage = isTranslateCalled ? secondFieldLanguage : preferredLanguage;
    setSecondFieldLanguage(targetLanguage);
    translateViaResponseAI.mutate(
      {
        text: getValues('response') ?? '',
        target_language: targetLanguage,
        tone_of_voice: settings?.tone_of_voice ?? 'formal',
      },
      {
        onSuccess: (data) => {
          setValue('translatedResponse', data?.text);
        },
      }
    );
    setSelectedResponse('translatedResponse');
  };

  // Translates the current selected field and applies the result to the other field.
  const updateTranslation = () => {
    if (selectedResponse === 'response') {
      translate();
      return;
    }
    translateViaResponseAI.mutate(
      {
        text: getValues('translatedResponse') ?? '',
        target_language: firstFieldLanguage,
        tone_of_voice: settings?.tone_of_voice ?? 'formal',
      },
      {
        onSuccess: (data) => {
          setValue('response', data?.text);
          setSelectedResponse('response');
        },
      }
    );
  };

  const translateSameField = (targetLanguage: string, fieldId: ResponseTextFieldId) => {
    translateViaResponseAI.mutate(
      {
        text: getValues(fieldId) ?? '',
        target_language: targetLanguage,
        tone_of_voice: settings?.tone_of_voice ?? 'formal',
      },
      {
        onSuccess: (data) => {
          setValue(fieldId, data?.text);
          setSelectedResponse(fieldId);
        },
      }
    );
  };

  return {
    translateViaResponseAI,
    isTranslateCalled,

    firstFieldLanguage,
    setFirstFieldLanguage,

    secondFieldLanguage,
    setSecondFieldLanguage,

    translate,
    translateSameField,
    updateTranslation,
  };
}
