import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import type { BenchmarkDefinitionRule, Entity } from '@trustyou/shared';
import {
  Alert,
  Button,
  Chip,
  StyledFontAwesomeIcon as Icon,
  Stack,
  Typography,
} from '@trustyou/ui';

import { AdditionalEntitiesModal } from './AdditionalEntitiesModal';
import { DefinitionRuleSelector } from './DefinitionRuleSelector/DefinitionRuleSelector';

import { manageDrawer } from '../../../constants/messages/benchmarks';
import { styles } from './styles';

export const EntitiesTab = () => {
  const [isEntitySelectorOpen, setIsEntitySelectorOpen] = useState(false);
  const { setValue, watch, clearErrors, formState } = useFormContext();
  const rules = watch('entities_rules');
  const selectedEntities = watch('additional_entities');

  useEffect(() => {
    if (
      selectedEntities.length ||
      rules.map((rule: BenchmarkDefinitionRule) => rule.values).flat().length
    ) {
      clearErrors('owned_definition');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rules, selectedEntities]);

  const toggleIsEntitySelectorOpen = () => {
    setIsEntitySelectorOpen((state) => !state);
  };

  const onAdditionalEntitiesModalSave = (values: Entity[]) => {
    setValue('additional_entities', values, { shouldDirty: true });
    toggleIsEntitySelectorOpen();
  };

  return (
    <Stack gap={1}>
      {Object.keys(formState.errors).includes('owned_definition') && (
        <Alert severity="error">
          <FormattedMessage {...manageDrawer.noEntitiesError} />
        </Alert>
      )}
      <Stack marginTop={1}>
        <Typography variant="subtitle1">
          <FormattedMessage {...manageDrawer.entitiesRulesHeader} />
        </Typography>
        <Typography variant="body2">
          <FormattedMessage {...manageDrawer.entitiesRulesSecondaryHeader} />
        </Typography>
      </Stack>
      <DefinitionRuleSelector
        rules={rules}
        onChange={(values) => setValue('entities_rules', values, { shouldDirty: true })}
      />
      <Stack marginTop={4} direction="row" justifyContent="space-between">
        <Typography variant="body2">
          <FormattedMessage {...manageDrawer.additionalEntitiesHeader} />
        </Typography>
        {!!selectedEntities.length && (
          <Chip
            label={
              <FormattedMessage
                {...manageDrawer.additionalEntitiesCount}
                values={{ count: selectedEntities.length }}
              />
            }
            color="info"
            variant="pastel"
            onClick={toggleIsEntitySelectorOpen}
          />
        )}
      </Stack>
      <Button sx={styles.selectEntitiesButton} onClick={toggleIsEntitySelectorOpen}>
        <Icon icon={faPlus} />
        <FormattedMessage {...manageDrawer.additionalEntitiesSelectButton} />
      </Button>
      {isEntitySelectorOpen && (
        <AdditionalEntitiesModal
          defaultItems={selectedEntities}
          onClose={toggleIsEntitySelectorOpen}
          onSave={onAdditionalEntitiesModalSave}
        />
      )}
    </Stack>
  );
};
