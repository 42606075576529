import { FormattedMessage, useIntl } from 'react-intl';

import type { StackProps } from '@mui/material';
import { faMemoCircleInfo as faMemoCircleInfoOutlined } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { faMemoCircleInfo as faMemoCircleInfoSolid } from '@trustyou/fortawesome/pro-solid-svg-icons';
import { type Entity, useResponsive } from '@trustyou/shared';
import { Button, IconButton, Link, Stack, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

import type { ReviewRoot } from '../../../types';
import { getDateFromReview } from '../../../utils/date';
import { getAuthor, isTrustYouFeedback } from '../../../utils/review';
import { Stamp } from '../stamps';

export type MetaDataProps = {
  reviewRoot: ReviewRoot;
  entity: Entity;
  onPressVisitDataInfo?: () => void;
};

export function ReviewMetadata({ reviewRoot, entity, onPressVisitDataInfo }: MetaDataProps) {
  const intl = useIntl();
  const { isPhone } = useResponsive();
  const isSurvey = isTrustYouFeedback(reviewRoot);

  return (
    <Stack
      sx={{
        paddingBlock: 1,
        paddingInline: 2,
        gap: 1,
        alignItems: 'start',
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        backgroundColor: (theme) => theme.palette.grey[50],
      }}
    >
      <Stack
        direction="row"
        sx={{
          columnGap: 3,
          alignItems: 'center',
          flexWrap: 'wrap',
          ...(isPhone && {
            width: '100%',
            justifyContent: 'space-between',
          }),
        }}
      >
        <Typography variant="h6" sx={{ fontSize: 'initial' }}>
          {getAuthor(reviewRoot)}
        </Typography>
        {isSurvey &&
          (isPhone ? (
            <IconButton
              data-gtm-class="inbox_visit_data"
              data-gtm-id="inbox_visit_data_metadata"
              color="primary"
              onClick={onPressVisitDataInfo}
            >
              <StyledFontAwesomeIcon icon={faMemoCircleInfoOutlined} />
            </IconButton>
          ) : (
            <Button
              data-gtm-class="inbox_visit_data"
              data-gtm-id="inbox_visit_data_metadata"
              onClick={onPressVisitDataInfo}
              startIcon={<StyledFontAwesomeIcon icon={faMemoCircleInfoSolid} />}
            >
              <FormattedMessage id="inbox.visit-data" defaultMessage="Visit data" />
            </Button>
          ))}
      </Stack>
      <Stack>
        <StyledResponsiveStack>
          <Stamp
            status={
              <FormattedMessage
                id="inbox.published-with-date"
                defaultMessage="Published: {publishDate}"
                values={{ publishDate: getDateFromReview(reviewRoot?.review?.date ?? '') }}
              />
            }
            tooltip={intl.formatMessage({
              id: 'inbox.published-with-date-tooltip',
              defaultMessage: 'Review published on the source site',
            })}
            sx={{ color: 'text.primary' }}
          />
          {!isPhone && <Separator />}
          <Stamp
            status={
              <FormattedMessage
                id="inbox.processed-with-date"
                defaultMessage="Processed: {processedDate}"
                values={{
                  processedDate: getDateFromReview(reviewRoot?.review?.produced_at ?? ''),
                }}
              />
            }
            tooltip={intl.formatMessage({
              id: 'inbox.processed-with-date-tooltip',
              defaultMessage: 'Review processed by this Inbox',
            })}
            sx={{ color: 'text.primary' }}
          />
        </StyledResponsiveStack>
        <StyledResponsiveStack>
          <Typography variant="caption" color="text.secondary">
            <FormattedMessage
              id="inbox.meta.source"
              defaultMessage="Source: {source}"
              values={{
                source: (
                  <Link
                    href={reviewRoot?.review?.url ?? ''}
                    target="_blank"
                    rel="noreferrer"
                    underline="hover"
                    variant="caption"
                  >
                    {reviewRoot?.survey?.survey_name
                      ? `TrustYou Survey: ${reviewRoot?.survey.survey_name}`
                      : (reviewRoot?.review?.domain_name ?? reviewRoot?.review?.domain_id)}
                  </Link>
                ),
              }}
            />
          </Typography>
          {!isPhone && <Separator />}
          <Typography variant="caption" color="text.secondary">
            <FormattedMessage
              id="inbox.review.meta.entity"
              defaultMessage="Entity: {entity}"
              values={{
                entity: (
                  <Typography color="text.primary" variant="caption">
                    {entity?.name}
                  </Typography>
                ),
              }}
            />
          </Typography>
        </StyledResponsiveStack>
        <Typography variant="caption" color="text.secondary">
          {/* The term 'Review ID' shouldn't be translated because it must match its corresponding untranslated field label in Zendesk's external form */}
          Review ID: {reviewRoot?.review?.id}
        </Typography>
      </Stack>
    </Stack>
  );
}

function StyledResponsiveStack({ children, ...props }: StackProps) {
  const { isPhone } = useResponsive();

  return (
    <Stack
      {...props}
      sx={{
        columnGap: 0.5,
        flexWrap: 'wrap',
        ...(!isPhone && {
          flexDirection: 'row',
          alignItems: 'center',
        }),
        ...props.sx,
      }}
    >
      {children}
    </Stack>
  );
}

function Separator() {
  return (
    <Typography component="span" variant="caption">
      ·
    </Typography>
  );
}
