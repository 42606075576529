import { useCallback, useMemo, useState } from 'react';
import { type Control, Controller, type FormState, type UseFormWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { getPaginationRequest, getSortRequest } from '@trustyou/shared';
import {
  Autocomplete,
  Avatar,
  BrandedAvatar,
  Chip,
  FormHelperText,
  Stack,
  TextField,
  Typography,
  stringToBrandedColor,
} from '@trustyou/ui';
import { debounce } from 'lodash';

import { useUsersByOrganization } from './hooks/reports';
import {
  formHelperTextMessages,
  formLabelMessages,
  formPlaceholderMessages,
  validationErrorsMessages,
} from './messages';
import { type SchedulingForm, schedulingSchema } from './reportValidationSchema';

const PAGE_SIZE = 50;

export const RecipientsAutocomplete = ({
  control,
  formState,
  watch,
}: {
  watch: UseFormWatch<SchedulingForm>;
  formState: FormState<SchedulingForm>;
  control: Control<SchedulingForm>;
}) => {
  const intl = useIntl();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE,
  });
  const [focused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [searchKey, setSearchKey] = useState('');
  const {
    data,
    isFetching,
    isPending: isLoading,
    isError,
  } = useUsersByOrganization(
    getPaginationRequest(paginationModel),
    getSortRequest([{ field: 'name', sort: 'asc' }]),
    searchKey
  );
  const users = useMemo(() => {
    return data?.data.filter((item) => item.status === 'active');
  }, [data]);
  const recipients = watch('recipients', schedulingSchema.getDefault().recipients);

  const onInputChangeDebounced = useCallback((value: string) => {
    debounce(() => {
      setSearchKey(value);
    }, 1000);
  }, []);

  const shouldLoadMore = useMemo(() => {
    return data && !isLoading && !isFetching && paginationModel.pageSize < data.pagination.total;
  }, [paginationModel, data, isLoading, isFetching]);

  const options =
    users
      ?.map((item) => ({ value: item.id, label: item.email }))
      .filter((item) => {
        return !recipients.find((recipient) => recipient.value === item.value);
      })
      .concat(
        isFetching || isLoading
          ? [
              {
                value: 'loading',
                label: `${intl.formatMessage(formLabelMessages.loading)}...`,
              },
            ]
          : shouldLoadMore
            ? [
                {
                  value: 'more',
                  label: intl.formatMessage(formLabelMessages.more),
                },
              ]
            : []
      ) ?? [];

  return (
    <Controller
      control={control}
      name="recipients"
      render={({ field: { value = [], onChange } }) => (
        <Stack>
          <Autocomplete
            autoHighlight
            inputValue={inputValue}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            onInputChange={(_event, value, reason) => {
              if (reason === 'input') {
                setInputValue(value);
                onInputChangeDebounced(value);
              } else if (reason === 'clear') {
                setInputValue('');
                onInputChangeDebounced(value);
              } else if (reason === 'reset') {
                setInputValue('');
                onInputChangeDebounced(value);
              }
            }}
            open={focused && data && data.data.length > 0}
            onFocus={() => {
              setFocused(true);
            }}
            onBlur={() => {
              setFocused(false);
            }}
            multiple
            limitTags={1}
            onChange={(_event, newValue, reason, details) => {
              if (reason === 'removeOption') {
                onChange(value.filter((item) => item.value !== details?.option.value));
              } else if (reason === 'clear') {
                onChange([]);
              } else {
                const item = newValue[value.length];
                if (typeof item === 'string') {
                  return;
                }
                if (item.value === 'more') {
                  setPaginationModel((prev) => ({
                    ...prev,
                    page: 0,
                    pageSize: prev.pageSize + PAGE_SIZE,
                  }));
                } else if (item.value !== 'loading') {
                  newValue.forEach((item) => {
                    if (typeof item !== 'string') {
                      onChange(value.concat({ value: item.value, label: item.label }));
                    }
                  });
                }
              }
            }}
            noOptionsText={`${intl.formatMessage(formLabelMessages.loading)}...`}
            clearOnBlur
            handleHomeEndKeys
            id="recipients"
            options={options}
            renderOption={(props, option) => {
              const user = users?.find((user) => user.email === option.label);
              return user ? (
                <li {...props}>
                  <Stack direction="row" alignItems="flex-start">
                    <BrandedAvatar name={user?.name} />
                    <Stack direction="column" ml={2} alignItems="flex-start">
                      <Typography variant="body2" color="text.primary">
                        {user?.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{ wordBreak: 'break-word' }}
                        color="text.secondary"
                      >
                        {user?.email}
                      </Typography>
                    </Stack>
                  </Stack>
                </li>
              ) : (
                <li {...props} style={{ wordBreak: 'break-all' }}>
                  {option.label}
                </li>
              );
            }}
            sx={{
              '& ~ .MuiFormHelperText-root': {
                marginInlineStart: 0,
              },
            }}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => {
                const user = users?.find((user) => user.email === option.label);
                const dynamicColor = stringToBrandedColor(user?.name ?? 'a');
                return (
                  <Chip
                    {...getTagProps({ index })}
                    label={option.label}
                    avatar={
                      <Avatar
                        sx={{
                          backgroundColor: dynamicColor,
                          color: (theme) =>
                            `${theme.palette.getContrastText(dynamicColor)} !important`,
                        }}
                      >
                        {option.label.charAt(0).toUpperCase()}
                      </Avatar>
                    }
                  />
                );
              })
            }
            renderInput={(params) => (
              <TextField
                placeholder={intl.formatMessage(formPlaceholderMessages.recipients)}
                error={!!formState.errors.recipients}
                {...params}
              />
            )}
          />
          {formState.errors.recipients && (
            <FormHelperText error>
              {intl.formatMessage(validationErrorsMessages.missingRecipients)}
            </FormHelperText>
          )}
          {isError ? (
            <FormHelperText error>
              {intl.formatMessage(formHelperTextMessages.errorLoadingUsers)}
            </FormHelperText>
          ) : (
            <FormHelperText>{intl.formatMessage(formHelperTextMessages.recipients)}</FormHelperText>
          )}
        </Stack>
      )}
    />
  );
};
