import { forwardRef } from 'react';

import MuiButton, { type ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { useGTMProps } from '@trustyou/shared';

import type { E2ECouldTest } from '../types/e2e';
import type { GTMTracked } from '../types/gtm';

export interface ButtonProps extends MuiButtonProps, E2ECouldTest, GTMTracked {}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, 'data-testid': testId, ...restProps }: ButtonProps, ref) => {
    const propsWithGtm = useGTMProps<ButtonProps>(restProps, testId);
    return (
      <MuiButton {...propsWithGtm} ref={ref}>
        {children}
      </MuiButton>
    );
  }
);
