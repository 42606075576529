import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Chip, PopperMenu } from '@trustyou/ui';

import { USER_PLACEHOLDER } from '../../../../constants';
import { commonMessages } from '../../../../constants/messages';
import { useReview } from '../../../../hooks';
import { getHumanDate } from '../../../../utils/date';
import { messages } from '../../../review-deletion/messages';
import { StatusList } from './status-list';

export function DeletedChip() {
  const intl = useIntl();
  const { reviewId = '' } = useParams();
  const { data: reviewRoot } = useReview({ reviewId });
  const { deletion } = reviewRoot ?? {};

  return (
    <PopperMenu
      disablePortal={false}
      placement="bottom"
      Trigger={({ onClick }) => (
        <Chip
          label={intl.formatMessage(messages.deleted)}
          color="error"
          variant="pastel"
          onClick={onClick}
          // @ts-expect-error Property disableRipple does not exist on type 'IntrinsicAttributes & ChipProps'
          disableRipple
        />
      )}
      Content={() => (
        <StatusList
          items={[
            {
              concept: intl.formatMessage(messages.deleted),
              content: getHumanDate(deletion?.confirmed_at ?? ''),
            },
            {
              concept: intl.formatMessage(commonMessages.by),
              content: deletion?.username ?? USER_PLACEHOLDER,
            },
          ]}
        />
      )}
    />
  );
}
