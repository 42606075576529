import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import { faMagnifyingGlass, faXmark } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { type Filters, SEARCH_DEBOUNCE_TIME_MS, useResponsive } from '@trustyou/shared';
import { StyledFontAwesomeIcon } from '@trustyou/ui';

import { useDebounce, useFilters } from '../../hooks';
import { toInput, toTerms } from '../../utils/keyword-search';
import { IconButtonWithTooltip } from '../icon-button-with-tooltip';

export function CustomGridToolbarQuickFilter() {
  const intl = useIntl();
  const { isPhone } = useResponsive();
  const { currentView, setCurrentView } = useFilters();
  const { register, getValues, setValue } = useFormContext<Filters>();
  const { ref, ...registerProps } = register('keywords');
  const input = toInput(getValues().keywords || []);
  const [inputValue, setInputValue] = useState(input);
  const debouncedInput = useDebounce(inputValue, SEARCH_DEBOUNCE_TIME_MS);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  };

  const handleClear = () => {
    setInputValue('');
    setValue('keywords', []);
    setCurrentView({ ...currentView, filters: { ...currentView.filters, keywords: [] } });
  };

  useEffect(() => {
    const debouncedTerms = toTerms(debouncedInput);
    setValue('keywords', debouncedTerms);
    if (debouncedTerms.length > 0) {
      setCurrentView({
        ...currentView,
        filters: {
          ...currentView.filters,
          keywords: debouncedTerms,
        },
      });
    }

    // Not including `currentView` in the dependency array to avoid infinite render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInput]);

  return (
    <GridToolbarQuickFilter
      {...registerProps}
      variant="filled"
      placeholder={intl.formatMessage({ id: 'inbox.action.search', defaultMessage: 'Search' })}
      value={inputValue}
      onChange={handleChange}
      // The `debounceMs` prop has no effect because DataGrid's `filterModel` is not being used.
      // debounceMs={SEARCH_DEBOUNCE_TIME_MS}
      slotProps={{
        input: {
          startAdornment: (
            <StyledFontAwesomeIcon
              icon={faMagnifyingGlass}
              size="lg"
              sx={{ color: (theme) => theme.palette.action.active }}
            />
          ),
          endAdornment: inputValue && (
            <IconButtonWithTooltip
              icon={faXmark}
              tooltip={<FormattedMessage id="inbox.action.clear" defaultMessage="Clear" />}
              onClick={handleClear}
              sx={{ height: 32, width: 32 }}
            />
          ),
          sx: {
            '& .MuiInputBase-input': {
              paddingBlock: 1,
              marginLeft: 1,
            },
          },
        },
      }}
      sx={{
        width: isPhone ? '100%' : 220,
        '& .MuiInputBase-root': {
          paddingRight: 0.5,
          backgroundColor: (theme) => theme.palette.background.default,
          '& fieldset': {
            borderColor: 'transparent',
          },
        },
      }}
    />
  );
}
