import { ResponseFormContentLayout } from '../response-form-content-layout';
import { ResponseTemplate } from '../response-template';
import { ResponseTextField } from '../response-text-field';

type ResponseTemplateFormContentProps = {
  onSubmit: () => void;
};

export function ResponseTemplateFormContent({ onSubmit }: ResponseTemplateFormContentProps) {
  return (
    <ResponseFormContentLayout onSubmit={onSubmit}>
      <ResponseTemplate />
      <ResponseTextField fieldId="response" />
    </ResponseFormContentLayout>
  );
}
