import MuiListItemButton, {
  type ListItemButtonProps as MuiListItemButtonProps,
} from '@mui/material/ListItemButton';
import { useGTMProps } from '@trustyou/shared';

import type { E2ECouldTest } from '../types/e2e';
import type { GTMTracked } from '../types/gtm';

export interface ListItemButtonProps extends MuiListItemButtonProps, E2ECouldTest, GTMTracked {}

export const ListItemButton = ({
  children,
  'data-testid': testId,
  ...restProps
}: ListItemButtonProps) => {
  const propsWithGtm = useGTMProps<ListItemButtonProps>(restProps, testId);
  return <MuiListItemButton {...propsWithGtm}>{children}</MuiListItemButton>;
};
