import { useState } from 'react';
import { useIntl } from 'react-intl';

import { deepOrange, green, orange, red } from '@mui/material/colors';
import { useLanguageStore } from '@trustyou/shared';
import {
  Box,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  SmallFormFieldSkeleton,
  Stack,
  styled,
} from '@trustyou/ui';

import { useInterpolatePlaceholders } from './use-interpolate-placeholders';
import { useTemplateLanguages } from './use-template-languages';

import { templateMessages } from '../../../../constants/messages';
import { useGetResponseTemplates } from '../../../../hooks';
import { getResponseTemplatesByLanguage } from '../../../../utils/settings';

const EMPTY_OPTION_VALUE = 'none';

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  paddingInlineStart: theme.spacing(4),
}));

export function ResponseTemplate() {
  const intl = useIntl();
  const { locale } = useLanguageStore();
  const { data: templates } = useGetResponseTemplates();

  const [language, setLanguage] = useState((locale ?? 'en-US').substring(0, 2));
  const [templateName, setTemplateName] = useState(EMPTY_OPTION_VALUE);

  const templatesByLanguage = getResponseTemplatesByLanguage(templates, language);

  const templatesBySentiment = Object.groupBy(
    templatesByLanguage,
    (template) => template.sentiment
  );
  const positiveTemplates = templatesBySentiment.positive || [];
  const neutralTemplates = templatesBySentiment.neutral || [];
  const negativeTemplates = templatesBySentiment.negative || [];

  useInterpolatePlaceholders({
    templatesByLanguage,
    language,
    templateName,
  });

  const { templateLanguages } = useTemplateLanguages({ templates });

  if (!templates || Object.keys(templateLanguages).length === 0) return <SmallFormFieldSkeleton />;

  return (
    <Stack direction="column" gap={2}>
      <FormControl sx={{ width: 220 }}>
        <InputLabel id="template-language-label">
          {intl.formatMessage(templateMessages.languageLabel)}
        </InputLabel>
        <Select
          labelId="template-language-label"
          id="template-language"
          value={language}
          onChange={(event) => setLanguage(event.target.value)}
          input={<OutlinedInput label={intl.formatMessage(templateMessages.languageLabel)} />}
        >
          {Object.entries(templateLanguages).map(([languageKey, languageName]) => (
            <MenuItem key={languageKey} value={languageKey} data-testid={`lang-${languageKey}`}>
              {languageName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: 'calc(100% - 44px)' }}>
        <InputLabel id="template-template-label">
          {intl.formatMessage(templateMessages.templateLabel)}
        </InputLabel>
        <Select
          labelId="template-template-label"
          id="template-template"
          value={templateName}
          onChange={(event) => setTemplateName(event.target.value)}
          input={<OutlinedInput label={intl.formatMessage(templateMessages.templateLabel)} />}
          MenuProps={{ sx: { maxHeight: 480 } }}
        >
          {templatesByLanguage.length === 0 && (
            <StyledMenuItem value="empty">
              <Box component="span" color="text.secondary" sx={{ fontSize: 14 }}>
                {intl.formatMessage(templateMessages.empty)}
              </Box>
            </StyledMenuItem>
          )}
          {templatesByLanguage.length > 0 && (
            <StyledMenuItem value={EMPTY_OPTION_VALUE}>
              <Box component="span" color="text.secondary" sx={{ fontSize: 14 }}>
                {intl.formatMessage(templateMessages.placeholder)}
              </Box>
            </StyledMenuItem>
          )}
          {positiveTemplates.length > 0 && (
            <ListSubheader
              sx={{ backgroundColor: green[50], color: green[900], lineHeight: '36px' }}
            >
              {intl.formatMessage(templateMessages.positive)}
            </ListSubheader>
          )}
          {positiveTemplates.map((template) => (
            <StyledMenuItem key={template.name} value={template.name}>
              {template.name}
            </StyledMenuItem>
          ))}
          {neutralTemplates.length > 0 && (
            <ListSubheader
              sx={{ backgroundColor: orange[50], color: deepOrange[900], lineHeight: '36px' }}
            >
              {intl.formatMessage(templateMessages.neutral)}
            </ListSubheader>
          )}
          {neutralTemplates.map((template) => (
            <StyledMenuItem key={template.name} value={template.name}>
              {template.name}
            </StyledMenuItem>
          ))}
          {negativeTemplates.length > 0 && (
            <ListSubheader sx={{ backgroundColor: red[50], color: red[900], lineHeight: '36px' }}>
              {intl.formatMessage(templateMessages.negative)}
            </ListSubheader>
          )}
          {negativeTemplates.map((template) => (
            <StyledMenuItem key={template.name} value={template.name}>
              {template.name}
            </StyledMenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}
