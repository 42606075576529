import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { faXmark } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { SISENSE_SERVER_PATH } from '@trustyou/shared';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  StyledFontAwesomeIcon as Icon,
  IconButton,
  List,
  Typography,
} from '@trustyou/ui';
import { isString } from 'lodash';

import { BulletItem } from './BulletItem';
import { GridRow } from './GridRow';
import { messages } from './messages';

import styles from './styles';

export const InfoModal = () => {
  const intl = useIntl();

  const [showInfoModal, setShowInfoModal] = useState(false);

  const messageEventHandler = (event: MessageEvent) => {
    if (
      event.origin === SISENSE_SERVER_PATH &&
      isString(event.data) &&
      event.data.includes('dashboardAction-info')
    ) {
      setShowInfoModal(true);
    }
  };

  useEffect(() => {
    window.addEventListener('message', messageEventHandler);
    return () => {
      window.removeEventListener('message', messageEventHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = () => {
    setShowInfoModal(false);
  };

  return (
    <Dialog open={showInfoModal} onClose={onClose} sx={styles.dialog}>
      <DialogTitle sx={styles.dialogTitle}>
        <Typography variant="h5">{intl.formatMessage(messages.header)}</Typography>
        <IconButton data-testid="info-modal-close-button" onClick={onClose}>
          <Icon icon={faXmark} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        <List sx={styles.list}>
          <BulletItem
            primaryText={intl.formatMessage(messages.changeVsThePreviousPeriod)}
            secondaryText={intl.formatMessage(messages.changeVsThePreviousPeriodDescription)}
          />
          <BulletItem
            primaryText={intl.formatMessage(messages.changeVsTheSamePeriodLastYear)}
            secondaryText={intl.formatMessage(messages.changeVsTheSamePeriodLastYearDescription)}
          />
        </List>
        <Grid container pl={3} mt={3}>
          <Grid size={12}>
            <Typography variant="subtitle1" fontWeight={500}>
              <FormattedMessage id="analytics.info-modal.examples" defaultMessage="Examples" />
            </Typography>
          </Grid>
          <GridRow
            label={intl.formatMessage(messages.selectedPeriod)}
            value={intl.formatMessage(messages.februaryWithYear, { year: 2023 })}
            isHeader
          />
          <GridRow
            label={intl.formatMessage(messages.previousPeriod)}
            value={intl.formatMessage(messages.januaryWithYear, { year: 2023 })}
          />
          <GridRow
            label={intl.formatMessage(messages.samePeriodLastYear)}
            value={intl.formatMessage(messages.februaryWithYear, { year: 2022 })}
          />
        </Grid>
        <Grid container pl={3} mt={3}>
          <GridRow
            label={intl.formatMessage(messages.selectedPeriod)}
            value={intl.formatMessage(messages.februaryWithPeriod, { period: '1 - 28, 2023' })}
            isHeader
          />
          <GridRow
            label={intl.formatMessage(messages.previousPeriod)}
            value={intl.formatMessage(messages.januaryWithPeriod, { period: '3 - 31, 2023' })}
          />
          <GridRow
            label={intl.formatMessage(messages.samePeriodLastYear)}
            value={intl.formatMessage(messages.februaryWithPeriod, { period: '1 - 28, 2022' })}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button sx={styles.closeButton} onClick={onClose} type="button" variant="contained">
          <FormattedMessage id="analytics.action.close" defaultMessage="Close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
