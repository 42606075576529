import { useMemo } from 'react';

import { useLanguagesForResponseAI } from './api-query';

import type { Language, LanguageItem } from '../types';

export type LanguageSource = {
  language_source: Language | 'AUTO';
  language_source_name: null | string;
};

export const LANGUAGE_SOURCE_AUTO: LanguageSource = {
  language_source: 'AUTO',
  language_source_name: 'Auto Detecting',
};

export const LANGUAGE_ITEM_AUTO: LanguageItem = {
  value: 'AUTO',
  label: 'Auto',
};

export const useLanguage = () => {
  const { data: languages, isPending: isLoading } = useLanguagesForResponseAI({
    enabled: true,
  });

  const sortedLanguages = useMemo(() => {
    if (isLoading || !languages) return [];
    return [...languages].sort((a, b) => a.label.localeCompare(b.label));
  }, [languages, isLoading]);

  const languagesWithAuto = useMemo(() => {
    if (isLoading && !sortedLanguages) return [LANGUAGE_ITEM_AUTO];
    return [LANGUAGE_ITEM_AUTO, ...(sortedLanguages || [])];
  }, [sortedLanguages, isLoading]);

  const fallBackLanguageSource: LanguageSource = {
    language_source: 'EN',
    language_source_name: 'English',
  };

  const fallBackLanguage = 'en' as Language;

  /**
   * format language code to lowercase and remove region code
   * except for simplified and traditional chinese
   *
   * example: input code 'en-US' -> output code 'en'
   * example: input code 'AR' -> output code 'ar'
   * example: input code 'zh-Hans' -> output code 'zh-Hans'
   */
  const getFormattedLanguageCode = (code: string) => {
    const lowerCaseCode = code.toLowerCase();
    if (lowerCaseCode === 'zh-hans') return 'zh-Hans';
    if (lowerCaseCode === 'zh-hant') return 'zh-Hant';
    return lowerCaseCode.split('-')[0];
  };

  const getLanguageByCode = (code?: Language | 'AUTO') => {
    if (!code || code === 'AUTO') return LANGUAGE_ITEM_AUTO;

    const formattedCode = getFormattedLanguageCode(code);
    return languages?.find(
      (language) => language.value.toLowerCase() === formattedCode.toLowerCase()
    );
  };

  const getLanguageByLanguageSource = (language: LanguageSource) => {
    return getLanguageByCode(language.language_source);
  };

  const getLanguageSourceByCode = (code: string | null) => {
    const upperCaseCode = code?.toUpperCase() as Language | 'AUTO' | 'UND';

    if (!upperCaseCode || upperCaseCode === 'UND') return fallBackLanguageSource;
    if (upperCaseCode === 'AUTO') return LANGUAGE_SOURCE_AUTO;
    const language = getLanguageByCode(upperCaseCode) ?? {
      value: 'EN-US',
      label: 'English',
    };

    return {
      language_source: language.value,
      language_source_name: language.label,
    };
  };

  const getLanguageSourceByLanguage = (language: LanguageItem): LanguageSource => {
    return {
      language_source: language.value,
      language_source_name: language.label,
    };
  };

  return {
    isLoading,
    languages: sortedLanguages,
    languagesWithAuto,
    fallBackLanguageSource,
    fallBackLanguage,
    getLanguageSourceByCode,
    getLanguageByCode,
    getFormattedLanguageCode,
    getLanguageByLanguageSource,
    getLanguageSourceByLanguage,
  };
};
