import { useState } from 'react';
import {
  FormProvider,
  type SubmitErrorHandler,
  type SubmitHandler,
  useForm,
} from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { zodResolver } from '@hookform/resolvers/zod';
import { lightBlue } from '@mui/material/colors';
import { styled } from '@mui/system';
import { faMagnifyingGlass } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  AdvancedFilters,
  AnswerableReviewsOnlyFilter,
  BasicFilters,
  type QueryOptions,
  StyledDivider,
  ViewsDropdown,
  mapFiltersToViewFilters,
  useActiveFiltersCount,
  useFilters,
  useReviewsData,
} from '@trustyou/inbox';
import {
  type Filters,
  commonFields,
  filtersSchema,
  useChangelingStore,
  useInboxStore,
} from '@trustyou/shared';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  ComposableDrawerWithStickyFooter,
  InputAdornment,
  InputLabel,
  Stack,
  StyledFontAwesomeIcon,
  TextField,
  Typography,
} from '@trustyou/ui';

import {
  commonMessages,
  inboxDetailsStepMessages,
  inboxFiltersStepMessages,
} from '../../../../constants/messages';
import { useNewReportStore } from '../../../../store';
import { Footer } from '../footer';
import styles from '../styles';

export function InboxFilterStep() {
  const intl = useIntl();
  const { isChangeling } = useChangelingStore();
  const { prevStep, nextStep } = useNewReportStore();
  const [isOpen, setIsOpen] = useState(false);
  const methods = useForm<Filters>({ resolver: zodResolver(filtersSchema) });

  // Fetch data sources and other necessary data
  const { dataGrid } = useInboxStore();
  const activeFiltersCount = useActiveFiltersCount();
  const { currentView, applyFilters, debugInvalidForm } = useFilters();
  const [value, setValue] = useState(currentView.filters?.keywords ?? []);

  // Fetching users selected entities
  const queryOptions: QueryOptions = {
    sortModel: dataGrid.sorting?.sortModel,
    page: dataGrid.pagination?.paginationModel?.page,
    pageSize: dataGrid.pagination?.paginationModel?.pageSize,
  };
  // Reason to call this is to get the data of user's selected entities
  //TODO: fix this api call in future because it currently fetches all the data related to reviews
  useReviewsData(queryOptions, mapFiltersToViewFilters(currentView.filters ?? {}));

  const onValid: SubmitHandler<Filters> = (form: Filters) => {
    applyFilters(form);
    setIsOpen(false);
  };

  const onInvalid: SubmitErrorHandler<Filters> = (event) => {
    debugInvalidForm(event);
  };

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        onSubmit={methods.handleSubmit(onValid, onInvalid)}
        sx={styles.stepBody}
      >
        <Stack width="100%" gap={6} alignItems="center">
          <Stack width="600px" spacing={3}>
            <Stack>
              <Typography variant="h6">
                {intl.formatMessage(inboxFiltersStepMessages.defineFiltersTitle)}
              </Typography>
              <Typography variant="body2" color="text.primary">
                {intl.formatMessage(inboxFiltersStepMessages.defineFiltersDescription)}
              </Typography>
            </Stack>
            <Stack flexDirection="row" gap={2} alignItems="center">
              <ViewsDropdown label={intl.formatMessage(commonFields.view)} width={320} />
              <Button variant="contained" onClick={() => setIsOpen(true)}>
                {intl.formatMessage(commonMessages.filters)}
                {activeFiltersCount > 0 && `(${activeFiltersCount})`}
              </Button>
            </Stack>
          </Stack>
          <Stack width="600px">
            <InputLabel
              htmlFor="keywords"
              sx={{ display: 'flex', paddingY: 1, gap: 2, alignItems: 'center' }}
            >
              <Typography component="h1" variant="h6" color="text.primary">
                {intl.formatMessage(commonMessages.keywords)}
              </Typography>
              <Chip
                label={intl.formatMessage(commonMessages.optional)}
                sx={{ color: 'info.main', backgroundColor: lightBlue[50] }}
              />
            </InputLabel>
            <Typography variant="body1" gutterBottom>
              {intl.formatMessage(inboxDetailsStepMessages.descriptionOne)}
            </Typography>
            <Typography variant="body1" color="text.secondary" marginBottom={2}>
              {intl.formatMessage(inboxDetailsStepMessages.descriptionTwo)}
            </Typography>
            <Autocomplete
              multiple
              id="keywords"
              options={[]}
              freeSolo
              value={value}
              onChange={(_event, newValue) => {
                setValue(newValue);
              }}
              renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  return (
                    <Chip variant="filled" label={option} color="info" key={key} {...tagProps} />
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  slotProps={{
                    input: {
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position="start">
                            <StyledFontAwesomeIcon
                              icon={faMagnifyingGlass}
                              size="lg"
                              sx={{
                                color: (theme) => theme.palette.action.active,
                                marginInline: 1,
                              }}
                            />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      ),
                    },
                  }}
                  placeholder={intl.formatMessage(commonMessages.enterKeywords)}
                />
              )}
            />
          </Stack>
          <ComposableDrawerWithStickyFooter
            anchor="right"
            open={isOpen}
            onClose={() => setIsOpen(false)}
            PaperProps={{
              sx: {
                width: '320px',
                paddingTop: isChangeling ? 8 : 0,
              },
            }}
          >
            <ComposableDrawerWithStickyFooter.Content>
              <Stack spacing={2}>
                <BasicFilters />
                <AnswerableReviewsOnlyFilter />
                <StyledDivider />
                <AdvancedFilters />
                <StyledDivider />
              </Stack>
            </ComposableDrawerWithStickyFooter.Content>
            <ComposableDrawerWithStickyFooter.Footer
              primaryButton={
                <Button type="submit" variant="contained">
                  <FormattedMessage id="inbox.action.apply" defaultMessage="Apply" />
                </Button>
              }
              secondaryButton={
                <Button onClick={() => setIsOpen(false)} type="button" color="inherit">
                  <FormattedMessage id="inbox.action.cancel" defaultMessage="Cancel" />
                </Button>
              }
              sx={{ boxShadow: 5 }}
            />
          </ComposableDrawerWithStickyFooter>
        </Stack>
      </Box>
      <Footer onClickBack={prevStep} onClickNext={nextStep} />
    </FormProvider>
  );
}
