import { useMediaQuery } from '@mui/material';
import { theme } from '@trustyou/ui';

import { DRAWER_WIDTH } from '../constants';

export function useDrawerWidth() {
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const drawerWidth = isDesktop ? DRAWER_WIDTH : '80vw';

  return { drawerWidth };
}
