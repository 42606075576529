import {
  ADD_OWNED_ENTITIES_PERMISSION,
  SEARCH_ADDABLE_ENTITIES_PERMISSION,
  usePermissionStore,
} from '@trustyou/shared';

const useShowAddEntities = () => {
  const { hasPermissions } = usePermissionStore();

  const isSearchAddableEntitiesAllowed = hasPermissions(SEARCH_ADDABLE_ENTITIES_PERMISSION);
  const isAddOwnedEntitiesAllowed = hasPermissions(ADD_OWNED_ENTITIES_PERMISSION);

  return isSearchAddableEntitiesAllowed && isAddOwnedEntitiesAllowed;
};

export default useShowAddEntities;
