import type { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

import { useChangelingStore } from '@trustyou/shared';
import { Box, ChangelingAlert, Stack } from '@trustyou/ui';

import styles from './styles';

export type TopBarLayoutProps = {
  hideTopBar?: boolean;
  children?: ReactElement;
};

export const FullScreenLayout = ({ children }: TopBarLayoutProps) => {
  const { isChangeling } = useChangelingStore();
  return (
    <Stack sx={styles.container}>
      <ChangelingAlert />
      <Box component="main" sx={{ ...styles.body, ...(isChangeling && styles.changelingBody) }}>
        {children || <Outlet />}
      </Box>
    </Stack>
  );
};
