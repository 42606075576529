import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { faChevronLeft, faChevronRight } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Box, Button, StyledFontAwesomeIcon as Icon, Stack, Tooltip } from '@trustyou/ui';

import { newReportWizardMessages } from '../../../constants/messages';
import styles from './styles';

type FooterProps = {
  disableNext?: boolean;
  children?: ReactNode;
  nextButtonTooltip?: string;
  onClickBack?: () => void;
  onClickNext?: () => void;
};

export const Footer = ({
  onClickBack,
  onClickNext,
  children,
  disableNext,
  nextButtonTooltip,
}: FooterProps) => (
  <Stack sx={styles.footer}>
    {onClickBack && (
      <Button
        data-testid="back-button"
        variant="outlined"
        onClick={onClickBack}
        startIcon={<Icon icon={faChevronLeft} />}
        size="large"
      >
        <FormattedMessage {...newReportWizardMessages.back} />
      </Button>
    )}
    <Box sx={{ flexGrow: 1 }}>{children}</Box>
    {onClickNext && (
      <Tooltip placement="top" arrow title={nextButtonTooltip}>
        <span>
          <Button
            data-testid="next-button"
            variant="contained"
            onClick={onClickNext}
            endIcon={<Icon icon={faChevronRight} />}
            size="large"
            disabled={disableNext}
          >
            <FormattedMessage {...newReportWizardMessages.next} />
          </Button>
        </span>
      </Tooltip>
    )}
  </Stack>
);
