import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useEntity } from '@trustyou/shared';

import { useGetSignature, useReview } from '../../../../hooks';
import type { ResponseFormSchema, Template } from '../../../../types';

const PLACEHOLDERS = {
  brand: '{brand}',
  hotel: '{hotel}',
  guestFirstName: '{guest_first_name}',
  guestLastName: '{guest_last_name}',
};

export function useInterpolatePlaceholders({
  templatesByLanguage,
  language,
  templateName,
}: {
  templatesByLanguage: Template[];
  language: string;
  templateName: string;
}) {
  const { reviewId = '' } = useParams();
  const { data: reviewRoot } = useReview({ reviewId });
  const { survey, meta } = reviewRoot ?? {};
  const { data: entity } = useEntity(meta?.entity_id ?? '');
  const { data: signature } = useGetSignature();
  const { setValue, resetField } = useFormContext<ResponseFormSchema>();

  const interpolateTemplateText = (currentText: string) => {
    const brand = entity?.brand ?? PLACEHOLDERS.brand;
    const hotel = entity?.name ?? PLACEHOLDERS.hotel;
    const guestFirstName =
      survey?.visit?.guest_info?.find((data) => data.internal_key === 'guest_first_name')?.answer
        ?.value ?? PLACEHOLDERS.guestFirstName;
    const guestLastName =
      survey?.visit?.guest_info?.find((data) => data.internal_key === 'guest_last_name')?.answer
        ?.value ?? PLACEHOLDERS.guestLastName;

    let resultText = currentText
      .replaceAll(PLACEHOLDERS.brand, brand)
      .replaceAll(PLACEHOLDERS.hotel, hotel)
      .replaceAll(PLACEHOLDERS.guestFirstName, guestFirstName)
      .replaceAll(PLACEHOLDERS.guestLastName, guestLastName);

    if (signature) {
      resultText = resultText.concat(`\n${signature}`);
    }

    setValue('response', resultText);
  };

  useEffect(() => {
    const foundTemplate = templatesByLanguage.find((template) => template.name === templateName);
    if (!foundTemplate) {
      resetField('response');
      return;
    }
    interpolateTemplateText(foundTemplate.text);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, templateName]);
}
