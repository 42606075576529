import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import {
  type Datapoint,
  type FiltersField,
  useDatePickersIntl,
  useLanguageStore,
  useVisitDatapointListQuery,
} from '@trustyou/shared';
import {
  AdapterDayjs,
  DatePicker,
  LocalizationProvider,
  SmallFormFieldSkeleton,
  TextField,
  Typography,
} from '@trustyou/ui';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import type { VisitDataFilter as VisitDataFilterType } from '../../../client';
import { useFilters } from '../../../hooks';
import { DATE_FORMAT } from '../../custom-date-range-dialog/custom-date-range-picker';
import { DropdownSingleAutocomplete } from '../../dropdowns/dropdown-single-autocomplete';

dayjs.extend(utc);

export function VisitDataFilter() {
  const { data, isPending } = useVisitDatapointListQuery();
  const [visitDataFilters, setVisitDataFilters] = useState<Datapoint[]>([]);
  const { currentView } = useFilters();

  const { locale } = useLanguageStore();
  const shortLocale = locale.substring(0, 2);
  const datePickersIntl = useDatePickersIntl();
  const { control, register, reset, getValues } = useFormContext();

  const { fields } = useFieldArray({
    control,
    name: 'survey.visit_data',
  });

  useEffect(() => {
    const newFields = visitDataFilters.map((filter) => ({
      key: filter.internal_key,
      type: filter.type,
      values:
        currentView?.filters?.survey?.visit_data?.find(
          (view) => view && view.key === filter.internal_key
        )?.values ?? [],
    }));

    // need to call reset otherwise ui bugged when loading a view as the fields expand with loaded visitDataFilters
    reset({
      ...getValues(),
      survey: {
        ...getValues('survey'),
        visit_data: newFields,
      },
    });
  }, [visitDataFilters, currentView, reset, getValues]);

  useEffect(() => {
    const order = [
      'arrival_date',
      'departure_date',
      'guest_language',
      'guest_gender',
      'room_number',
      'guest_email',
      'guest_first_name',
      'guest_last_name',
    ];
    if (data && Array.isArray(data)) {
      const predefined = data
        .filter((d) => d.category === 'predefined')
        .sort((a, b) => {
          const indexA = order.indexOf(a.internal_key);
          const indexB = order.indexOf(b.internal_key);

          if (indexA === -1 && indexB === -1) return 0; // Both keys not found
          if (indexA === -1) return 1; // A goes after B
          if (indexB === -1) return -1; // B goes after A

          return indexA - indexB; // Sort by index
        });
      const custom = data.filter((d) => d.category !== 'predefined');
      setVisitDataFilters(predefined.concat(custom));
    }
  }, [data]);

  return (
    <>
      <Typography textAlign="center" variant="subtitle2" color="text.secondary">
        <FormattedMessage id="inbox.filter.visit.data.header" defaultMessage="Visit Data" />
      </Typography>
      {isPending ? (
        <SmallFormFieldSkeleton />
      ) : (
        fields.map((field, index) => {
          const key = (field as unknown as VisitDataFilterType).key;
          const visitDataEntry = visitDataFilters.find((filter) => filter.internal_key === key);
          switch (visitDataEntry?.type) {
            case 'single_select':
              return (
                <DropdownSingleAutocomplete
                  key={key}
                  label={visitDataEntry?.name}
                  fieldName={`survey.visit_data.${index}.values.0` as FiltersField}
                  options={
                    visitDataEntry?.options?.map((option) => ({
                      ...option,
                      label: option.value.toString(),
                    })) ?? []
                  }
                />
              );
            case 'number':
              return (
                <TextField
                  slotProps={{ inputLabel: { shrink: true } }}
                  key={key}
                  label={visitDataEntry?.name}
                  type="number"
                  variant="outlined"
                  id={`survey.visit_data.${index}.values.0`}
                  {...register(`survey.visit_data.${index}.values.0`)}
                />
              );
            case 'datetime':
              return (
                <LocalizationProvider
                  key={key}
                  dateAdapter={AdapterDayjs}
                  adapterLocale={shortLocale}
                  localeText={
                    datePickersIntl[shortLocale]?.components.MuiLocalizationProvider.defaultProps
                      .localeText
                  }
                >
                  <Controller
                    name={`survey.visit_data.${index}.values.0`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        format={DATE_FORMAT}
                        label={visitDataEntry?.name}
                        onChange={(date) => {
                          if (!date || !dayjs(date).isValid()) {
                            // reset date if invalid
                            onChange(null);
                          } else {
                            // Convert the date to a Unix timestamp string using dayjs
                            const unixTimestamp = dayjs.utc(date).startOf('day').unix().toString();
                            onChange(unixTimestamp); // Update form state
                          }
                        }}
                        value={
                          value && dayjs.unix(Number(value)).isValid()
                            ? dayjs.unix(Number(value))
                            : null
                        }
                        slotProps={{ textField: { slotProps: { inputLabel: { shrink: true } } } }}
                      />
                    )}
                  />
                </LocalizationProvider>
              );
            default:
              return (
                <TextField
                  slotProps={{ inputLabel: { shrink: true } }}
                  key={key}
                  label={visitDataEntry?.name}
                  variant="outlined"
                  id={`survey.visit_data.${index}.values.0`}
                  {...register(`survey.visit_data.${index}.values.0`)}
                />
              );
          }
        })
      )}
    </>
  );
}
