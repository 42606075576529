import { forwardRef } from 'react';

import MuiChip, { type ChipProps as MuiChipProps } from '@mui/material/Chip';
import { useGTMProps } from '@trustyou/shared';

import type { E2ECouldTest } from '../types/e2e';
import type { GTMTracked } from '../types/gtm';

export interface ChipProps extends MuiChipProps, E2ECouldTest, GTMTracked {}

export const Chip = forwardRef<HTMLDivElement, ChipProps>(
  ({ 'data-testid': testId, ...restProps }: ChipProps, ref) => {
    const propsWithGtm = useGTMProps<ChipProps>(restProps, testId);
    return <MuiChip {...propsWithGtm} ref={ref} />;
  }
);
