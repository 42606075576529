import { Stack } from '@trustyou/ui';

import { AdvancedFilters } from './advanced-filters';
import { AnswerableReviewsOnlyFilter } from './answerable-reviews-only-filter';
import { BasicFilters } from './basic-filters';
import { NewViewButton } from './new-view-button-and-dialog';

import { StyledDivider } from './styled-divider';

type FiltersTabContentProps = {
  onSave: () => void;
};

export function FiltersTabContent({ onSave }: FiltersTabContentProps) {
  return (
    <Stack spacing={2}>
      <BasicFilters />
      <AnswerableReviewsOnlyFilter />
      <StyledDivider />
      <AdvancedFilters />
      <StyledDivider />
      <NewViewButton onSave={onSave} sx={{ alignSelf: 'start' }} />
    </Stack>
  );
}
