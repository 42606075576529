import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  REPORT_LIST_PERMISSION,
  REPORT_SCHEDULE_PERMISSION,
  usePermissionStore,
} from '@trustyou/shared';
import { Button, ComposableDrawerWithStickyFooter, Tab, Tabs } from '@trustyou/ui';

import { NewReport } from './NewReport';
import { Report } from './Report';
import { ScheduledReportsTable } from './ScheduledReportsDataGrid';
import { TabActions } from './components/TabActions';
import { TabPanel } from './components/TabPanel';
import { DRAWER_WIDTH_PX } from './constants';

const SLIDE_ANIMATION_DURATION = 250;

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function ReportsDrawer({
  onClose,
  dashboardId,
  changelingMode,
  enabledFormats,
}: {
  onClose: () => void;
  dashboardId: string;
  changelingMode?: boolean;
  enabledFormats?: string[];
}) {
  const [activeTab, setActiveTab] = useState(0);
  const [report, setReport] = useState<{ id: string } | null>(null);
  const intl = useIntl();

  const { hasPermissions } = usePermissionStore();
  const hasListPermission = hasPermissions(REPORT_LIST_PERMISSION);
  const hasRegisterPermission = hasPermissions(REPORT_SCHEDULE_PERMISSION);

  return (
    <ComposableDrawerWithStickyFooter
      open
      anchor="right"
      onClose={onClose}
      PaperProps={{
        sx: {
          width: DRAWER_WIDTH_PX,
          paddingTop: changelingMode ? 8 : 0,
        },
      }}
      SlideProps={{ appear: true, timeout: SLIDE_ANIMATION_DURATION }}
      aria-describedby="reports-drawer"
      sx={{}}
    >
      <ComposableDrawerWithStickyFooter.Header
        title={intl.formatMessage({
          id: 'analytics.reports.header-title',
          defaultMessage: 'PDF reports',
        })}
        onClose={onClose}
        sx={{ paddingInline: 3 }}
      />
      <ComposableDrawerWithStickyFooter.Content sx={{ paddingInline: 3, paddingBlockStart: 0 }}>
        <Tabs
          value={activeTab}
          onChange={(_event, tab) => {
            setActiveTab(tab);
          }}
          aria-label="reports tabs"
          sx={{ marginBlockEnd: 2 }}
        >
          {hasRegisterPermission && (
            <Tab
              label={
                <FormattedMessage id="analytics.reports.new.title" defaultMessage="New report" />
              }
              {...a11yProps(0)}
            />
          )}
          {hasListPermission && (
            <Tab
              label={
                <FormattedMessage id="analytics.reports.title" defaultMessage="Scheduled reports" />
              }
              {...a11yProps(1)}
            />
          )}
        </Tabs>
        <TabPanel value={activeTab} index={0}>
          <NewReport
            dashboardId={dashboardId}
            enabledFormats={enabledFormats}
            onSuccess={() => {
              setActiveTab(1);
            }}
            onCancel={onClose}
          />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <ScheduledReportsTable
            onEdit={({ id }) => {
              setReport({ id });
            }}
          />
          <TabActions
            right={
              <Button color="inherit" onClick={onClose}>
                <FormattedMessage id="analytics.reports.common.cancel" defaultMessage="Cancel" />
              </Button>
            }
          />
        </TabPanel>
        {report && (
          <Report
            dashboardId={dashboardId}
            enabledFormats={enabledFormats}
            id={report.id}
            onClose={() => {
              setReport(null);
            }}
            changelingMode={changelingMode}
          />
        )}
      </ComposableDrawerWithStickyFooter.Content>
      {/* TODO: Refactor the position absolute TabActions with ComposableDrawerWithStickyFooter.Footer */}
      {/* <ComposableDrawerWithStickyFooter.Footer
        primaryButton={
          activeTab === 0 && (
            <Button
              // onClick={() => {}}
              type="submit"
              variant="contained"
              disabled={!formState.isValid}
            >
              <FormattedMessage id="common.action.save" defaultMessage="Save" />
            </Button>
          )
        }
        secondaryButton={
          <Button onClick={onClose} type="button" color="inherit">
            <FormattedMessage id="common.action.cancel" defaultMessage="Cancel" />
          </Button>
        }
        sx={{ boxShadow: 5 }}
      /> */}
    </ComposableDrawerWithStickyFooter>
  );
}
