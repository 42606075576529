import { useQuery } from '@tanstack/react-query';
import {
  $assApi,
  ANALYTICS_BASE_PATH,
  useLanguageStore,
  useMembershipStore,
} from '@trustyou/shared';

import { WIDGET_LIST } from '../../constants/query-keys';
import type { WidgetListPayload, WidgetListResponse } from '../../types/widgets';

async function fetchWidgets(params: WidgetListPayload): Promise<WidgetListResponse> {
  const url = `${ANALYTICS_BASE_PATH}/report_engine/widget_list`;
  const { data } = await $assApi.get(url, { params });
  return data;
}

export function useWidgets(dashboardId: string) {
  const { membership } = useMembershipStore();
  const { locale } = useLanguageStore();

  return useQuery({
    queryKey: [WIDGET_LIST, dashboardId],
    queryFn: () =>
      fetchWidgets({
        dashboard_id: dashboardId,
        organization_id: membership?.organizationId as string,
        language: locale,
      }),
    refetchOnWindowFocus: false,
    enabled: true,
  });
}
