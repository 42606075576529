import type { PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';

import { useResponsive } from '@trustyou/shared';
import { Stack } from '@trustyou/ui';

import { GDPRAlert } from './gdpr-alert';
import { PrivateResponseSection } from './private-response/private-response-section';

import { useReview } from '../../../../hooks';
import { isTrustYouFeedback } from '../../../../utils/review';
import { SubmitRegion } from './submit-region';

type ResponseFormContentLayoutProps = PropsWithChildren<{
  onSubmit: () => void;
}>;

export function ResponseFormContentLayout({ children, onSubmit }: ResponseFormContentLayoutProps) {
  const { isMobile } = useResponsive();
  const { reviewId = '' } = useParams();
  const { data: reviewRoot } = useReview({ reviewId });
  const isSurvey = isTrustYouFeedback(reviewRoot);
  const isPublicSurvey = reviewRoot?.survey?.privacy_level === 'public';
  const selectedResponse = 'response';

  return (
    <Stack
      component="form"
      spacing={4}
      sx={{
        maxWidth: '80ch',
        ...(isMobile && {
          paddingBlockEnd: 10,
        }),
      }}
    >
      {isPublicSurvey && <GDPRAlert />}
      <Stack spacing={2}>{children}</Stack>
      {isSurvey && <PrivateResponseSection selectedResponse={selectedResponse} />}
      <SubmitRegion onSubmit={onSubmit} />
    </Stack>
  );
}
