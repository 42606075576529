import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Chip, PopperMenu } from '@trustyou/ui';

import { messages } from './messages';

import { AUTHOR_PLACEHOLDER } from '../../../../constants';
import { useReview } from '../../../../hooks';
import { getHumanDate } from '../../../../utils/date';
import { StatusList } from './status-list';

export function MarkedAsRespondedChip() {
  const intl = useIntl();
  const { reviewId = '' } = useParams();
  const { data: reviewRoot } = useReview({ reviewId });

  return (
    <PopperMenu
      disablePortal={false}
      placement="bottom"
      Trigger={({ onClick }) => (
        <Chip
          label={intl.formatMessage(messages.markedAsResponded)}
          color="success"
          variant="pastel"
          onClick={onClick}
          // @ts-expect-error Property disableRipple does not exist on type 'IntrinsicAttributes & ChipProps'
          disableRipple
        />
      )}
      Content={() => (
        <StatusList
          items={[
            {
              concept: intl.formatMessage(messages.markedAsResponded),
              content: getHumanDate(reviewRoot?.response?.date ?? ''),
            },
            {
              concept: intl.formatMessage(messages.respondedBy),
              content: reviewRoot?.response?.author ?? AUTHOR_PLACEHOLDER,
            },
          ]}
        />
      )}
    />
  );
}
