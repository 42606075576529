import { Fragment, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  SEARCH_DEBOUNCE_TIME_MS,
  countries,
  searchPlaceholders,
  useAttributeValuesForBenchmark,
} from '@trustyou/shared';
import { Autocomplete, Checkbox, Chip, Divider, Popper, TextField } from '@trustyou/ui';
import { debounce, keyBy, mapValues } from 'lodash';

import {
  COMPETITOR_SEARCH_DEBOUNCE_TIME_MS,
  TYPE_TO_SEARCH_ATTRIBUTES,
} from '../../../../constants/benchmarks';
import { manageDrawer } from '../../../../constants/messages/benchmarks';
import { styles } from './styles';

type Props = {
  attribute?: string;
  isCompetitors?: boolean;
  defaultValues?: string[];
  onChange: (values: string[]) => void;
};

type Option = {
  key: string;
  label: string;
  selected?: boolean;
};

export const AttributeValuesSelector = ({
  attribute,
  isCompetitors,
  defaultValues = [],
  onChange,
}: Props) => {
  const intl = useIntl();
  const [inputValue, setInputValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const countryMap = mapValues(keyBy(countries, 'code'), 'label');
  const [selectedValues, setSelectedValues] = useState<Option[]>(
    defaultValues.map((key) => ({
      key,
      label: attribute === 'country' ? countryMap[key] || key : key,
    }))
  );

  const { data: values = [], isPending } = useAttributeValuesForBenchmark(
    isCompetitors ? 'competitor' : 'organization',
    attribute,
    searchValue,
    // Don't search if the search value is empty and the attribute is a type_to_search attribute in competitors tab
    !(isCompetitors && attribute && TYPE_TO_SEARCH_ATTRIBUTES.includes(attribute) && !searchValue)
  );

  // This is to avoid 'None of the options match error when selected values does not match with server side filtered options list
  const mergedOptions: Option[] = useMemo(() => {
    const valuesKeys = values.map((value) => value.key);
    return [
      ...values,
      ...selectedValues
        .filter((selectedValue) => !valuesKeys.includes(selectedValue.key))
        .map((row) => ({ ...row, selected: true })),
    ];
  }, [values, selectedValues]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce(
      async (value: string) => {
        setSearchValue(value);
      },
      isCompetitors ? COMPETITOR_SEARCH_DEBOUNCE_TIME_MS : SEARCH_DEBOUNCE_TIME_MS
    ),
    []
  );
  const isDisabled = !attribute;

  const getPlaceholder = () => {
    if (!attribute) return undefined;
    if (isCompetitors && TYPE_TO_SEARCH_ATTRIBUTES.includes(attribute)) {
      return intl.formatMessage(searchPlaceholders.typeToSearch);
    }
    if (!values.length && !isPending && !isCompetitors) {
      return intl.formatMessage(manageDrawer.definitionRulesSelectNoValues);
    }
    return undefined;
  };

  return (
    <Autocomplete
      autoHighlight
      multiple
      limitTags={2}
      id="attribute-values-selector"
      options={mergedOptions}
      disablePortal
      disableCloseOnSelect
      loading={isPending}
      loadingText={intl.formatMessage(manageDrawer.definitionRulesLoading)}
      value={selectedValues}
      filterOptions={(x) => x.filter((option) => !option.selected)}
      isOptionEqualToValue={(option, value) => option.key === value.key}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option, { selected, index }) => (
        <Fragment key={option.key}>
          <li {...props} key={option.key} style={styles.ruleValueSelectOption}>
            {option.label}
            <Checkbox checked={selected} />
          </li>
          {index !== values.length - 1 && <Divider />}
        </Fragment>
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip {...getTagProps({ index })} key={option.key} label={option.label} />
        ))
      }
      renderInput={(params) => <TextField {...params} placeholder={getPlaceholder()} />}
      slots={{
        popper: (props) => (
          <Popper
            {...props}
            sx={
              isDisabled ||
              (isCompetitors && TYPE_TO_SEARCH_ATTRIBUTES.includes(attribute) && !searchValue)
                ? { display: 'none' }
                : undefined
            }
          />
        ),
      }}
      sx={styles.ruleValueSelect}
      onChange={(_, values) => {
        const valueList = values.map((value) => value.key);
        onChange(valueList);
        setSelectedValues(values);
      }}
      inputValue={inputValue}
      onInputChange={(event, newValue, reason) => {
        if (reason === 'reset' && (!event || event.type === 'click')) return;
        setInputValue(newValue);
        debounceSearch(newValue);
      }}
      disabled={isDisabled}
    />
  );
};
