import { useEffect } from 'react';

import type { GridFilterModel, GridRowId, GridRowModel, GridSortModel } from '@mui/x-data-grid-pro';
import { useBasicEntities, useEntityCount } from '@trustyou/shared';

import type {
  AllowedSortField,
  ReviewEnvelopeDetailSchema,
  ReviewSchema,
  ReviewView_Input as ReviewView,
  SortOrder,
} from '../../client';
import {
  useFetchPaginatedReviews,
  useFetchSubcategoryMapping,
  useFetchTopLevelCategoryMapping,
} from '../../hooks';
import { useStore } from '../../store/store';
import type { PrePayload } from '../../types';
import { getReviewListDate } from '../../utils/date';
import { excludeFalsyFilters } from '../../utils/mappers';
import { getAuthor } from '../../utils/review';

// Copied from `node_modules/@mui/x-data-grid-generator/hooks/useQuery.d.ts`.
export interface QueryOptions {
  cursor?: GridRowId;
  page?: number;
  pageSize?: number;
  filterModel?: GridFilterModel;
  sortModel?: GridSortModel;
  firstRowToRender?: number;
  lastRowToRender?: number;
}

function getFormattedReviewTitleAndText(review: ReviewSchema) {
  if (review.title) return review.title?.concat(review.text ? ' | ' + review.text : '');
  return review.text;
}

export function useReviewsData(
  queryOptions: QueryOptions,
  filters: ReviewView
): {
  rows: GridRowModel[];
  filteredTotal?: number;
  isLoading: boolean;
  isRefetching: boolean;
  refetch: () => void;
} {
  const updateReviewsList = useStore.use.updateReviewsList();
  const updateRestrictedScope = useStore.use.updateRestrictedScope();

  const categoryMappingQuery = useFetchTopLevelCategoryMapping();
  const subcategoryMappingQuery = useFetchSubcategoryMapping();

  const { data: totalEntityCount = 0 } = useEntityCount();

  const prePayload: PrePayload = {
    page: queryOptions.page ?? 0,
    pageSize: queryOptions.pageSize ?? 50,
    sort: {
      field: queryOptions.sortModel?.[0]?.field as AllowedSortField,
      order: queryOptions.sortModel?.[0]?.sort as SortOrder,
    },
    filters: excludeFalsyFilters(filters, categoryMappingQuery.data, subcategoryMappingQuery.data),
  };
  const {
    data: paginatedReviewsData,
    isPending: isLoading,
    isRefetching,
    refetch,
  } = useFetchPaginatedReviews(prePayload);
  const { data: entityData } = useBasicEntities(
    paginatedReviewsData?.data.map((item) => item.meta.entity_id) ?? []
  );

  useEffect(() => {
    if (paginatedReviewsData) {
      updateReviewsList(paginatedReviewsData.data.map((item) => item.review));
      // The `meta.restricted_scope` is an array of entity ids that the backend exposes when the inbox has been implicitly filtered by the LIMIT (25) of permitted entities at the same time.
      // This is a solution to prevent performance issues and long loading times for organizations with a lot of entities.
      updateRestrictedScope(paginatedReviewsData.meta.restricted_scope);
    } else {
      updateReviewsList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatedReviewsData]);

  if (!paginatedReviewsData) {
    return { rows: [], filteredTotal: undefined, isLoading: true, isRefetching: true, refetch };
  }

  return {
    rows: paginatedReviewsData.data.map((item) => {
      const entityName = entityData?.find(({ id }) => id === item.meta.entity_id)?.name;
      return {
        id: item.id,
        score: item.review.score,
        author: getAuthor(item as ReviewEnvelopeDetailSchema),
        text: getFormattedReviewTitleAndText(item.review),
        source: {
          href: item.review.url,
          label: item.review.domain_name,
        },
        status: item.meta.status,
        date: getReviewListDate(item.review.date),
        ...(entityName && totalEntityCount > 1
          ? {
              entity: entityName,
            }
          : {}),
      };
    }),
    filteredTotal: paginatedReviewsData.meta.filtered_total ?? undefined,
    isLoading,
    isRefetching,
    refetch,
  };
}
