import {
  type CurrentUser,
  USER_ROLES,
  areUITestsRunning,
  useAuthorizationStore,
  useMembershipStore,
} from '@trustyou/shared';

const useCurrentUser = (): CurrentUser | undefined => {
  const { tokenStore } = useAuthorizationStore();
  const { membership } = useMembershipStore();

  const membershipId = membership?.id;

  if (areUITestsRunning())
    return {
      id: '1',
      email: 'testUser@gmail.com',
      role: USER_ROLES.SERVICE,
    };

  if (!membershipId) {
    return undefined;
  }

  if (!tokenStore || !tokenStore[membershipId]) {
    return undefined;
  }

  return {
    id: tokenStore[membershipId].org_center?.claims.scope_id as string,
    email: tokenStore[membershipId].org_center?.claims.email as string,
    role: tokenStore[membershipId].org_center?.claims.role as USER_ROLES,
    membershipId,
  };
};

export default useCurrentUser;
