export const useGTMProps = <T>(props: T & { disableGTMTracking?: boolean }, testId?: string) => {
  if (!testId) return props;
  if (props.disableGTMTracking)
    return {
      'data-testid': testId,
      ...props,
    };
  return {
    'data-testid': testId,
    'data-gtm-class': testId,
    'data-gtm-id': testId,
    ...props,
  };
};
