import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import type { SubscriptionStatus } from '@trustyou/shared';
import { Chip } from '@trustyou/ui';

const ActiveStatusChip = () => (
  <Chip
    label={<FormattedMessage id="subscription.status.active" defaultMessage="Active" />}
    color="success"
    variant="pastel"
  />
);

const SuspendedStatusChip = () => (
  <Chip
    label={<FormattedMessage id="subscription.status.suspended" defaultMessage="Suspended" />}
    color="warning"
    variant="pastel"
  />
);

export const SUBSCRIPTION_STATUS_CHIP_MAP: { [key in SubscriptionStatus]: ReactNode } = {
  active: <ActiveStatusChip />,
  suspended: <SuspendedStatusChip />,
};
