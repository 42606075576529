import { useState } from 'react';
import { Controller, type RegisterOptions, useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import {
  EMAIL_VALIDATION,
  commonFields,
  useChangelingStore,
  useConfirm,
  useResponsive,
} from '@trustyou/shared';
import {
  Asterisk,
  Button,
  Checkbox,
  ComposableDrawerWithStickyFooter,
  ConfirmDialog,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
  snackbar,
} from '@trustyou/ui';

import { useReview } from '../../../../../hooks';
import { useDrawerWidth } from '../../../../../hooks/use-drawer-width';
import type { ResponseFormSchema, ResponseTextFieldId } from '../../../../../types';
import { MultiEmailField } from '../../../../custom-multi-email-field';
import { CustomTextField } from '../../../../custom-text-field';

const messages = defineMessages({
  title: {
    id: 'inbox.response.email-details.discard-title',
    defaultMessage: 'Leave editor and discard changes?',
  },
  content: {
    id: 'inbox.response.email-details.discard-content',
    defaultMessage: 'Your changes will be lost if you leave the email editor',
  },
});

export function EmailDetailsDrawer({
  selectedResponse,
}: {
  selectedResponse?: ResponseTextFieldId;
}) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const intl = useIntl();
  const { isPhone } = useResponsive();
  const { isChangeling } = useChangelingStore();
  const { reviewId = '' } = useParams();
  const { data: reviewRoot } = useReview({ reviewId });
  const isPublic = reviewRoot?.survey?.privacy_level === 'public';

  const { getValues, setValue, resetField, formState } = useFormContext<ResponseFormSchema>();
  const sendDifferentResponse = useWatch({ name: 'emailDetails.sendDifferentResponse' });
  const { isDialogOpen, handleAction, handleConfirm, handleCancel } = useConfirm(
    !!formState.dirtyFields.emailDetails
  );

  const { drawerWidth } = useDrawerWidth();

  const onSave = () => {
    snackbar.success(intl.formatMessage(commonFields.changesSaved));
    resetField('emailDetails', { defaultValue: getValues('emailDetails') });
    setIsDrawerOpen(false);
  };

  const onCancel = async () => {
    handleAction(() => {
      resetField('emailDetails');
      setIsDrawerOpen(false);
    });
  };

  const getResponseText = (selectedResponse?: ResponseTextFieldId) => {
    if (selectedResponse === 'response' && !!getValues('response')) {
      return getValues('response');
    } else if (selectedResponse === 'translatedResponse' && !!getValues('translatedResponse')) {
      return getValues('translatedResponse');
    }
    return getValues('response');
  };

  const prefillEmailResponse = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!getValues('emailDetails.differentResponse')) {
      const response = getResponseText(selectedResponse);
      setValue('emailDetails.differentResponse', response);
    }
  };

  return (
    <>
      <Button variant="outlined" fullWidth={isPhone} onClick={() => setIsDrawerOpen(true)}>
        <FormattedMessage
          id="inbox.response.email-details.edit-details"
          defaultMessage="Edit email details"
        />
      </Button>
      <ComposableDrawerWithStickyFooter
        anchor="right"
        open={isDrawerOpen}
        onClose={onCancel}
        PaperProps={{
          sx: {
            width: drawerWidth,
            paddingTop: isChangeling ? 8 : 0,
          },
        }}
      >
        <ComposableDrawerWithStickyFooter.Header
          title={intl.formatMessage({
            id: 'inbox.response.email-details.title',
            defaultMessage: 'Email details',
          })}
          sx={{ paddingInline: 3 }}
        />
        <ComposableDrawerWithStickyFooter.Content sx={{ paddingInline: 3 }}>
          <RequiredInput
            name="emailDetails.subject"
            label={intl.formatMessage({
              id: 'inbox.response.email-details.subject',
              defaultMessage: 'Subject',
            })}
          />
          <RequiredInput
            name="emailDetails.from"
            label={intl.formatMessage({
              id: 'inbox.response.email-details.from',
              defaultMessage: 'From',
            })}
            registerOptions={{ pattern: EMAIL_VALIDATION }}
          />
          <MultiEmailField
            name="emailDetails.cc"
            label={intl.formatMessage({
              id: 'inbox.response.email-details.cc',
              defaultMessage: 'CC',
            })}
            hint={intl.formatMessage({
              id: 'inbox.response.email-details.cc-hint',
              defaultMessage: 'Send a copy to this email',
            })}
          />
          <MultiEmailField
            name="emailDetails.bcc"
            label={intl.formatMessage({
              id: 'inbox.response.email-details.bcc',
              defaultMessage: 'BCC',
            })}
            hint={intl.formatMessage({
              id: 'inbox.response.email-details.bcc-hint',
              defaultMessage: 'Send a secret copy to this email',
            })}
          />
          <CheckboxField
            name="emailDetails.attachReview"
            label={intl.formatMessage({
              id: 'inbox.response.email-details.attach-original-review',
              defaultMessage: 'Attach original review text to the email',
            })}
            onClick={prefillEmailResponse}
          />
          {isPublic && (
            <CheckboxField
              name="emailDetails.sendDifferentResponse"
              label={intl.formatMessage({
                id: 'inbox.response.email-details.send-different-response',
                defaultMessage: 'Send a different response directly to the guest',
              })}
              onClick={prefillEmailResponse}
            />
          )}
          {sendDifferentResponse && (
            <CustomTextField
              id="emailDetails.differentResponse"
              nonFloatingLabel={
                <FormattedMessage
                  id="inbox.response.email-details.different-response.title"
                  defaultMessage="Email response"
                />
              }
              minRows={3}
              maxRows={20}
              fullWidth
              mainFullWidth={true}
              multiline
            />
          )}
        </ComposableDrawerWithStickyFooter.Content>
        <ComposableDrawerWithStickyFooter.Footer
          primaryButton={
            <Button
              onClick={onSave}
              type="button"
              variant="contained"
              disabled={!!Object.keys(formState.errors).length}
            >
              <FormattedMessage id="inbox.action.save" defaultMessage="Save" />
            </Button>
          }
          secondaryButton={
            <Button onClick={onCancel} type="button" color="inherit">
              <FormattedMessage id="inbox.action.cancel" defaultMessage="Cancel" />
            </Button>
          }
          sx={{ boxShadow: 5 }}
        />
      </ComposableDrawerWithStickyFooter>
      <ConfirmDialog
        open={isDialogOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        title={intl.formatMessage(messages.title)}
        content={intl.formatMessage(messages.content)}
      />
    </>
  );
}

function RequiredInput({
  name,
  label,
  registerOptions,
}: {
  name: any;
  label: string;
  registerOptions?: RegisterOptions<ResponseFormSchema>;
}) {
  const { control } = useFormContext<ResponseFormSchema>();

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: true, ...registerOptions }}
      render={({ field, fieldState: { error } }) => (
        <Stack spacing={1}>
          <Typography variant="body2">
            {label} <Asterisk />
          </Typography>
          <TextField {...field} fullWidth error={!!error} helperText={error?.message ?? ' '} />
        </Stack>
      )}
    />
  );
}

function CheckboxField({
  name,
  label,
  onClick,
}: {
  name: any;
  label: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  const { control } = useFormContext<ResponseFormSchema>();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <FormControlLabel
          control={<Checkbox {...field} checked={field.value ?? false} onClick={onClick} />}
          label={label}
        />
      )}
    />
  );
}
