import { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import type { FormControlProps } from '@mui/material';
import { type FiltersField, commonFields } from '@trustyou/shared';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  ListItem,
  ListItemText,
  TextField,
} from '@trustyou/ui';

import { FONT_SIZE, MIN_FIELD_HEIGHT } from './constants';
import { CustomPaperComponent } from './custom-paper-component';

import type { Option } from '../../types';

export type DropdownMultipleAutocompleteProps = FormControlProps & {
  fieldName: FiltersField;
  label: string;
  options: Option[];
  initialValues: string[];
};

export function DropdownMultipleAutocomplete({
  fieldName,
  label,
  options,
  initialValues,
  ...props
}: DropdownMultipleAutocompleteProps) {
  const intl = useIntl();
  const { control } = useFormContext();

  const getPlaceholder = (value: Option[] | string[] | null) => {
    if (!value || value.length === 0) {
      return intl.formatMessage(commonFields.all);
    }
    if (value.length === options.length) {
      return '';
    }
    return 'More...';
  };

  const [selectedValues, setSelectedValues] = useState(initialValues);
  const [placeholder, setPlaceholder] = useState(getPlaceholder(selectedValues));

  const memoizedSelectedValues = useMemo(
    () => options.filter((option) => selectedValues.includes(option.value)),
    [options, selectedValues]
  );

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field: { value, onChange } }) => (
        <FormControl {...props}>
          <Autocomplete
            multiple
            limitTags={1}
            fullWidth
            disableCloseOnSelect
            openOnFocus
            autoHighlight
            options={options}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            value={memoizedSelectedValues}
            onChange={(event, value) => {
              setSelectedValues(value.map((v) => v.value));
              setPlaceholder(getPlaceholder(value));
              onChange(value.map((v) => v.value));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                placeholder={placeholder}
                slotProps={{ inputLabel: { shrink: true } }}
                sx={{
                  minHeight: MIN_FIELD_HEIGHT,
                  fontSize: FONT_SIZE,
                }}
              />
            )}
            renderTags={(tagValue) =>
              tagValue.map((option) => (
                <Box
                  key={option.value}
                  component="span"
                  sx={{
                    fontSize: FONT_SIZE,
                    marginInlineStart: 0.75,
                  }}
                >
                  {option.label},
                </Box>
              ))
            }
            renderOption={(props, option, { selected }) => (
              <ListItem {...props} key={option.value} dense>
                <Checkbox
                  edge="start"
                  size="small"
                  checked={selected}
                  disableRipple
                  inputProps={{ 'aria-labelledby': option.label }}
                  sx={{
                    paddingBlock: 0,
                    paddingInline: 1,
                  }}
                />
                <ListItemText id={option.value} primary={option.label} />
              </ListItem>
            )}
            slots={{ paper: CustomPaperComponent }}
            sx={{
              '& .MuiAutocomplete-tag': {
                fontSize: FONT_SIZE,
                marginInline: 0.5,
              },
            }}
          />
        </FormControl>
      )}
    />
  );
}
