import { useIntl } from 'react-intl';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthWrapper as AnalyticsAuthWrapper } from '@trustyou/analytics';
import { Stack } from '@trustyou/ui';

import FiltersLoader from './filters-loader';
import { ReportDashboard } from './report-dashboard';

import { newReportWizardMessages } from '../../../../constants/messages';
import { useNewReportStore } from '../../../../store';
import { Footer } from '../footer';
import styles from '../styles';

export const FiltersStep = () => {
  const intl = useIntl();
  const { activeStep, isDashboardLoaded, newReport, nextStep, prevStep } = useNewReportStore();
  const hasWidgetsSelected = !!(newReport?.widgets || []).length;
  return (
    <>
      <Stack sx={{ ...styles.stepBody, ...(activeStep !== 1 && { display: 'none' }) }}>
        <AnalyticsAuthWrapper loader={<FiltersLoader />}>
          <ReportDashboard />
        </AnalyticsAuthWrapper>
      </Stack>
      {activeStep === 1 && (
        <Footer
          onClickBack={prevStep}
          onClickNext={nextStep}
          disableNext={!isDashboardLoaded || !hasWidgetsSelected}
          nextButtonTooltip={
            isDashboardLoaded && !hasWidgetsSelected
              ? intl.formatMessage(newReportWizardMessages.widgetsSelectWarning)
              : undefined
          }
        />
      )}
    </>
  );
};
