import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { styled } from '@mui/material';
import { useResponsive } from '@trustyou/shared';
import { Box, FormControlLabel, Radio, Stack, TextField, snackbar } from '@trustyou/ui';

import { ResponseFieldActionWrapper } from './response-field-action-wrapper';

import { useLanguage } from '../../../../hooks';
import { useStore } from '../../../../store/store';
import type { Language, ResponseFormSchema, ResponseTextFieldId } from '../../../../types';
import { DropdownChip, type Option } from '../../../dropdowns/dropdown-chip';
import { ResponseAIRatingDesktop } from '../../../response-ai/response-ai-rating/response-ai-rating-desktop';

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '2px',
    },
  },
});

type ResponseTextFieldProps = {
  fieldId: ResponseTextFieldId;
  language?: Language;
  isSelected?: boolean;
  isDisabled?: boolean;
  showRadioButton?: boolean;
  setSelectedResponse?: (response: ResponseTextFieldId) => void;
  onChangeLanguage?: (newLanguage: Option, textFieldId: ResponseTextFieldId) => void;
};

export function ResponseTextField({
  fieldId,
  language = 'EN',
  isSelected,
  isDisabled,
  showRadioButton,
  setSelectedResponse,
  onChangeLanguage = () => {},
}: ResponseTextFieldProps) {
  const intl = useIntl();
  const { isMobile, isDesktop } = useResponsive();
  const { control, getValues, resetField } = useFormContext<ResponseFormSchema>();
  const { languages } = useLanguage();
  const hasGeneratedResponse = useStore.use.hasGeneratedResponse();
  const isRatingDesktopVisible = fieldId === 'response' && hasGeneratedResponse && isDesktop;
  const isRatingMobileVisible = fieldId === 'response' && hasGeneratedResponse && isMobile;

  const handleCopy = () => {
    navigator.clipboard.writeText(getValues?.(fieldId) as string);
    snackbar.success(
      intl.formatMessage({
        id: 'inbox.response.copy.successful',
        defaultMessage: 'Copied to clipboard',
      })
    );
  };

  const handleEdit = () => {
    setSelectedResponse?.(fieldId);
  };

  const handleDelete = () => {
    resetField(fieldId);
  };

  return (
    <Stack spacing={0.5}>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          justifyItems: 'center',
        }}
      >
        {hasGeneratedResponse &&
          (showRadioButton ? (
            <FormControlLabel
              value={fieldId}
              control={<Radio size="small" checked={isSelected} data-testid={`${fieldId}-radio`} />}
              label={
                <LanguageDropdown
                  fieldId={fieldId}
                  options={languages}
                  initialValue={language}
                  onChangeLanguage={onChangeLanguage}
                />
              }
            />
          ) : (
            <LanguageDropdown
              fieldId={fieldId}
              options={languages}
              initialValue={language}
              onChangeLanguage={onChangeLanguage}
            />
          ))}
        {isRatingDesktopVisible && <ResponseAIRatingDesktop />}
      </Stack>
      <ResponseFieldActionWrapper
        isRatingMobileVisible={isRatingMobileVisible}
        onCopy={handleCopy}
        onEdit={handleEdit}
        onDelete={handleDelete}
      >
        <Box style={{ display: 'inline-block', position: 'relative', width: '100%' }}>
          <Controller
            name={fieldId}
            control={control}
            render={({ field }) => (
              <StyledTextField
                {...field}
                placeholder={intl.formatMessage({
                  id: 'inbox.response.placeholder',
                  defaultMessage: 'Type your response here',
                })}
                slotProps={{ htmlInput: { 'data-testid': `${fieldId}-textarea` } }}
                disabled={isDisabled}
                fullWidth
                multiline
                minRows={4}
              />
            )}
          />
        </Box>
      </ResponseFieldActionWrapper>
    </Stack>
  );
}

function LanguageDropdown({
  fieldId,
  initialValue,
  options = [],
  onChangeLanguage,
}: {
  fieldId: ResponseTextFieldId;
  initialValue: Language | 'AUTO';
  options: Option[];
  onChangeLanguage: (newLanguage: Option, textFieldId: ResponseTextFieldId) => void;
}) {
  const { getLanguageSourceByCode } = useLanguage();
  const option = {
    label: getLanguageSourceByCode(initialValue).language_source_name ?? '',
    value: getLanguageSourceByCode(initialValue).language_source ?? '',
  };

  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
      <DropdownChip
        title={fieldId}
        options={options}
        initialValue={option}
        onChange={(value) => onChangeLanguage(value, fieldId)}
      />
    </Stack>
  );
}
