import type { ChipProps } from '@mui/material';

import { TRUSTYOU_SURVEY_DOMAIN_NAME } from '../constants';
import { useStore } from '../store/store';
import type { ReviewRoot } from '../types';

export function isTrustYouFeedback(reviewRoot?: ReviewRoot) {
  return reviewRoot?.review.domain_name === TRUSTYOU_SURVEY_DOMAIN_NAME;
}

export function getAuthor(review: ReviewRoot) {
  if (review.review.author) return review.review.author;

  const guest_info_author = review.survey?.visit?.guest_info?.reduce((acc, curr) => {
    if (curr.internal_key === 'guest_first_name') {
      return (curr.answer?.value + ' ' + acc).trim();
    }
    if (curr.internal_key === 'guest_last_name') {
      return (acc + ' ' + curr.answer?.value).trim();
    }
    return acc;
  }, '');
  if (guest_info_author) return guest_info_author;

  return '';
}

export function useSurroundingReviewIds(currentId: string) {
  const reviewsList = useStore.use.reviewsList();

  const currentIndex = reviewsList.findIndex(({ id }) => id === currentId);
  const previousIndex = currentIndex - 1;
  const nextIndex = currentIndex + 1;
  const prevReviewId = previousIndex === -1 ? undefined : reviewsList.at(previousIndex)?.id;
  const nextReviewId = reviewsList.at(nextIndex)?.id;

  return { prevReviewId, nextReviewId };
}

export function isDeletionPendingOrRequested(deletion: ReviewRoot['deletion']) {
  return deletion?.status === 'pending' || deletion?.status === 'requested';
}

export function isDeletionConfirmed(deletion: ReviewRoot['deletion']) {
  return deletion?.status === 'confirmed';
}

// Possible values for scale answers are 0-100 (coupled to API response)
export const getScaleAnswerColor = (value: number): ChipProps['color'] => {
  if (value <= 50) {
    return 'error';
  }
  if (value <= 70) {
    return 'warning';
  }
  return 'success';
};

// Possible values for NPS answers are 0-10 (coupled to API response)
export const getNPSAnswerColor = (value: number): ChipProps['color'] => {
  if (value <= 4) {
    return 'error';
  }
  if (value <= 6) {
    return 'warning';
  }
  return 'success';
};
