import { FormattedMessage } from 'react-intl';

import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@trustyou/ui';

type TranslateButtonProps = {
  icon: IconDefinition | null;
  loading?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export function TranslateButton({ icon, loading, onClick }: TranslateButtonProps) {
  return (
    <Button
      size="small"
      loading={loading}
      loadingPosition="start"
      startIcon={icon && <FontAwesomeIcon icon={icon} />}
      onClick={onClick}
      data-gtm-id="inbox_response_ai_translate"
    >
      <FormattedMessage id="inbox.response.ai.action.translate" defaultMessage="Translate" />
    </Button>
  );
}
