import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import type { FiltersField } from '@trustyou/shared';
import { Box, FormControlLabel, Switch, TextField, Typography } from '@trustyou/ui';

import { MAX_FIELD_WIDTH } from '../../../constants';
import { toTerms } from '../../../utils/keyword-search';
import { DropdownScore } from '../../dropdowns/dropdown-score';
import { DropdownSource } from '../../dropdowns/dropdown-source';

type SwitchProps = {
  fieldName: string;
  label: string;
};

export type SelectProps = {
  fieldName: string;
};

type TextFieldProps = {
  fieldName: string;
  initialValue: string;
};

export function EnabledSwitch({ fieldName, label }: SwitchProps) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field }) => (
        <FormControlLabel
          control={
            <Switch
              checked={field.value}
              onChange={(event) => field.onChange(event.target.checked)}
            />
          }
          label={label}
        />
      )}
    />
  );
}

export function ScoreSelect({ fieldName }: SelectProps) {
  return (
    <Box maxWidth={MAX_FIELD_WIDTH}>
      <DropdownScore fieldName={fieldName as FiltersField} fullWidth />
    </Box>
  );
}

export function SourceSelect({ fieldName }: SelectProps) {
  return (
    <Box maxWidth={MAX_FIELD_WIDTH}>
      <DropdownSource fieldName={fieldName as FiltersField} fullWidth />
    </Box>
  );
}

export function KeywordsTextarea({ fieldName, initialValue }: TextFieldProps) {
  const intl = useIntl();
  const { control, setValue } = useFormContext();
  const [inputValue, setInputValue] = useState(initialValue);

  const handleTyping = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
    setValue(fieldName, toTerms(event.target.value));
  };

  return (
    <Box maxWidth={MAX_FIELD_WIDTH}>
      <Typography variant="body2" gutterBottom>
        {intl.formatMessage({
          id: 'inbox.settings.notifications.keywords-statement',
          defaultMessage: 'Include only reviews that contain the following keywords:',
        })}
      </Typography>
      <Controller
        control={control}
        name={fieldName}
        render={({ field }) => (
          <TextField
            {...field}
            value={inputValue}
            onChange={handleTyping}
            placeholder={intl.formatMessage({
              id: 'inbox.settings.notifications.keywords.placeholder',
              defaultMessage: 'Enter keywords',
            })}
            helperText={intl.formatMessage({
              id: 'inbox.search.info',
              defaultMessage: 'To search for an exact phrase, put it in quotation marks (" ")',
            })}
            multiline
            minRows={2}
            fullWidth
            slotProps={{ inputLabel: { shrink: true } }}
          />
        )}
      />
    </Box>
  );
}
