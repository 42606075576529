import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { faInfoCircle } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { useBenchmarkSets } from '@trustyou/shared';
import {
  Autocomplete,
  FormControl,
  FormFieldLabel,
  StyledFontAwesomeIcon,
  TextField,
  Tooltip,
} from '@trustyou/ui';

import { manageDrawer } from '../../../constants/messages/benchmarks';
import { styles } from './styles';

export const SetController = () => {
  const { control, setValue } = useFormContext();
  const intl = useIntl();
  const { data: benchmarkSets = [], isPending: isSearching } = useBenchmarkSets();

  const getMatchedValue = (newValue: string) =>
    benchmarkSets.find((value) => value.toLowerCase() === newValue.trim().toLowerCase()) ||
    newValue;

  const handleValueSet = (value?: string) => {
    setValue('set_name', value, { shouldValidate: true, shouldDirty: true });
  };

  return (
    <FormControl>
      <FormFieldLabel required>
        {intl.formatMessage(manageDrawer.setNameTitle)}
        <Tooltip placement="top" arrow title={intl.formatMessage(manageDrawer.setNameInfo)}>
          <StyledFontAwesomeIcon icon={faInfoCircle} sx={{ marginLeft: 1 }} />
        </Tooltip>
      </FormFieldLabel>
      <Controller
        name="set_name"
        control={control}
        rules={{
          required: intl.formatMessage(manageDrawer.setNameRequired),
        }}
        render={({ field, fieldState }) => (
          <Autocomplete
            autoHighlight
            value={field.value}
            loading={isSearching}
            loadingText={intl.formatMessage(manageDrawer.setNameLoading)}
            sx={styles.setField}
            onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
              handleValueSet(event.target.value ? getMatchedValue(event.target.value) : '');
            }}
            onChange={(_, newValue) => {
              if (typeof newValue === 'string') {
                handleValueSet(getMatchedValue(newValue));
              } else if (!newValue) {
                handleValueSet('');
              } else {
                handleValueSet(newValue.inputValue || newValue.title);
              }
            }}
            filterOptions={(options, params) => {
              const { inputValue } = params;
              const filteredOptions = options.filter((option) =>
                option.title.toLowerCase().includes(inputValue.toLowerCase())
              );
              if (inputValue && !filteredOptions.length) {
                return [
                  {
                    inputValue,
                    title: intl.formatMessage(manageDrawer.setNameAdd, { name: inputValue }),
                  },
                ];
              }
              return filteredOptions;
            }}
            openOnFocus
            selectOnFocus
            handleHomeEndKeys
            id="benchmark-set"
            options={benchmarkSets.sort().map((title) => ({ title }))}
            getOptionLabel={(option) =>
              typeof option === 'string' ? option : option.inputValue || option.title
            }
            renderOption={(props, option) => (
              <li {...props} key={props.id}>
                {option.title}
              </li>
            )}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                placeholder={intl.formatMessage(manageDrawer.setNamePlaceholder)}
                onChange={(event) => {
                  field.onChange(event.target.value);
                }}
              />
            )}
          />
        )}
      />
    </FormControl>
  );
};
