import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { faArrowRotateRight } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Button } from '@trustyou/ui';

import { PAGINATED_REVIEWS } from '../../constants/query-keys';

export const RefreshButton = () => {
  const queryClient = useQueryClient();
  const [loading, setRefreshing] = useState(false);

  const handleRefresh = async () => {
    setRefreshing(true);
    await queryClient.invalidateQueries({ queryKey: [PAGINATED_REVIEWS] });
    setRefreshing(false);
  };

  return (
    <Button
      size="small"
      startIcon={<FontAwesomeIcon icon={faArrowRotateRight} />}
      loading={loading}
      loadingPosition="start"
      onClick={handleRefresh}
      data-gtm-class="inbox_table_actions"
      data-gtm-id="inbox_table_refresh"
    >
      <FormattedMessage id="inbox.refresh" defaultMessage="Refresh" />
    </Button>
  );
};
