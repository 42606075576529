import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useEmailSettings, useUpdateEmailSettings } from '@trustyou/inbox';
import { commonFields } from '@trustyou/shared';
import { Box, Button, LinearProgress, Stack, Typography, snackbar } from '@trustyou/ui';

import { EntitiesSection } from './entities-section';
import { FeedbacksSection } from './feedbacks-section';
import { ReviewsSection } from './reviews-section';

import type { EmailNotificationSettings_Input } from '../../../client';
import useGetSettingsPermission from '../../../hooks/permissions/use-get-settings-permission';
import useModerationRevisionPermission from '../../../hooks/permissions/use-moderation-revision-permission';
import useSetSettingsPermission from '../../../hooks/permissions/use-set-settings-permission';
import { SurveyReviewModerationSection } from './survey-moderation-section';

const DEFAULT_VALUES: EmailNotificationSettings_Input = {
  filters: {
    entities: [],
  },
  reviews: [
    {
      enabled: false,
      schedule_type: 'daily',
      schedule_hour: 2,
      filters: {
        score: [],
        source: [],
        search_terms: [],
      },
    },
  ],
  feedbacks: [
    {
      enabled: false,
      schedule_type: 'real_time',
      filters: {
        score: [],
      },
    },
  ],
  survey_moderation: {
    enabled: false,
  },
};

export function EmailSettingsForm() {
  const intl = useIntl();
  const isAllowedToGetSettings = useGetSettingsPermission();
  const isAllowedToSetSettings = useSetSettingsPermission();
  const hasModerationPermission = useModerationRevisionPermission();

  const emailSettings = useEmailSettings();
  const updateEmailSettings = useUpdateEmailSettings();

  const methods = useForm<EmailNotificationSettings_Input>({
    values: emailSettings.data,
    defaultValues: DEFAULT_VALUES,
    resetOptions: {
      keepDirtyValues: true, // user-interacted input will be retained
    },
  });
  const { handleSubmit } = methods;

  const onSubmit = (data: EmailNotificationSettings_Input) => {
    if (!isAllowedToSetSettings) {
      snackbar.warning('Permissions are needed to change Inbox settings');
      return;
    }
    if (data.filters.entities?.length === 0) {
      snackbar.warning(
        intl.formatMessage({
          id: 'inbox.settings.notifications.entities.select-at-least-one-entity',
          defaultMessage: 'Select at least one entity',
        })
      );
      return;
    }
    updateEmailSettings.mutate(data, {
      onSuccess: () => {
        snackbar.success(intl.formatMessage(commonFields.changesSaved));
      },
    });
  };

  if (!isAllowedToGetSettings) {
    snackbar.warning('Permissions are needed to see Inbox settings');
    return;
  }

  if (emailSettings.isPending) {
    return <LinearProgress />;
  }

  return (
    <FormProvider {...methods}>
      <Typography variant="h6" sx={{ marginBlockEnd: 2 }}>
        <FormattedMessage
          id="inbox.settings.notifications.title"
          defaultMessage="Inbox notifications"
        />
      </Typography>
      <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
        <EntitiesSection />
        <ReviewsSection />
        <FeedbacksSection />
        {hasModerationPermission && <SurveyReviewModerationSection />}
        <Box sx={{ alignSelf: 'end' }}>
          <Button
            loading={updateEmailSettings.isPending}
            data-testid="save-changes-inbox-notifications"
            variant="contained"
            type="submit"
            sx={{ marginBlockStart: 5 }}
          >
            <FormattedMessage {...commonFields.saveChanges} />
          </Button>
        </Box>
      </Stack>
    </FormProvider>
  );
}
