import { useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { faShare } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { useChangelingStore, useConfirm } from '@trustyou/shared';
import {
  Button,
  ComposableDrawerWithStickyFooter,
  ConfirmDialog,
  Stack,
  snackbar,
} from '@trustyou/ui';

import type { ForwardReviewIn } from '../../../client';
import { useForwardReview } from '../../../hooks';
import { useDrawerWidth } from '../../../hooks/use-drawer-width';
import { MultiEmailField } from '../../custom-multi-email-field';
import { CustomTextField } from '../../custom-text-field';
import { IconButtonWithTooltip } from '../../icon-button-with-tooltip';

export function ForwardReview() {
  // Needed to trigger form submission outside the form HTML element
  const FORM_ID = 'forward-review-form';

  const { accessTokenPayload } = useOidcAccessToken();
  const { reviewId = '' } = useParams();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const intl = useIntl();

  const methods = useForm<ForwardReviewIn>({
    mode: 'onChange',
    defaultValues: {
      sender: accessTokenPayload.name,
      emails: [],
      additional_comment: '',
    },
  });
  const { formState, control, reset, handleSubmit, getValues } = methods;
  const { isDialogOpen, handleAction, handleConfirm, handleCancel } = useConfirm(formState.isDirty);
  const { drawerWidth } = useDrawerWidth();
  const { isChangeling } = useChangelingStore();

  const forwardReview = useForwardReview();

  const emails = useWatch({ control, name: 'emails' });

  const onCancel = () => {
    handleAction(() => {
      reset();
      setIsDrawerOpen(false);
    });
  };

  const onSend = () => {
    forwardReview.mutate(
      { reviewId, payload: getValues() },
      {
        onSuccess: () => {
          reset();
          setIsDrawerOpen(false);
          snackbar.success(
            intl.formatMessage({
              id: 'inbox.review.action.forward.success',
              defaultMessage: 'Review forwarded',
            })
          );
        },
        onError: () => {
          snackbar.error(
            intl.formatMessage({
              id: 'inbox.review.action.forward.error',
              defaultMessage: 'Couldn’t forward the review, please try again',
            })
          );
        },
      }
    );
  };

  return (
    <>
      <IconButtonWithTooltip
        data-gtm-class="review_action_on_review"
        data-gtm-id="forward"
        onClick={() => setIsDrawerOpen(true)}
        icon={faShare}
        tooltip={
          <FormattedMessage id="inbox.review.action.forward" defaultMessage="Forward review" />
        }
        color="primary"
      />
      <FormProvider {...methods}>
        <ComposableDrawerWithStickyFooter
          anchor="right"
          open={isDrawerOpen}
          onClose={onCancel}
          PaperProps={{
            sx: {
              width: drawerWidth,
              paddingTop: isChangeling ? 8 : 0,
            },
          }}
        >
          <ComposableDrawerWithStickyFooter.Header
            title={intl.formatMessage({
              id: 'inbox.review.forward.drawer.title',
              defaultMessage: 'Forward review',
            })}
            sx={{ paddingInline: 3 }}
          />
          <ComposableDrawerWithStickyFooter.Content sx={{ paddingInline: 3 }}>
            <Stack component="form" id={FORM_ID} onSubmit={handleSubmit(onSend)} spacing={3}>
              <MultiEmailField
                name="emails"
                label={intl.formatMessage({
                  id: 'inbox.review.forward.drawer.email-details.email',
                  defaultMessage: 'Email',
                })}
                description={intl.formatMessage({
                  id: 'inbox.review.forward.drawer.email-details.email-description',
                  defaultMessage: 'Enter one or more email addresses',
                })}
                isRequired
              />
              <CustomTextField
                id="additional_comment"
                nonFloatingLabel={
                  <FormattedMessage
                    id="inbox.review.forward.drawer.email-details.additional-comment"
                    defaultMessage="Additional comment"
                  />
                }
                minRows={3}
                maxRows={20}
                fullWidth
                mainFullWidth={true}
                multiline
              />
            </Stack>
          </ComposableDrawerWithStickyFooter.Content>
          <ComposableDrawerWithStickyFooter.Footer
            primaryButton={
              <Button
                type="submit"
                form={FORM_ID}
                variant="contained"
                disabled={!!Object.keys(formState.errors).length || !emails.length}
              >
                <FormattedMessage id="inbox.action.send" defaultMessage="Send" />
              </Button>
            }
            secondaryButton={
              <Button onClick={onCancel} color="inherit">
                <FormattedMessage id="inbox.action.cancel" defaultMessage="Cancel" />
              </Button>
            }
            sx={{ boxShadow: 5 }}
          />
        </ComposableDrawerWithStickyFooter>
      </FormProvider>
      <ConfirmDialog
        open={isDialogOpen}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        title={intl.formatMessage({
          id: 'inbox.review.forward.drawer.leave-title',
          defaultMessage: 'Abort forward and discard changes?',
        })}
        content={intl.formatMessage({
          id: 'inbox.review.forward.drawer.leave-content',
          defaultMessage: 'If you leave, your changes will be lost.',
        })}
      />
    </>
  );
}
