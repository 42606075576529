import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { UserViewTypes, isRouteEndsWith, navigationNodes, useUserNavStore } from '@trustyou/shared';
import { Box, ContentPane, Tab, Tabs } from '@trustyou/ui';

import { AddAccessGroupButton } from './AccessGroups';
import { InviteMemberButton } from './components/InviteMemberButton/InviteMemberButton';
import styles from './users.styles';

import { useManageAccessGroups, useManageEntitySubscriptions } from '../../../hooks';

export const Users = () => {
  const [selectedTab, setSelectedTab] = useState<string>();
  const location = useLocation();
  const navigate = useNavigate();
  const { view } = useUserNavStore();

  const isManageAccessGroupsAllowed = useManageAccessGroups();
  const isEntitySubscriptionsAllowed = useManageEntitySubscriptions();

  const tabs = [{ id: 'members', path: 'members', label: navigationNodes.users }];
  if (isManageAccessGroupsAllowed) {
    tabs.push({ id: 'access-groups', path: 'access-groups', label: navigationNodes.accessGroups });
  }

  const isChildRoute =
    selectedTab === 'access-groups' && !isRouteEndsWith(location.pathname, 'access-groups');

  useEffect(() => {
    if (isRouteEndsWith(location.pathname, 'organization/users')) {
      navigate(tabs[0].path, { replace: true });
    }
    tabs.forEach((tab) => {
      if (location.pathname.includes(tab.path)) {
        setSelectedTab(tab.id);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (isChildRoute || view.type === UserViewTypes.INVITE || view.type === UserViewTypes.EDIT) {
    return <Outlet />;
  }

  return (
    <ContentPane sx={styles.contentPane} bodyStyles={styles.contentPaneBody}>
      <Box sx={styles.tabsContainer}>
        <Tabs value={selectedTab || 'members'} sx={styles.tabs}>
          {tabs.map((tab) => (
            <Tab
              key={tab.id}
              value={tab.id}
              label={<FormattedMessage {...tab.label} />}
              component={Link}
              to={tab.path}
            />
          ))}
        </Tabs>
        {selectedTab === 'members' && !isEntitySubscriptionsAllowed && <InviteMemberButton />}
        {selectedTab === 'access-groups' && <AddAccessGroupButton />}
      </Box>
      <Box sx={styles.body}>
        <Outlet />
      </Box>
    </ContentPane>
  );
};
