import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { faPlus } from '@trustyou/fortawesome/pro-regular-svg-icons';
import type { BenchmarkDefinitionRule, Entity } from '@trustyou/shared';
import {
  Alert,
  Button,
  Chip,
  StyledFontAwesomeIcon as Icon,
  Stack,
  Typography,
} from '@trustyou/ui';

import { AdditionalCompetitorsModal } from './AdditionalCompetitorsModal';
import { DefinitionRuleSelector } from './DefinitionRuleSelector/DefinitionRuleSelector';

import { manageDrawer } from '../../../constants/messages/benchmarks';
import { styles } from './styles';

export const CompetitorsTab = () => {
  const [isEntitySelectorOpen, setIsEntitySelectorOpen] = useState(false);
  const { setValue, watch, clearErrors, formState } = useFormContext();
  const rules = watch('competitors_rules');
  const selectedEntities = watch('additional_competitors');

  useEffect(() => {
    if (
      selectedEntities.length ||
      rules.map((rule: BenchmarkDefinitionRule) => rule.values).flat().length
    ) {
      clearErrors('competitors_definition');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rules, selectedEntities]);

  const toggleIsEntitySelectorOpen = () => {
    setIsEntitySelectorOpen((state) => !state);
  };

  const onAdditionalCompetitorsModalSave = (values: Entity[]) => {
    setValue('additional_competitors', values, { shouldDirty: true });
    toggleIsEntitySelectorOpen();
  };

  return (
    <Stack gap={1}>
      {Object.keys(formState.errors).includes('competitors_definition') && (
        <Alert severity="error">
          <FormattedMessage {...manageDrawer.noCompetitorsError} />
        </Alert>
      )}
      <Stack marginTop={1}>
        <Typography variant="subtitle1">
          <FormattedMessage {...manageDrawer.competitorsRulesHeader} />
        </Typography>
        <Typography variant="body2">
          <FormattedMessage {...manageDrawer.competitorsRulesSecondaryHeader} />
        </Typography>
      </Stack>
      <DefinitionRuleSelector
        rules={rules}
        isCompetitors
        onChange={(values) => setValue('competitors_rules', values)}
      />
      <Stack marginTop={4} direction="row" justifyContent="space-between">
        <Typography variant="body2">
          <FormattedMessage {...manageDrawer.additionalCompetitorsHeader} />
        </Typography>
        {!!selectedEntities.length && (
          <Chip
            label={
              <FormattedMessage
                {...manageDrawer.additionalEntitiesCount}
                values={{ count: selectedEntities.length }}
              />
            }
            color="info"
            variant="pastel"
            onClick={toggleIsEntitySelectorOpen}
          />
        )}
      </Stack>
      <Button sx={styles.selectEntitiesButton} onClick={toggleIsEntitySelectorOpen}>
        <Icon icon={faPlus} />
        <FormattedMessage {...manageDrawer.additionalCompetitorsSelectButton} />
      </Button>
      {isEntitySelectorOpen && (
        <AdditionalCompetitorsModal
          defaultItems={selectedEntities}
          onClose={toggleIsEntitySelectorOpen}
          onSave={onAdditionalCompetitorsModalSave}
        />
      )}
    </Stack>
  );
};
