import { ListSubheader, type ListSubheaderProps, lighten } from '@mui/material';

export function StyledListSubheader({ sx, ...props }: ListSubheaderProps) {
  return (
    <ListSubheader
      sx={{
        lineHeight: 2.5,
        color: (theme) => theme.palette.primary.dark,
        backgroundColor: (theme) => lighten(theme.palette.primary.dark, 0.96),
        ...sx,
      }}
      {...props}
      role="heading"
    />
  );
}
