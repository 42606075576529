import type { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';

import type { TabsProps } from '@mui/material';
import { useResponsive } from '@trustyou/shared';
import { Box, Tab, Tabs } from '@trustyou/ui';

import type { ResponseTab } from '../../../../types';

type ResponseTabsProps = {
  activeTab: ResponseTab;
  onChange: TabsProps['onChange'];
  isResponseAITabVisible: boolean;
};

export function ResponseTabs({ activeTab, onChange, isResponseAITabVisible }: ResponseTabsProps) {
  const intl = useIntl();
  const { isMobile } = useResponsive();

  return (
    <Tabs
      value={activeTab}
      onChange={onChange}
      aria-label="response tabs"
      variant={isMobile ? 'fullWidth' : 'standard'}
      sx={{
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        ...(isMobile && {
          marginBlockStart: 2,
        }),
      }}
    >
      {isResponseAITabVisible && (
        <Tab
          label={intl.formatMessage({ id: 'inbox.responseAI', defaultMessage: 'ResponseAI' })}
          value="response_ai"
          {...a11yProps('response_ai')}
        />
      )}
      <Tab
        label={intl.formatMessage({ id: 'inbox.template', defaultMessage: 'Template' })}
        value="template"
        {...a11yProps('template')}
      />
      <Tab
        label={intl.formatMessage({ id: 'inbox.blank', defaultMessage: 'Blank' })}
        value="blank"
        {...a11yProps('blank')}
      />
    </Tabs>
  );
}

type TabPanelProps = PropsWithChildren<{
  index: ResponseTab;
  value: ResponseTab;
}>;

export function ResponseTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`response-tabpanel-${index}`}
      aria-labelledby={`response-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ padding: 2 }}>{children}</Box>}
    </Box>
  );
}

function a11yProps(index: ResponseTab) {
  return {
    id: `response-tab-${index}`,
    'aria-controls': `response-tabpanel-${index}`,
  };
}
