import type { ReactNode } from 'react';

import styles from './ExplanationModal.styles';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  Typography,
} from '../../mui';

export interface ExplanationPoint {
  title: ReactNode;
  description: ReactNode;
}

export interface ExplanationModalProps {
  title: ReactNode;
  content?: ReactNode;
  open: boolean;
  points: ExplanationPoint[];
  buttonText: ReactNode;
  onClose: () => void;
  dataName: string;
  children?: ReactNode;
}

export const ExplanationModal = ({
  title,
  content,
  open,
  points,
  onClose,
  buttonText,
  dataName,
  children,
}: ExplanationModalProps) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      {children || (
        <>
          <Typography variant="body2">{content}</Typography>
          <List sx={styles.list}>
            {points.map((point, index) => (
              <ListItem sx={styles.point} key={index}>
                <Grid container>
                  <Grid size={1} sx={styles.pointBullet}>
                    •
                  </Grid>
                  <Grid size={11} sx={styles.pointWrapper}>
                    <Typography component="span" variant="body2" sx={styles.pointTitle}>
                      {point.title}
                    </Typography>
                    <Typography component="span">:</Typography>
                    <Typography component="span" sx={styles.pointDescription} variant="body2">
                      {point.description}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        </>
      )}
    </DialogContent>

    <DialogActions>
      <Button
        sx={styles.button}
        data-testid={`${dataName}-close`}
        onClick={onClose}
        variant="contained"
      >
        {buttonText}
      </Button>
    </DialogActions>
  </Dialog>
);
