import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  contentPaneHeaderStyles: { paddingBlockEnd: 1, justifyContent: 'space-between' },
  emptyStateStack: {
    alignItems: 'center',
    gap: 3,
  },
  emptyStateContainer: { width: '400px', margin: '0 auto', textAlign: 'center' },
};

export default styles;
