import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { faXmark } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { useRouteUtils } from '@trustyou/shared';
import { Button, SetupStepper, Stack, StyledFontAwesomeIcon, TrustYouLogo } from '@trustyou/ui';

import { FiltersStep } from './filters-step/filters-step';
import { InboxFilterStep } from './inbox-filter-step/inbox-filter-step';
import { RecipientsStep } from './recipients-step/recipients-step';

import { DiscardChangesModal } from '../../../components';
import { newReportWizardSteps } from '../../../constants/messages';
import { useNewReportStore } from '../../../store';
import { SettingsStep } from './settings-step';
import styles from './styles';

export const NewReport = () => {
  const {
    activeStep,
    showDiscardModal,
    toggleDiscardModal,
    isInboxReviews,
    setContentId,
    setActiveStep,
    init,
  } = useNewReportStore();
  const { navigateWithBase } = useRouteUtils();
  const isComingFromInbox = window.location.href.includes('fromInbox');

  const onDiscard = () => {
    toggleDiscardModal();
    navigateWithBase(isComingFromInbox ? 'inbox' : 'reports');
  };

  useEffect(() => {
    if (isComingFromInbox) {
      setContentId('reviews_inbox');
      setActiveStep(1);
    } else {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Stack sx={styles.topBar}>
        <TrustYouLogo variant="bubbles" color="blue" />
        <Button
          size="medium"
          variant="text"
          onClick={toggleDiscardModal}
          sx={{ color: 'text.secondary' }}
        >
          <StyledFontAwesomeIcon icon={faXmark} />
          <FormattedMessage id="action.close" defaultMessage="Close" />
        </Button>
      </Stack>
      <Stack sx={{ height: 'calc(100% - 69px)' }}>
        <SetupStepper steps={newReportWizardSteps} activeStep={activeStep} />
        {activeStep === 0 && <SettingsStep />}
        {activeStep === 1 && (isInboxReviews() ? <InboxFilterStep /> : <FiltersStep />)}
        {activeStep === 2 && <RecipientsStep />}
      </Stack>
      <DiscardChangesModal
        onCancel={toggleDiscardModal}
        onDiscard={onDiscard}
        isOpen={!!showDiscardModal}
      />
    </>
  );
};
