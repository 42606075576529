import type { PropsWithChildren } from 'react';

import { Box, Typography } from '@trustyou/ui';
import DOMPurify from 'dompurify';

export type QuestionWrapperProps = PropsWithChildren<{
  question: string;
  description?: string;
}>;

export const QuestionWrapper = ({ question, description = '', children }: QuestionWrapperProps) => {
  const sanitizedQuestion = DOMPurify.sanitize(question);
  const sanitizedDescription = DOMPurify.sanitize(description);

  return (
    <Box>
      <Typography
        variant="subtitle2"
        gutterBottom
        sx={{ fontWeight: 500 }}
        dangerouslySetInnerHTML={{ __html: sanitizedQuestion }}
      />
      {description && (
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary' }}
          dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
        />
      )}
      {children}
    </Box>
  );
};
