import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { SEARCH_DEBOUNCE_TIME_MS, useIsValidAccessGroup, validationErrors } from '@trustyou/shared';
import {
  Box,
  CircularProgress,
  FormControl,
  InputAdornment,
  TextField,
  Typography,
} from '@trustyou/ui';
import { debounce } from 'lodash';

import styles from './styles';

const AccessGroupName = () => {
  const { control } = useFormContext();
  const intl = useIntl();
  const isValidAccessGroup = useIsValidAccessGroup();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCheck = useCallback(
    debounce(async (value: string) => {
      if (!value || control._defaultValues.name === value) return;
      const response = await isValidAccessGroup.mutateAsync(value);
      if (response) return;
      control.setError('name', {
        type: 'custom',
        message: intl.formatMessage(validationErrors.existingAccessGroup),
      });
    }, SEARCH_DEBOUNCE_TIME_MS),
    []
  );

  return (
    <Box sx={styles.nameContainer}>
      <Typography variant="body1">
        <FormattedMessage
          id="access-group.enter-access-group-name"
          defaultMessage="Enter a name for this access group"
        />
      </Typography>
      <FormControl>
        <Controller
          name="name"
          control={control}
          rules={{
            required: intl.formatMessage(validationErrors.requiredAccessGroup),
            onChange: async (event) => {
              if (event) debounceCheck(event.target.value);
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              label={intl.formatMessage({
                id: 'access-group.name-of-access-group',
                defaultMessage: 'Name of access group',
              })}
              id="name"
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      {isValidAccessGroup.isPending && <CircularProgress size={20} />}
                    </InputAdornment>
                  ),
                },
              }}
            />
          )}
        />
      </FormControl>
    </Box>
  );
};

export default AccessGroupName;
