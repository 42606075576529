import type { ReactNode } from 'react';

import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, ListItemButton, ListItemIcon, ListItemText, alpha } from '@trustyou/ui';

export type Step = {
  icon: IconProp;
  label: ReactNode;
  content: ReactNode;
};

export type EditorSideNavProps = {
  activeStep?: number;
  setActiveStep: (index: number) => void;
  steps: Step[];
};

export function EditorSideNav({ activeStep = 0, setActiveStep, steps }: EditorSideNavProps) {
  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        paddingBlock: 3,
        paddingInline: 1,
        backgroundColor: 'primary.main',
        color: 'common.white',
      }}
    >
      {steps.map((item, index) => (
        <ListItemButton
          key={index}
          onClick={() => setActiveStep(index)}
          disableRipple
          sx={{
            flexDirection: 'column',
            borderRadius: 1,
            ...(index === activeStep && {
              backgroundColor: 'common.white',
              color: 'primary.main',
            }),
            '&:hover': {
              ...(index === activeStep && {
                backgroundColor: (theme) => alpha(theme.palette.common.white, 0.96),
                color: 'primary.main',
              }),
            },
          }}
        >
          <ListItemIcon
            sx={{
              color: 'common.white',
              justifyContent: 'center',
              ...(index === activeStep && {
                color: 'primary.main',
              }),
            }}
          >
            <FontAwesomeIcon icon={item.icon} size="lg" />
          </ListItemIcon>
          <ListItemText primary={item.label} slotProps={{ primary: { variant: 'body2' } }} />
        </ListItemButton>
      ))}
    </List>
  );
}
