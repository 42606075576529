import { Controller, type FieldError, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { faInfoCircle } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { EMAIL_VALIDATION, validationErrors } from '@trustyou/shared';
import {
  Asterisk,
  Autocomplete,
  Chip,
  Stack,
  StyledFontAwesomeIcon,
  TextField,
  Tooltip,
  Typography,
} from '@trustyou/ui';

export interface MultiEmailFieldProps {
  name: string;
  label: string;
  hint?: string;
  description?: string;
  isRequired?: boolean;
}

export function MultiEmailField({
  name,
  label,
  hint,
  description,
  isRequired = false,
}: MultiEmailFieldProps) {
  const intl = useIntl();
  const { control } = useFormContext();

  const getHelperText = (error?: FieldError) => {
    if (error?.type === 'required') return intl.formatMessage(validationErrors.required);
    if (error) {
      return intl.formatMessage(validationErrors.invalidEmail);
    }
    return description ?? ' ';
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: isRequired,
        validate: {
          allValidEmails: (values: string[]) => {
            const isAllValid = values.every((v) => EMAIL_VALIDATION.test(v));
            return isAllValid;
          },
        },
      }}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <Stack spacing={1}>
          <Typography variant="body2">
            {label}
            {isRequired && <Asterisk />}
            {hint && (
              <Tooltip placement="top" arrow title={hint}>
                <StyledFontAwesomeIcon
                  icon={faInfoCircle}
                  sx={{ marginLeft: 1, color: 'text.secondary' }}
                />
              </Tooltip>
            )}
          </Typography>
          <Autocomplete
            {...field}
            autoSelect
            fullWidth
            freeSolo
            multiple
            options={[]}
            limitTags={1}
            value={field.value ?? []}
            onChange={(_, value) => {
              field.onChange(value);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                return <Chip key={key} label={option} color="info" {...tagProps} />;
              })
            }
            renderInput={(params) => (
              <TextField
                ref={ref}
                {...params}
                placeholder={intl.formatMessage({
                  id: 'inbox.response.email-details.email-placeholder',
                  defaultMessage: 'Add emails',
                })}
                error={!!error}
                helperText={getHelperText(error)}
              />
            )}
          />
        </Stack>
      )}
    />
  );
}
