import { useIntl } from 'react-intl';

import { styled } from '@mui/material';
import { useLanguageStore } from '@trustyou/shared';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@trustyou/ui';
import dayjs from 'dayjs';

import type { GuestInfo } from '../../../client';
import { DATE_FORMAT } from '../../custom-date-range-dialog/custom-date-range-picker';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(0.75, 2),
  verticalAlign: 'top',
}));

export type VisitDataTableProps = {
  rows: GuestInfo[];
};

export function VisitDataTable({ rows }: VisitDataTableProps) {
  const intl = useIntl();
  const { locale } = useLanguageStore();

  const getFormattedAnswer = (answer: GuestInfo['answer']) => {
    if (answer?.type === 'datetime' && answer?.value) {
      return dayjs(answer.value).format(DATE_FORMAT);
    }
    return answer?.value;
  };

  const getTranslatedLabel = (label: GuestInfo['label']) => {
    if (!label) return;
    const languages = Object.keys(label); // example: ['en', 'de', 'fr']
    const DEFAULT_LANGUAGE = 'en';

    // compare lang to locale of form "en-US"
    const userLang = languages.find((lang) => lang.startsWith(locale));

    if (userLang) return label[userLang];
    if (DEFAULT_LANGUAGE in label) return label[DEFAULT_LANGUAGE];
    // return the first language if no user or default language is found
    return label[languages[0]];
  };

  return (
    <TableContainer sx={{ marginBlock: 2, maxHeight: 500 }}>
      <Table>
        <TableBody>
          {rows.map(({ label, answer }) => (
            <TableRow key={`${label}:${answer?.value}`}>
              <StyledTableCell sx={{ width: 220 }} component="th" scope="row">
                {getTranslatedLabel(label)}
              </StyledTableCell>
              {answer?.value ? (
                <StyledTableCell>{getFormattedAnswer(answer)}</StyledTableCell>
              ) : (
                <StyledTableCell sx={{ color: 'text.disabled' }}>
                  {intl.formatMessage({
                    id: 'inbox.not-available',
                    defaultMessage: 'Not available',
                  })}
                </StyledTableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
