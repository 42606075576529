import { useEffect, useState } from 'react';

import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import { faMagnifyingGlass, faXmark } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { SEARCH_DEBOUNCE_TIME_MS } from '@trustyou/shared';
import { IconButtonWithTooltip, Spacer, StyledFontAwesomeIcon } from '@trustyou/ui';

type CustomToolbarWithSearchProps = {
  searchTerms: string;
  setSearchTerms: (searchTerms: string) => void;
};

export function CustomToolbarWithSearch({
  searchTerms,
  setSearchTerms,
}: CustomToolbarWithSearchProps) {
  const [debouncedSearch, setDebouncedSearch] = useState(searchTerms);

  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchTerms(debouncedSearch);
    }, SEARCH_DEBOUNCE_TIME_MS);

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  return (
    <GridToolbarContainer sx={{ alignItems: 'start', padding: 1 }}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <Spacer />
      <GridToolbarQuickFilter
        variant="outlined"
        slotProps={{
          input: {
            startAdornment: (
              <StyledFontAwesomeIcon
                icon={faMagnifyingGlass}
                size="lg"
                sx={{ marginRight: 0.5, color: 'text.secondary' }}
              />
            ),
            endAdornment: searchTerms && (
              <IconButtonWithTooltip
                icon={faXmark}
                tooltip="Clear"
                onClick={() => setDebouncedSearch('')}
                sx={{ height: 32, width: 32 }}
              />
            ),
          },
        }}
        sx={{ width: 220 }}
        value={debouncedSearch}
        onChange={(event) => setDebouncedSearch(event.target.value)}
      />
    </GridToolbarContainer>
  );
}
