import { useIntl } from 'react-intl';

import { blue, lime, pink, purple, teal } from '@mui/material/colors';
import type { GridColDef } from '@mui/x-data-grid-pro';
import {
  type IconDefinition,
  faEnvelope,
  faHandWave,
  faImage,
  faMemo,
  faParagraph,
  faThumbsUp,
} from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Avatar, Stack, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

import { ViewIconButton } from './view-icon-button';

import type { ContentEnum } from '../../../client';
import type { TextRow } from '../../../types/content';
import { messages } from '../../../utils/messages';

type RowAvatar = {
  color: string;
  icon: IconDefinition;
};

const ROW_AVATARS: Record<ContentEnum, RowAvatar> = {
  welcome_message: {
    color: blue[300],
    icon: faHandWave,
  },
  thankyou_message: {
    color: blue[300],
    icon: faThumbsUp,
  },
  text: {
    color: blue[300],
    icon: faMemo,
  },
  // already_taken: {
  //   color: blue[300],
  //   icon: faUserCheck,
  // },
  // closed_status: {
  //   color: blue[300],
  //   icon: faSubtitlesSlash,
  // },
  section: {
    color: lime[600],
    icon: faParagraph,
  },
  email_invitation: {
    color: teal[300],
    icon: faEnvelope,
  },
  // not used in the text-based content data-grid, but needed to satisfy typescript
  image: {
    color: pink[500],
    icon: faImage,
  },
  // not used in the text-based content data-grid, but needed to satisfy typescript
  question: {
    color: purple[500],
    icon: faParagraph,
  },
};

function SquareAvatarWithIcon({ type }: { type: ContentEnum }) {
  return (
    <Avatar
      variant="rounded"
      sx={{
        height: (theme) => theme.spacing(4),
        width: (theme) => theme.spacing(4),
        backgroundColor: ROW_AVATARS[type].color,
        color: 'common.white',
      }}
    >
      <StyledFontAwesomeIcon icon={ROW_AVATARS[type].icon} />
    </Avatar>
  );
}

export function useColumns() {
  const intl = useIntl();

  const columns: GridColDef<TextRow>[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
    },
    {
      field: 'displayed_internal_name',
      headerName: intl.formatMessage(messages.internalName),
      flex: 4,
      renderCell: ({ row }) => (
        <Stack flexDirection="row" sx={{ gap: 1, alignItems: 'center' }}>
          <SquareAvatarWithIcon type={row.type ?? 'text'} />
          <Typography variant="body2" fontWeight={500}>
            {row.displayed_internal_name}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'displayed_message_type',
      headerName: intl.formatMessage(messages.messageType),
      flex: 3,
    },
    {
      field: 'translations',
      headerName: intl.formatMessage(messages.translations),
      flex: 4,
    },
    {
      field: 'view',
      headerName: intl.formatMessage(messages.view),
      flex: 1,
      renderCell: ({ row }) => <ViewIconButton row={row} />,
    },
  ];

  return columns;
}
