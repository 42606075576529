import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { yupResolver } from '@hookform/resolvers/yup';
import type { TabsProps } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useConfirm, useEntity, validationErrors } from '@trustyou/shared';
import { ConfirmDialog, snackbar } from '@trustyou/ui';
import * as yup from 'yup';

import { ResponseTabPanel, ResponseTabs } from './response-tabs';
import { ResponseAIFormContent } from './tabs-content/response-ai-form-content';
import { ResponseBlankFormContent } from './tabs-content/response-blank-form-content';
import { ResponseTemplateFormContent } from './tabs-content/response-template-form-content';

import {
  resetAbortController,
  updateReviewStatusById,
  useReview,
  useSaveResponse,
} from '../../../../hooks';
import { useResponseAIPermission } from '../../../../hooks/permissions/use-response-ai-permission';
import { useStore } from '../../../../store/store';
import type {
  ErrorWithResponse,
  ResponseFormSchema,
  ResponseTab,
  ResponseTextFieldId,
  ReviewRoot,
} from '../../../../types';
import { isTrustYouFeedback } from '../../../../utils/review';

export function ResponseForm() {
  const intl = useIntl();
  const { reviewId = '' } = useParams();
  const { accessTokenPayload } = useOidcAccessToken();

  const isResponseFormDirty = useStore.use.isResponseFormDirty();
  const updateIsResponseFormDirty = useStore.use.updateIsResponseFormDirty();
  const updateHasExistingResponse = useStore.use.updateHasExistingResponse();
  const updateHasGeneratedResponse = useStore.use.updateHasGeneratedResponse();
  const updateAcceptsDirectResponse = useStore.use.updateAcceptsDirectResponse();

  const [selectedResponse, setSelectedResponse] = useState<ResponseTextFieldId>();

  const { data: reviewRoot } = useReview({ reviewId });
  const { review, survey, response, meta } = (reviewRoot ?? {}) as ReviewRoot;
  const { score, title, text } = review ?? {};

  const { data: entity } = useEntity(meta?.entity_id ?? '');

  const queryClient = useQueryClient();
  const saveResponse = useSaveResponse();

  const hasResponseAIPermission = useResponseAIPermission();
  const hasScoreTitleOrText = Boolean(score !== undefined || title?.length || text?.length);
  const isResponseAITabVisible = hasResponseAIPermission && hasScoreTitleOrText;
  const isTranslatedTextSelected = selectedResponse === 'translatedResponse';

  const invalidError = intl.formatMessage(validationErrors.invalidEmail);
  const requiredError = intl.formatMessage(validationErrors.required);
  const subjectText = intl.formatMessage(
    {
      id: 'inbox.response.email-details.default-subject',
      defaultMessage: 'Reply from {entityName}',
    },
    { entityName: entity?.name }
  );

  const hasExistingResponse = useStore.use.hasExistingResponse();
  const isSurvey = isTrustYouFeedback(reviewRoot);
  const isPrivateSurvey = survey?.privacy_level === 'private';

  const schema = yup.object().shape({
    response: hasExistingResponse ? yup.string() : yup.string().required(),
    alsoSendViaEmail: yup.boolean().default(false),
    emailDetails: yup.object().shape({
      subject: yup.string().required(requiredError).default(subjectText),
      from: yup
        .string()
        .email(invalidError)
        .required(requiredError)
        .default(accessTokenPayload.email),
      cc: yup.array().of(yup.string().email(invalidError)),
      bcc: yup.array().of(yup.string().email(invalidError)),
    }),
  });
  const methods = useForm<ResponseFormSchema>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      response: response?.text ?? '',
      translatedResponse: '',
      alsoSendViaEmail: isPrivateSurvey,
      emailDetails: {
        subject: subjectText,
        body: '',
        from: accessTokenPayload.email,
        attachReview: true,
      },
    },
  });
  const { formState, getValues, reset } = methods;

  const resetResponseValues = useCallback(() => {
    resetAbortController();

    // Update review booleans when review changes
    updateIsResponseFormDirty(false);
    updateHasExistingResponse(!!response);
    updateAcceptsDirectResponse(reviewRoot?.meta?.directly_respondable ?? false);
  }, [
    response,
    reviewRoot?.meta?.directly_respondable,
    updateAcceptsDirectResponse,
    updateIsResponseFormDirty,
    updateHasExistingResponse,
  ]);

  const onSubmit = () => {
    const { response, translatedResponse, alsoSendViaEmail, emailDetails } = getValues();

    const publicResponseText = isTranslatedTextSelected ? translatedResponse : response;
    const privateResponseText = emailDetails?.sendDifferentResponse
      ? emailDetails.differentResponse
      : isTranslatedTextSelected
        ? translatedResponse
        : response;
    const shouldIncludePrivateResponse = isPrivateSurvey || alsoSendViaEmail;
    const surveyResponseText = isPrivateSurvey ? undefined : publicResponseText;

    saveResponse.mutate(
      {
        reviewId: review.id ?? '',
        payload: {
          // TODO: SIN-390 remove this line to stop sending the author to the backend
          author: accessTokenPayload.name,
          text: isSurvey ? surveyResponseText : publicResponseText,
          private_response: shouldIncludePrivateResponse
            ? {
                text: privateResponseText ?? '',
                subject: emailDetails?.subject ?? '',
                sender: emailDetails?.from ?? '',
                cc: emailDetails?.cc,
                bcc: emailDetails?.bcc,
                attach_feedback: emailDetails?.attachReview ?? false,
              }
            : null,
        },
      },
      {
        onSuccess: () => {
          updateReviewStatusById(queryClient, review.id ?? '', 'responded');
          resetResponseValues();
          updateHasExistingResponse(true);
        },
        onError: (err) => {
          const { response } = err as unknown as ErrorWithResponse;
          if (response.data.detail) {
            snackbar.error(response.data.detail);
          } else {
            snackbar.genericError();
          }
        },
      }
    );
  };

  const [activeTab, setActiveTab] = useState<ResponseTab>(
    isResponseAITabVisible ? 'response_ai' : 'template'
  );

  const { isDialogOpen, handleAction, handleConfirm, handleCancel } = useConfirm(
    formState.isDirty || isResponseFormDirty
  );

  const handleTabChange: TabsProps['onChange'] = (event, value) => {
    handleAction(() => {
      setActiveTab(value);
      reset();
      updateIsResponseFormDirty(false);
      updateHasGeneratedResponse(false);
    });
  };

  useEffect(() => {
    // Reset form when loaded review changes
    reset();
    // Enforce 'Generate response' button to be reset when navigating between reviews
    updateHasGeneratedResponse(false);
  }, [review.id, reset, updateHasGeneratedResponse]);

  useEffect(() => {
    // Trigger ConfirmDialog before leaving only when form is dirty
    updateIsResponseFormDirty(formState.isDirty);
  }, [formState.isDirty, updateIsResponseFormDirty]);

  if (!reviewRoot) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      <ResponseTabs
        activeTab={activeTab}
        onChange={handleTabChange}
        isResponseAITabVisible={isResponseAITabVisible}
      />
      <ResponseTabPanel value={activeTab} index="response_ai">
        <ResponseAIFormContent
          selectedResponse={selectedResponse ?? 'response'}
          setSelectedResponse={setSelectedResponse}
          onSubmit={onSubmit}
        />
      </ResponseTabPanel>
      <ResponseTabPanel value={activeTab} index="template">
        <ResponseTemplateFormContent onSubmit={onSubmit} />
      </ResponseTabPanel>
      <ResponseTabPanel value={activeTab} index="blank">
        <ResponseBlankFormContent onSubmit={onSubmit} />
      </ResponseTabPanel>
      <ConfirmDialog open={isDialogOpen} onConfirm={handleConfirm} onCancel={handleCancel} />
    </FormProvider>
  );
}
