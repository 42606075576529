import type { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import type { TabsProps } from '@mui/material';
import { useChangelingStore } from '@trustyou/shared';
import { Button, ComposableDrawerWithStickyFooter, Tab, Tabs } from '@trustyou/ui';

import { FILTERS_DRAWER_WIDTH } from '../../constants';
import { actionMessages } from '../../constants/messages';
import type { InboxDrawerTab } from '../../types';

export type FilterDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  currentTab: InboxDrawerTab;
  onChange: TabsProps['onChange'];
  ManageTabContent: ReactNode;
  FiltersTabContent: ReactNode;
};

export function InboxDrawer({
  isOpen,
  onClose,
  currentTab,
  onChange,
  ManageTabContent,
  FiltersTabContent,
}: FilterDrawerProps) {
  const intl = useIntl();
  const { isChangeling } = useChangelingStore();

  return (
    <ComposableDrawerWithStickyFooter
      anchor="right"
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: FILTERS_DRAWER_WIDTH,
          paddingTop: isChangeling ? 8 : 0,
        },
      }}
    >
      <ComposableDrawerWithStickyFooter.Header
        otherContent={
          <Tabs value={currentTab} onChange={onChange}>
            <Tab
              label={<FormattedMessage id="inbox.manage" defaultMessage="Manage" />}
              value="manage"
            />
            <Tab
              label={<FormattedMessage id="inbox.filters" defaultMessage="Filters" />}
              value="filters"
            />
          </Tabs>
        }
        sx={{ paddingBlock: 0 }}
      />
      <ComposableDrawerWithStickyFooter.Content>
        {currentTab === 'manage' && ManageTabContent}
        {currentTab === 'filters' && FiltersTabContent}
      </ComposableDrawerWithStickyFooter.Content>
      <ComposableDrawerWithStickyFooter.Footer
        primaryButton={
          currentTab === 'filters' && (
            <Button type="submit" form="inbox-filters-form" variant="contained" onClick={onClose}>
              {intl.formatMessage(actionMessages.apply)}
            </Button>
          )
        }
        secondaryButton={
          <Button type="button" color="inherit" onClick={onClose}>
            {currentTab === 'manage' && (
              <FormattedMessage id="inbox.action.close" defaultMessage="Close" />
            )}
            {currentTab === 'filters' && (
              <FormattedMessage id="inbox.action.cancel" defaultMessage="Cancel" />
            )}
          </Button>
        }
        sx={{ boxShadow: 5 }}
      />
    </ComposableDrawerWithStickyFooter>
  );
}
