import { Fragment } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { faPlus, faTrashCan } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Button, Grid, Stack, StyledFontAwesomeIcon, TextField, Typography } from '@trustyou/ui';

import { MAX_AI_GUIDES, MAX_AI_GUIDE_CHARACTERS } from '../../../../constants';
import type { ResponseAISettingsFormData } from '../../../../types';
import { deleteIdKeyFromAIGuide } from '../../../../utils/settings';
import { IconButtonWithTooltip } from '../../../icon-button-with-tooltip';

export const AIGuidesForm = ({ entityIndex }: { entityIndex: number }) => {
  const intl = useIntl();
  const { control } = useFormContext<ResponseAISettingsFormData>();
  const {
    fields: aiGuides,
    append,
    remove,
    update,
  } = useFieldArray({
    control,
    name: `guides.${entityIndex}.guides` as 'guides.0.guides',
  });

  return (
    <>
      {aiGuides?.map((aiGuide, index) => (
        <Fragment key={`${aiGuide}_${index}`}>
          <Grid size={6}>
            <Controller
              name={`guides.${entityIndex}.guides.${index}.problem`}
              control={control}
              render={({ field }) => (
                <TextField
                  data-testid="guestInput-textfield"
                  fullWidth
                  placeholder={intl.formatMessage({
                    id: 'inbox.settings.guestInput.placeholder',
                    defaultMessage: 'E.g. “parking is too expensive”',
                  })}
                  {...field}
                  onBlur={(event) => {
                    const problem = event.target.value;
                    const updatedAiGuide = deleteIdKeyFromAIGuide({ ...aiGuide, problem });
                    update(index, updatedAiGuide);
                  }}
                  slotProps={{ htmlInput: { maxLength: 150 } }}
                />
              )}
            />
          </Grid>
          <Grid size={6}>
            <Stack direction="row">
              <Controller
                name={`guides.${entityIndex}.guides.${index}.answer`}
                control={control}
                render={({ field }) => (
                  <TextField
                    data-testid="hotelierResponse-testfield"
                    {...field}
                    onBlur={(event) => {
                      const answer = event.target.value;
                      const updatedAiGuide = deleteIdKeyFromAIGuide({ ...aiGuide, answer });
                      update(index, updatedAiGuide);
                    }}
                    multiline
                    rows={3}
                    fullWidth
                    placeholder={intl.formatMessage({
                      id: 'inbox.settings.hotelierResponse.placeholder',
                      defaultMessage: 'E.g. “There is free parking on the nearby streets”',
                    })}
                    helperText={
                      <Typography variant="caption" color="text.secondary">
                        <FormattedMessage
                          id="inbox.max.characters.limit"
                          defaultMessage="{value}/{maxLimit} characters"
                          values={{
                            maxLimit: MAX_AI_GUIDE_CHARACTERS,
                            value: aiGuide.answer.length,
                          }}
                        />
                      </Typography>
                    }
                    slotProps={{
                      formHelperText: {
                        sx: {
                          textAlign: 'end',
                          mt: 1,
                          mr: 0,
                        },
                      },
                      htmlInput: {
                        maxLength: MAX_AI_GUIDE_CHARACTERS,
                      },
                    }}
                  />
                )}
              />
              <IconButtonWithTooltip
                aria-label="textbox delete button"
                data-gtm-class="inbox_response_textbox_action"
                data-gtm-id="delete"
                tooltip={intl.formatMessage({
                  id: 'inbox.response.info.delete',
                  defaultMessage: 'Delete text',
                })}
                icon={faTrashCan}
                onClick={() => remove(index)}
              />
            </Stack>
          </Grid>
        </Fragment>
      ))}
      <Stack sx={{ alignItems: 'center' }} width="100%" paddingTop={5}>
        {aiGuides?.length === MAX_AI_GUIDES ? (
          <>
            <Typography sx={{ fontWeight: 'bold' }}>
              <FormattedMessage
                id="inbox.settings.noEmptySpace.title"
                defaultMessage="10 / 10 Guides used"
              />
            </Typography>
            <Typography>
              <FormattedMessage
                id="inbox.settings.noEmptySpace.description"
                defaultMessage="Delete some to free up space"
              />
            </Typography>
          </>
        ) : (
          <Button
            disabled={aiGuides?.length >= MAX_AI_GUIDES || entityIndex < 0}
            onClick={() => append({ problem: '', answer: '' })}
            startIcon={<StyledFontAwesomeIcon icon={faPlus} />}
            variant="contained"
            color="secondary"
            data-gtm-id="inbox_settings_new_guide_button"
          >
            <FormattedMessage id="inbox.settings.actions.newGuide" defaultMessage="New guide" />
          </Button>
        )}
      </Stack>
    </>
  );
};
