import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import {
  faArrowLeft,
  faChevronLeft,
  faChevronRight,
  faEnvelope,
  faLanguage,
} from '@trustyou/fortawesome/pro-regular-svg-icons';
import { useConfirm, useLanguageStore, useResponsive, useRouteUtils } from '@trustyou/shared';
import { Box, Button, ConfirmDialog, Divider, Stack, StyledFontAwesomeIcon } from '@trustyou/ui';

import { ForwardReview } from './forward-review';
import { MarkReviewAsResponded } from './mark-review-as-responded';

import {
  updateReviewStatusById,
  useSetReviewReadStatus,
  useTranslatePermission,
} from '../../../hooks';
import useSetReviewsRespondedStatusPermission from '../../../hooks/permissions/use-set-reviews-responded-status-permission';
import useSetReviewsStatusPermission from '../../../hooks/permissions/use-set-reviews-status-permission';
import { useStore } from '../../../store/store';
import type { ReviewRoot } from '../../../types';
import {
  isDeletionConfirmed,
  isDeletionPendingOrRequested,
  useSurroundingReviewIds,
} from '../../../utils/review';
import { IconButtonWithTooltip } from '../../icon-button-with-tooltip';
import { ReviewDeletion } from '../../review-deletion';
import { SurveyModeration } from '../../survey-moderation/survey-moderation';

type ReviewDetailToolbarProps = {
  reviewData: ReviewRoot;
  shouldDisplayTranslation: boolean;
  setShouldDisplayTranslation: (value: boolean) => void;
};

export function ReviewDetailToolbar({
  reviewData,
  shouldDisplayTranslation,
  setShouldDisplayTranslation,
}: ReviewDetailToolbarProps) {
  const { reviewId = '' } = useParams();
  const queryClient = useQueryClient();
  const { isMobile } = useResponsive();
  const { locale } = useLanguageStore();
  const { prevReviewId, nextReviewId } = useSurroundingReviewIds(reviewId);
  const isResponseFormDirty = useStore.use.isResponseFormDirty();
  const setReviewStatus = useSetReviewReadStatus();
  const isSetReviewsStatusAllowed = useSetReviewsStatusPermission();
  const isSetReviewsRespondedStatusAllowed = useSetReviewsRespondedStatusPermission();
  const isTranslateAllowed = useTranslatePermission();
  const { isDialogOpen, handleAction, handleConfirm, handleCancel } =
    useConfirm(isResponseFormDirty);
  const { navigateWithBase } = useRouteUtils();

  const isOnlineReview = !reviewData.survey;
  const shouldShowCheckIconButton = isOnlineReview && !reviewData?.meta?.directly_respondable;
  const shouldShowTrashIconButton = isOnlineReview;
  const shouldShowFlagIconButton = !isOnlineReview;

  const isDeletionProcessStartedOrFinished =
    isDeletionPendingOrRequested(reviewData.deletion) || isDeletionConfirmed(reviewData.deletion);
  const isModerationProcessStarted = ['requested', 'propagating'].includes(
    reviewData.moderation?.status ?? ''
  );
  const isModerationProcessApproved = ['approved'].includes(reviewData.moderation?.status ?? '');

  const isMarkAsUnreadDisabled =
    !isSetReviewsStatusAllowed ||
    isDeletionProcessStartedOrFinished ||
    isModerationProcessStarted ||
    isModerationProcessApproved;

  const isMarkAsRespondedDisabled =
    !!reviewData.response ||
    !reviewData.review?.respondable ||
    !isSetReviewsRespondedStatusAllowed ||
    isDeletionProcessStartedOrFinished ||
    isModerationProcessStarted ||
    isModerationProcessApproved;

  const goBackToInbox = () => {
    handleAction(() => navigateWithBase('inbox'));
  };

  const markAsUnread = () => {
    setReviewStatus.mutate(
      {
        status: 'unread',
        reviews: [reviewData.id],
      },
      {
        onSuccess: () => {
          updateReviewStatusById(queryClient, reviewData.id, 'unread');
          goBackToInbox();
        },
      }
    );
  };

  const handleTranslate = () => {
    setShouldDisplayTranslation(!shouldDisplayTranslation);
  };

  const getTranslateTooltip = () => {
    if (!isTranslateAllowed) {
      return (
        <FormattedMessage
          id="inbox.user.roles.read.only.inbox.alert"
          defaultMessage="Your role is read only"
        />
      );
    } else if (shouldDisplayTranslation) {
      return (
        <FormattedMessage id="inbox.review.action.show-original" defaultMessage="Show original" />
      );
    }
    return <FormattedMessage id="inbox.review.action.translate" defaultMessage="Translate" />;
  };

  const navigateToPreviousReview = () => {
    handleAction(() => navigateWithBase(`inbox/${prevReviewId}`));
  };

  const navigateToNextReview = () => {
    handleAction(() => navigateWithBase(`inbox/${nextReviewId}`));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: isMobile ? 'start' : 'center',
        justifyContent: 'space-between',
        paddingInline: 1,
        paddingBlock: 0.5,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        ...(isMobile && {
          paddingBlock: 0,
        }),
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{
          ...(isMobile && {
            order: 3,
            alignItems: 'center',
          }),
        }}
      >
        <IconButtonWithTooltip
          onClick={goBackToInbox}
          icon={faArrowLeft}
          tooltip={<FormattedMessage id="inbox.back-to-inbox" defaultMessage="Back to Inbox" />}
          color="primary"
        />
        <Box component="span" sx={{ width: (theme) => theme.spacing(1) }} />
        {['read', 'unread'].includes(reviewData.meta.status ?? '') && (
          <IconButtonWithTooltip
            data-gtm-class="review_action_on_review"
            data-gtm-id="mark_as_unread"
            onClick={markAsUnread}
            icon={faEnvelope}
            tooltip={
              isSetReviewsStatusAllowed ? (
                <FormattedMessage id="inbox.mark-as-unread" defaultMessage="Mark as unread" />
              ) : (
                <FormattedMessage
                  id="inbox.user.roles.read.only.inbox.alert"
                  defaultMessage="Your role is read only"
                />
              )
            }
            disabled={isMarkAsUnreadDisabled}
            color="primary"
          />
        )}
        {shouldShowCheckIconButton && (
          <MarkReviewAsResponded isDisabled={isMarkAsRespondedDisabled} />
        )}
        <IconButtonWithTooltip
          disabled={!isTranslateAllowed}
          data-gtm-class="review_action_on_review"
          data-gtm-id="translate"
          data-gtm-param-1={locale}
          onClick={handleTranslate}
          icon={faLanguage}
          tooltip={getTranslateTooltip()}
          color="primary"
        />
        <ForwardReview />
        {shouldShowTrashIconButton && <ReviewDeletion />}
        {shouldShowFlagIconButton && <SurveyModeration />}
      </Stack>
      {isMobile && <Divider flexItem sx={{ order: 2, marginInline: -1 }} />}
      <Stack
        direction="row"
        gap={3}
        sx={{
          ...(isMobile && {
            order: 1,
            width: '100%',
            justifyContent: 'space-between',
          }),
        }}
      >
        <Button
          startIcon={<StyledFontAwesomeIcon icon={faChevronLeft} style={{ fontSize: 'inherit' }} />}
          disabled={!prevReviewId}
          onClick={navigateToPreviousReview}
          data-gtm-class="inbox_navigation_button"
          data-gtm-id="previous"
        >
          <FormattedMessage id="inbox.review.navigation.previous" defaultMessage="Previous" />
        </Button>
        <Button
          endIcon={<StyledFontAwesomeIcon icon={faChevronRight} style={{ fontSize: 'inherit' }} />}
          disabled={!nextReviewId}
          onClick={navigateToNextReview}
          data-gtm-class="inbox_navigation_button"
          data-gtm-id="next"
        >
          <FormattedMessage id="inbox.review.navigation.next" defaultMessage="Next" />
        </Button>
      </Stack>
      <ConfirmDialog open={isDialogOpen} onConfirm={handleConfirm} onCancel={handleCancel} />
    </Box>
  );
}
