import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  container: { backgroundColor: 'background.paper' },
  body: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  changelingBody: {
    marginTop: 8,
    height: 'calc(100vh - 64px)',
  },
};

export default styles;
