import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { snackbar } from '@trustyou/ui';

import {
  newAbortController,
  resetAbortController,
  useGenerateResponseAI,
  useLanguage,
} from '../../../../hooks';
import { useStore } from '../../../../store/store';
import type { Language, ResponseFormSchema } from '../../../../types';

export function useResponseAI({
  setFirstFieldLanguage,
  setGuidesUsed,
}: {
  setFirstFieldLanguage: (language: Language) => void;
  setGuidesUsed: (guides: string[]) => void;
}) {
  const intl = useIntl();
  const { getLanguageSourceByCode } = useLanguage();
  const { reviewId } = useParams();
  const { setValue, resetField } = useFormContext<ResponseFormSchema>();

  const generateResponseAI = useGenerateResponseAI();

  const generationLanguage = useStore.use.generationLanguage();
  const hasGeneratedResponse = useStore.use.hasGeneratedResponse();
  const updateHasGeneratedResponse = useStore.use.updateHasGeneratedResponse();

  const [isReplaceDialogOpen, setIsReplaceDialogOpen] = useState(false);

  const handleGenerateResponseAI = ({
    replace,
    skipDialogNextTime,
  }: { replace?: boolean; skipDialogNextTime?: boolean } = {}) => {
    if (replace) {
      if (isReplaceDialogOpen) {
        setIsReplaceDialogOpen(!isReplaceDialogOpen);
      }
      if (skipDialogNextTime) {
        localStorage.setItem('skipDialogBeforeGenerateAnother', 'true');
      }
    } else {
      const skipDialog = Boolean(localStorage.getItem('skipDialogBeforeGenerateAnother'));
      if (hasGeneratedResponse && !skipDialog) {
        setIsReplaceDialogOpen(!isReplaceDialogOpen);
        return;
      }
    }

    newAbortController();

    generateResponseAI.mutate(
      {
        review_id: reviewId ?? '',
        language: generationLanguage.value,
      },
      {
        onSuccess: async (data) => {
          setValue('response', data.response_text, {
            shouldDirty: !!data.response_text,
          });
          resetField('translatedResponse');
          setGuidesUsed(data.templates_used);
          setFirstFieldLanguage(data.response_language);
          resetAbortController();
          updateHasGeneratedResponse(true);
          snackbar.success(
            intl.formatMessage({
              id: 'inbox.response.generate.success',
              defaultMessage: 'Response generated',
            })
          );
        },
        onError: () => {
          snackbar.error(
            intl.formatMessage({
              id: 'inbox.response.generate.feedbackTitleErrorGeneral',
              defaultMessage: 'Couldn’t generate a response, please try again',
            })
          );
          resetAbortController();
        },
      }
    );
  };

  return {
    generateResponseAI,
    handleGenerateResponseAI,
    isReplaceDialogOpen,
    setIsReplaceDialogOpen,
  };
}
