import { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import type { GridCellParams, GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { faGaugeMax, faPencil, faTrash } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  FETCH_BENCHMARKS,
  SEARCH_DEBOUNCE_TIME_MS,
  actionMessages,
  getPaginationRequest,
  getSortRequest,
  replaceRouteState,
  searchPlaceholders,
  useBenchmarks,
  useBenchmarksGridStore,
  useGridParams,
  useManageBenchmarksPermission,
} from '@trustyou/shared';
import {
  ActionCell,
  Box,
  ContentPane,
  DataGrid,
  DataGridCell,
  EmptyBenchmarks,
  SearchBar,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@trustyou/ui';

import { AddBenchmarkButton } from './AddBenchmarkButton';
import { BenchmarkDetailView } from './BenchmarkDetailView';
import { BenchmarkManageDrawer } from './BenchmarkManageDrawer/BenchmarkManageDrawer';
import { DeleteConfirmation } from './DeleteConfirmation';
import { EntitiesCompetitorsCountCell } from './EntitiesCompetitorsCountCell';
import { IconButtonWithTooltip } from './IconButtonWithTooltip';

import { common as benchmarkMessages } from '../../constants/messages/benchmarks';
import { useBenchmarkStore } from '../../store';
import { styles } from './styles';

const tableHeaders = defineMessages({
  benchmark: {
    id: 'benchmark.table.benchmark',
    defaultMessage: 'Benchmark',
  },
  benchmarkSet: {
    id: 'benchmark.table.benchmark-set',
    defaultMessage: 'Benchmark set',
  },
  ownEntities: {
    id: 'benchmark.table.own-entities',
    defaultMessage: 'Number of own entities',
  },
  competitorEntities: {
    id: 'benchmark.table.competitor-entities',
    defaultMessage: 'Number of competitor entities',
  },
});

export const BenchmarksTable = () => {
  const { state } = useLocation();
  const intl = useIntl();
  const manageBenchmarksPermission = useManageBenchmarksPermission();

  const {
    setSelectedBenchmark,
    setEditableBenchmark,
    showCreateBenchmarkDrawer,
    setDeletableBenchmark,
  } = useBenchmarkStore();

  useEffect(() => {
    if (state?.createBenchmark) {
      showCreateBenchmarkDrawer();
      replaceRouteState({ createBenchmark: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const gridState = useBenchmarksGridStore();

  const navigate = useNavigate();

  const { paginationModel, sortModel, searchKey } = gridState;

  const { onPaginationModelChange, onSortModelChange, onSearch } = useGridParams(
    FETCH_BENCHMARKS,
    gridState
  );

  const {
    data,
    isPending: isLoading,
    isError,
  } = useBenchmarks(getPaginationRequest(paginationModel), getSortRequest(sortModel), searchKey);

  const navigateToDashboard = (link: string) => () => navigate(link);

  const handleRowClick = (params: GridRowParams) => {
    setSelectedBenchmark(params.row);
  };

  const actionColumn = {
    field: 'action',
    headerName: '',
    sortable: false,
    renderCell: ({ row }: GridCellParams) => (
      <ActionCell
        testId="benchmark-action-menu"
        actions={[
          {
            testId: 'benchmark-action-edit',
            icon: faPencil,
            handler: () => {
              setEditableBenchmark(row);
            },
            message: intl.formatMessage(actionMessages.edit),
          },
          {
            testId: 'benchmark-action-remove',
            icon: faTrash,
            handler: () => {
              setDeletableBenchmark(row);
            },
            message: intl.formatMessage(actionMessages.delete),
          },
        ]}
      />
    ),
  };

  const columns: GridColDef[] = [
    {
      field: 'set_name',
      headerName: intl.formatMessage(tableHeaders.benchmarkSet),
      minWidth: 200,
      flex: 1,
      renderCell: ({ row }: GridCellParams) => <DataGridCell primaryValue={row.set_name} />,
    },
    {
      field: 'name',
      headerName: intl.formatMessage(tableHeaders.benchmark),
      minWidth: 200,
      flex: 1,
      renderCell: ({ row }: GridCellParams) => <DataGridCell primaryValue={row.name} />,
    },
    {
      field: 'num_owned_entities',
      headerName: intl.formatMessage(tableHeaders.ownEntities),
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: ({ row }: GridCellParams) => (
        <EntitiesCompetitorsCountCell
          count={row.num_owned_entities}
          message={intl.formatMessage(benchmarkMessages.entitiesCountError)}
        />
      ),
    },
    {
      field: 'num_competitor_entities',
      headerName: intl.formatMessage(tableHeaders.competitorEntities),
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: ({ row }: GridCellParams) => (
        <EntitiesCompetitorsCountCell
          count={row.num_competitor_entities}
          message={intl.formatMessage(benchmarkMessages.competitorsCountError)}
        />
      ),
    },
    {
      field: 'dashboard_link',
      headerName: '',
      minWidth: 150,
      sortable: false,
      renderCell: ({ row }: GridCellParams) => (
        <IconButtonWithTooltip
          icon={faGaugeMax}
          hide={!row.dashboard_link}
          tooltip={intl.formatMessage(benchmarkMessages.viewDashboard)}
          onClick={navigateToDashboard(row.dashboard_link)}
          sx={{ height: 32, width: 32 }}
        />
      ),
    },
  ];

  if (manageBenchmarksPermission) {
    columns.push(actionColumn);
  }

  if (!isLoading && !searchKey && data?.pagination?.total === 0)
    return (
      <ContentPane sx={styles.paneWrapper}>
        <EmptyBenchmarks
          action={
            manageBenchmarksPermission
              ? {
                  testId: 'create-benchmark-empty-state-button',
                  message: intl.formatMessage(benchmarkMessages.addBenchmark),
                  handler: showCreateBenchmarkDrawer,
                }
              : undefined
          }
        />
        <BenchmarkManageDrawer />
      </ContentPane>
    );

  const rows = (data?.data || []).map((row, rowId) => ({ ...row, rowId }));

  return (
    <ContentPane sx={styles.contentPane}>
      <Stack sx={styles.tabsBar}>
        <Tabs value={0}>
          <Tab
            label={
              manageBenchmarksPermission === 'organization'
                ? intl.formatMessage(benchmarkMessages.organizationBenchmarks)
                : intl.formatMessage(benchmarkMessages.userBenchmarks)
            }
          />
        </Tabs>
        <AddBenchmarkButton />
      </Stack>
      <Typography variant="body2" marginBottom={1}>
        {manageBenchmarksPermission === 'organization'
          ? intl.formatMessage(benchmarkMessages.organizationBenchmarksInfo)
          : intl.formatMessage(benchmarkMessages.userBenchmarksInfo)}
      </Typography>
      <Box sx={styles.searchBar}>
        <SearchBar
          onSearch={onSearch}
          debounceTime={SEARCH_DEBOUNCE_TIME_MS}
          placeholder={intl.formatMessage(searchPlaceholders.searchBenchmarks)}
          id="benchmarks"
          variant="standard"
          defaultValue={searchKey}
        />
      </Box>
      <DataGrid
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        rowHeight={64}
        columns={columns.filter((col) => col.field !== 'dashboard_link')}
        rows={rows}
        getRowId={(row) => row.rowId}
        rowCount={isError ? 0 : data?.pagination.total}
        onRowClick={handleRowClick}
        loading={isLoading}
        autoHeight={false}
        containerStyle={styles.tableContainer}
      />
      <BenchmarkDetailView />
      <BenchmarkManageDrawer />
      <DeleteConfirmation />
    </ContentPane>
  );
};
