import { useLanguageStore } from '@trustyou/shared';
import { Stack, Typography } from '@trustyou/ui';

import { useLanguage } from '../../hooks';

export type SelectAnswerProps = {
  answers: Record<string, string>[];
};

export const SelectAnswer = ({ answers = [] }: SelectAnswerProps) => {
  const { locale } = useLanguageStore();
  const { getFormattedLanguageCode } = useLanguage();

  return (
    answers.length > 0 && (
      <Stack spacing={0.5}>
        {answers?.map((answer, index) => (
          <Typography key={index} variant="body2">
            {answer?.[getFormattedLanguageCode(locale)] ?? answer?.en}
          </Typography>
        ))}
      </Stack>
    )
  );
};
