import { useIntl } from 'react-intl';

import { faXmark } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  StyledFontAwesomeIcon,
} from '@trustyou/ui';

import { VisitDataTable } from './visit-data-table';

import type { GuestInfo } from '../../../client';

export type VisitDataDialogProps = {
  guestInfo?: GuestInfo[] | null;
  isOpen: boolean;
  onClose: () => void;
};

export function VisitDataDialog({ guestInfo, isOpen, onClose }: VisitDataDialogProps) {
  const intl = useIntl();

  return (
    <Dialog fullWidth open={isOpen} onClose={onClose}>
      <DialogTitle>
        {intl.formatMessage({ id: 'inbox.visit-data', defaultMessage: 'Visit data' })}
        <IconButton
          aria-label="close visit data"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: (theme) => theme.spacing(2),
          }}
        >
          <StyledFontAwesomeIcon icon={faXmark} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {guestInfo?.length ? (
          <VisitDataTable rows={guestInfo} />
        ) : (
          <Stack sx={{ alignItems: 'center', paddingBlockStart: 2 }}>
            <Box sx={{ width: 225, textAlign: 'center' }}>
              {intl.formatMessage({
                id: 'inbox.visit-data.no-data',
                defaultMessage: 'No visit data for this review',
              })}
            </Box>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          {intl.formatMessage({
            id: 'inbox.action.close',
            defaultMessage: 'Close',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
