import type { PropsWithChildren } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import type { GridColDef } from '@mui/x-data-grid-pro';
import {
  faCheckDouble,
  faClock,
  faEnvelope,
  faEnvelopeOpen,
  faFlag,
  faTrash,
  faTrashCheck,
} from '@trustyou/fortawesome/pro-regular-svg-icons';
import { faFlag as faFlagSolid } from '@trustyou/fortawesome/pro-solid-svg-icons';
import { useEntityCount, useResponsive } from '@trustyou/shared';
import { Box, ClampedTypography, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

import { CustomTooltip } from './custom-tooltip';
import { CustomTooltipLink } from './custom-tooltip-link';

import { useTranslatedText } from '../../hooks/use-translated-text';
import { IconWithTooltip } from '../icon-with-tooltip';
import { ScoreChip } from '../score-chip';
import { SearchTermsHighlighter } from './search-terms-highlighter';

function ThreeLineText({ children, isMobile }: PropsWithChildren<{ isMobile?: boolean }>) {
  return (
    <ClampedTypography variant="body2" maxLines={isMobile ? 3 : 1} sx={{ fontWeight: 'inherit' }}>
      {children}
    </ClampedTypography>
  );
}

function getStatusIcon(status: string) {
  const icons: Record<string, IconProp> = {
    read: faEnvelopeOpen,
    unread: faEnvelope,
    responded: faClock,
    confirmed: faCheckDouble,
    marked_as_deleted: faTrash,
    deleted: faTrashCheck,
    requested: faFlag,
    marked_as_inappropriate: faFlag,
    inappropriate: faFlagSolid,
  };

  return icons[status];
}

function LeadingIcon({ status }: { status: string }) {
  return ['requested', 'marked_as_inappropriate', 'inappropriate'].includes(status) ? (
    <StyledFontAwesomeIcon
      icon={getStatusIcon(status)}
      sx={{ color: 'error.main', marginInlineEnd: 1.5 }}
    />
  ) : null;
}

export function useInboxColumns() {
  const intl = useIntl();
  const { isMobile } = useResponsive();
  const { getTranslatedText } = useTranslatedText();
  const { data: totalEntityCount = 0 } = useEntityCount();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
    },
    {
      field: 'score',
      headerName: intl.formatMessage({ id: 'inbox.header.score', defaultMessage: 'Score' }),
      cellClassName: 'score-cell',
      headerClassName: 'score-header',
      align: 'center',
      headerAlign: 'center',
      disableColumnMenu: isMobile,
      resizable: !isMobile,
      renderCell: ({ value }) => <ScoreChip dataTestId="score" value={value} />,
    },
    {
      field: 'author',
      headerName: intl.formatMessage({ id: 'inbox.header.author', defaultMessage: 'Author' }),
      sortable: false,
      flex: 2,
      disableColumnMenu: isMobile,
      resizable: !isMobile,
      renderCell: ({ value }) => (
        <ThreeLineText isMobile={isMobile}>
          <SearchTermsHighlighter value={value} />
        </ThreeLineText>
      ),
    },
    {
      field: 'text',
      headerName: intl.formatMessage({ id: 'inbox.header.text', defaultMessage: 'Text' }),
      sortable: false,
      flex: 8,
      disableColumnMenu: isMobile,
      resizable: !isMobile,
      renderCell: ({ row, value }) => (
        <Box
          sx={{
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            overflow: 'hidden',
          }}
        >
          {value ? (
            <ThreeLineText isMobile={isMobile}>
              <LeadingIcon status={row.status} />
              <SearchTermsHighlighter value={value} />
            </ThreeLineText>
          ) : (
            <>
              <LeadingIcon status={row.status} />
              <Typography variant="caption" color="text.secondary" sx={{ fontWeight: 'inherit' }}>
                <FormattedMessage
                  defaultMessage="Review without text"
                  id="inbox.review-without-text"
                />
              </Typography>
            </>
          )}
        </Box>
      ),
    },
    {
      field: 'source',
      headerName: intl.formatMessage({ id: 'inbox.header.source', defaultMessage: 'Source' }),
      sortable: false,
      flex: 2,
      disableColumnMenu: isMobile,
      resizable: !isMobile,
      renderCell: ({ value }) => <CustomTooltipLink href={value.href} label={value.label} />,
    },
    {
      field: 'status',
      headerName: intl.formatMessage({ id: 'inbox.header.status', defaultMessage: 'Status' }),
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      disableColumnMenu: isMobile,
      resizable: !isMobile,
      renderCell: ({ value }) => (
        <IconWithTooltip
          icon={getStatusIcon(value)}
          tooltip={getTranslatedText(value)}
          IconProps={{ sx: { color: (theme) => theme.palette.action.active } }}
        />
      ),
    },
    {
      field: 'date',
      headerName: intl.formatMessage({ id: 'inbox.header.date', defaultMessage: 'Date' }),
      disableColumnMenu: isMobile,
      resizable: !isMobile,
      renderCell: ({ value }) => <SearchTermsHighlighter value={value} />,
    },
  ];

  if (totalEntityCount > 1) {
    columns.push({
      field: 'entity',
      headerName: intl.formatMessage({ id: 'inbox.header.entity', defaultMessage: 'Entity' }),
      sortable: false,
      flex: 3,
      disableColumnMenu: isMobile,
      resizable: !isMobile,
      renderCell: ({ value }) => (
        <CustomTooltip sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} title={value}>
          <SearchTermsHighlighter value={value} />
        </CustomTooltip>
      ),
    });
  }

  return columns;
}
