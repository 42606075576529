import { ADD_COMPETITORS_PERMISSION, usePermissionStore } from '@trustyou/shared';

const useManageCompetitors = () => {
  const { hasPermissions } = usePermissionStore();

  const isSetCompetitorsAllowed = hasPermissions(ADD_COMPETITORS_PERMISSION);
  return isSetCompetitorsAllowed;
};

export default useManageCompetitors;
