import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { useQueryClient } from '@tanstack/react-query';
import { faCheck } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { useRouteUtils } from '@trustyou/shared';
import { snackbar } from '@trustyou/ui';

import { updateReviewStatusById, useSaveResponse } from '../../../hooks';
import useSetReviewsRespondedStatusPermission from '../../../hooks/permissions/use-set-reviews-responded-status-permission';
import { useStore } from '../../../store/store';
import { IconButtonWithTooltip } from '../../icon-button-with-tooltip';

export function MarkReviewAsResponded({ isDisabled }: { isDisabled?: boolean }) {
  const intl = useIntl();
  const isSetReviewsRespondedStatusAllowed = useSetReviewsRespondedStatusPermission();
  const saveResponse = useSaveResponse();
  const { reviewId = '' } = useParams();
  const { accessTokenPayload } = useOidcAccessToken();
  const queryClient = useQueryClient();
  const updateHasExistingResponse = useStore.use.updateHasExistingResponse();
  const { navigateWithBase } = useRouteUtils();

  const tooltipMessage = isSetReviewsRespondedStatusAllowed
    ? intl.formatMessage({
        id: 'inbox.action.mark-as-responded',
        defaultMessage: 'Mark as responded',
      })
    : intl.formatMessage({
        id: 'inbox.user.roles.read.only.inbox.alert',
        defaultMessage: 'Your role is read only',
      });

  const handleMarkAsResponded = () => {
    // TODO: Call `/set-status-responded-unresponded` endpoint when the backend supports sending text in the payload
    saveResponse.mutate(
      {
        reviewId,
        payload: {
          // TODO: SIN-390 Stop sending the author to the backend
          author: accessTokenPayload.name,
          text: '[marked as responded with an empty text]',
        },
      },
      {
        onSuccess: () => {
          snackbar.success(
            intl.formatMessage({
              id: 'inbox.review-marked-responded',
              defaultMessage: 'Review marked responded',
            })
          );
          updateHasExistingResponse(true);
          updateReviewStatusById(queryClient, reviewId, 'responded');
          navigateWithBase('inbox');
        },
      }
    );
  };

  return (
    <IconButtonWithTooltip
      data-gtm-class="review_action_on_review"
      data-gtm-id="mark_as_responded"
      disabled={isDisabled}
      onClick={handleMarkAsResponded}
      icon={faCheck}
      tooltip={tooltipMessage}
      color="primary"
    />
  );
}
