import { Stack } from '@trustyou/ui';

import { DropdownLanguage } from '../dropdowns/dropdown-language';
import { DropdownScore } from '../dropdowns/dropdown-score';
import { DropdownSource } from '../dropdowns/dropdown-source';
import { DropdownStatus } from '../dropdowns/dropdown-status';
import { DropdownTimeRange } from '../dropdowns/dropdown-time-range';

export function BasicFilters() {
  return (
    <Stack spacing={3} sx={{ paddingBlock: 1 }}>
      <DropdownTimeRange fieldName="timerange" />
      <DropdownStatus fieldName="statuses" />
      <DropdownScore fieldName="scores" />
      <DropdownSource fieldName="sources" />
      <DropdownLanguage fieldName="languages" />
    </Stack>
  );
}
