import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import type { SelectChangeEvent } from '@mui/material';
import { faSliders } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { useResponsive } from '@trustyou/shared';
import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  StyledFontAwesomeIcon,
} from '@trustyou/ui';

import { ViewItem } from './view-item';

import { useFilters } from '../../hooks/use-filters';
import { usePredefinedViews } from '../../hooks/use-predefined-views';

export type ViewsDropdownProps = {
  label?: string;
  width?: number;
  onManageViews?: () => void;
};

export function ViewsDropdown({ label, width = 260, onManageViews }: ViewsDropdownProps) {
  const intl = useIntl();
  const { isPhone } = useResponsive();
  const { allReviewsView, lastSevenDaysView, exampleView } = usePredefinedViews();
  const { views, currentView, setCurrentView, applyDefaultFilters, clearFilters } = useFilters();
  const [isOpen, setOpen] = useState(false);

  const filteredResultsLabel = intl.formatMessage({
    id: 'inbox.views.dropdown.filtered-results',
    defaultMessage: 'Filtered results',
  });

  const possibleValues = [
    filteredResultsLabel,
    allReviewsView.label,
    lastSevenDaysView.label,
    exampleView.label,
    ...views.map((view) => view.label),
  ];

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const viewName = event.target.value;
    if (viewName === allReviewsView.label) {
      clearFilters();
      return;
    }
    if (viewName === lastSevenDaysView.label) {
      applyDefaultFilters();
      return;
    }
    if (viewName === exampleView.label) {
      setCurrentView(exampleView);
      return;
    }
    const foundView = views.find((view) => view.label === viewName);
    if (!foundView) {
      return;
    }
    setCurrentView(foundView);
  };

  const getSelectedValue = () => {
    if (!currentView.label) return filteredResultsLabel;
    if (!possibleValues.includes(currentView.label)) return filteredResultsLabel;
    return currentView.label;
  };

  useEffect(() => {
    if (!currentView.label && Object.keys(currentView.filters).length === 0) {
      applyDefaultFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl fullWidth={isPhone} sx={{ minWidth: width, ...(!isPhone && { maxWidth: width }) }}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        id="view-select"
        label={label}
        defaultValue={lastSevenDaysView.label}
        value={getSelectedValue()}
        renderValue={(value) => value}
        sx={{ ...(!currentView.label && { color: (theme) => theme.palette.text.secondary }) }}
        open={isOpen}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        onChange={handleChange}
        MenuProps={{ PaperProps: { sx: { width } } }}
      >
        {/* To display a 'fake placeholder' in this Select component when applying filters without being saved as View, a MenuItem with 'display: none' is needed. */}
        <MenuItem value={filteredResultsLabel} sx={{ display: 'none' }} />
        <MenuItem
          data-gtm-class="inbox_select_view"
          data-gtm-id="inbox_all_reviews_view"
          value={allReviewsView.label}
        >
          <ViewItem view={allReviewsView} />
        </MenuItem>
        <MenuItem
          data-gtm-class="inbox_select_view"
          data-gtm-id="inbox_last_seven_days_view"
          value={lastSevenDaysView.label}
        >
          <ViewItem view={lastSevenDaysView} />
        </MenuItem>
        <Divider />
        {views.length === 0 && (
          <MenuItem
            data-gtm-class="inbox_select_view"
            data-gtm-id={`${JSON.stringify(exampleView.filters)}`}
            value={exampleView.label}
          >
            <ViewItem view={exampleView} />
          </MenuItem>
        )}
        {views.map((view, index) => (
          <MenuItem
            data-gtm-class="inbox_select_view"
            data-gtm-id={`${JSON.stringify(view.filters)}`}
            key={index}
            value={view.label}
          >
            <ViewItem view={view} />
          </MenuItem>
        ))}
        {onManageViews && [
          <Divider key="divider" />,
          <MenuItem
            key="manage-views"
            onClick={onManageViews}
            data-gtm-class="inbox_manage_view"
            data-gtm-id="inbox_manage_view_button"
          >
            <Button
              startIcon={<StyledFontAwesomeIcon icon={faSliders} style={{ fontSize: 'inherit' }} />}
              sx={{ padding: 0, '&:hover': { backgroundColor: 'transparent' } }}
            >
              <FormattedMessage id="inbox.manage-views" defaultMessage="Manage Views" />
            </Button>
          </MenuItem>,
        ]}
      </Select>
    </FormControl>
  );
}
