import { useEffect, useState } from 'react';

import { styled } from '@mui/material';
import { FormControl, InputBase, MenuItem, Select } from '@trustyou/ui';

export type Option = {
  label: string;
  value: number | string;
};

const ChipInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    backgroundColor: theme.palette.grey[200],
    transition: theme.transitions.create(['background-color']),
    borderRadius: theme.spacing(2),
    paddingBlock: theme.spacing(0.5),
    paddingInline: theme.spacing(1.5),
    fontSize: 13,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
      borderRadius: theme.spacing(2),
    },
  },
}));

export type DropdownChipProps = {
  options: Option[];
  initialValue: Option;
  onChange: (value: Option) => void;
  title?: string;
};

export function DropdownChip({ options, initialValue, onChange, title }: DropdownChipProps) {
  const [value, setValue] = useState<string | number>(initialValue.value);

  const handleClick = (option: Option) => {
    setValue(option.value);
    onChange(option);
  };

  useEffect(() => {
    setValue(initialValue.value);
  }, [initialValue.value]);

  return (
    <FormControl variant="standard">
      <Select
        aria-label={`language selector ${title}`}
        value={value}
        input={<ChipInput />}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          sx: {
            maxHeight: 460,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            onClick={() => handleClick(option)}
            data-gtm-class={`inbox_response_ai_${title?.split(' ').at(-1)?.toLowerCase()}`}
            data-gtm-id={option.label.toLowerCase()}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
