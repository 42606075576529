import type { IntlShape } from 'react-intl';

import { lightBlue } from '@mui/material/colors';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { faEdit } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Chip, CustomCell, IconButton, StyledFontAwesomeIcon, Tooltip } from '@trustyou/ui';

export const visitDataColumns = ({
  editButtonClick,
  intl,
}: {
  editButtonClick: () => void;
  intl: IntlShape;
}): GridColDef[] => {
  return [
    {
      field: 'name',
      headerName: intl.formatMessage({ id: 'visit-data.common.name', defaultMessage: 'Name' }),
      flex: 1,
    },
    {
      field: 'internal_key',
      headerName: intl.formatMessage({
        id: 'visit-data.common.reference-code',
        defaultMessage: 'Reference Code',
      }),
      flex: 1,
    },
    {
      field: 'type',
      headerName: intl.formatMessage({
        id: 'visit-data.common.type',
        defaultMessage: 'Type',
      }),
      flex: 1,
      renderCell: ({ value }) => <CustomCell text={value} variant="de-emphasized" />,
    },
    {
      field: 'category',
      headerName: intl.formatMessage({
        id: 'visit-data.common.category',
        defaultMessage: 'Category',
      }),
      flex: 1,
      valueGetter: ({ row }) => row,
      renderCell: ({ value }) => (
        <Chip
          label={
            value.category === 'predefined'
              ? intl.formatMessage({ id: 'common.standard', defaultMessage: 'standard' })
              : value.metadata.deleted_at
                ? intl.formatMessage({
                    id: 'common.custom-archived',
                    defaultMessage: 'custom (archived)',
                  })
                : intl.formatMessage({ id: 'common.custom', defaultMessage: 'custom' })
          }
          color={value.category === 'custom' ? 'info' : 'default'}
          variant="pastel"
        />
      ),
    },
    {
      field: 'options',
      headerName: intl.formatMessage({
        id: 'visit-data.common.values',
        defaultMessage: 'Values',
      }),
      flex: 1,
      valueGetter: ({ value }) =>
        value?.map((option: { value: string }) => option.value).join(', '),
    },
    {
      field: '_id',
      headerName: 'ID',
      flex: 1,
    },
    {
      field: 'edit',
      headerName: intl.formatMessage({
        id: 'visit-data.common.edit',
        defaultMessage: 'Edit',
      }),
      flex: 1,
      renderCell: ({ row }) => (
        <Tooltip
          title={intl.formatMessage({
            id: 'visit-data.standard-data-point.disabled-tooltip',
            defaultMessage: 'Standard data points are not editable',
          })}
          disableHoverListener={row.category !== 'predefined'}
          arrow
          placement="top"
        >
          <span>
            <IconButton
              size="small"
              disabled={row.category === 'predefined'}
              onClick={editButtonClick}
            >
              <StyledFontAwesomeIcon icon={faEdit} />
            </IconButton>
          </span>
        </Tooltip>
      ),
    },
  ];
};
