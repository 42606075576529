import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { Chip, Tooltip } from '@trustyou/ui';

const MatchedChip = () => (
  <Tooltip
    placement="top"
    arrow
    title={
      <FormattedMessage
        id="entitiesImporter.foundInOurDatabaseAndWillBeImported"
        defaultMessage="Found in our database and will be imported"
      />
    }
  >
    <Chip
      label={<FormattedMessage id="entitiesImporter.matched" defaultMessage="Matched" />}
      color="success"
      variant="pastel"
    />
  </Tooltip>
);

const NotFoundChip = () => (
  <Tooltip
    placement="top"
    arrow
    title={
      <FormattedMessage
        id="entitiesImporter.notFoundInOurDatabaseAndWillBeVerified "
        defaultMessage="Not found in our database and will be verified "
      />
    }
  >
    <Chip
      label={<FormattedMessage id="entitiesImporter.notFound" defaultMessage="Not found" />}
      color="warning"
      variant="pastel"
    />
  </Tooltip>
);

const ErrorChip = () => (
  <Tooltip
    placement="top"
    arrow
    title={
      <FormattedMessage
        id="entitiesImporter.pleaseFixTheErrorsAndUploadAgain"
        defaultMessage="Please fix the errors and upload again"
      />
    }
  >
    <Chip
      label={<FormattedMessage id="entitiesImporter.error" defaultMessage="Error" />}
      color="error"
      variant="pastel"
    />
  </Tooltip>
);

export const STATUS_CHIP_MAP: { [key: string]: ReactNode } = {
  matched: <MatchedChip />,
  not_found: <NotFoundChip />,
  error: <ErrorChip />,
};
