import {
  ALL,
  ALL_REVIEWS_FILTERS,
  type CustomTimerange,
  type Filters,
  type FormattedCustomTimeRange,
  type PresetTimerange,
  type RelativeTimerange,
  TIMERANGE_MAPPING,
  type TimerangeKey,
  type TimerangeValue,
  type View,
} from '@trustyou/shared';
import dayjs from 'dayjs';

import { type Mapping, mapBySectors, mapToSubCategories, mergeSectorMaps } from './filters';

import type {
  LanguageEnum,
  ReviewView_Input as ReviewView,
  ScoreRange,
  SurveyFilter_Input,
  ViewSchema_Input,
  ViewSchema_Output,
  ViewScoreEnum,
  VisitDataFilter,
} from '../client';
import { DATE_FORMAT } from '../components/custom-date-range-dialog/custom-date-range-picker';
import {
  SCORE_RANGE_MAP,
  SORTING_API_NAMES_MAP,
  SORTING_SEMANTIC_NAMES_MAP,
} from '../constants/filters';

export const getScoreRanges = (scoreNames?: ViewScoreEnum[]): ScoreRange[] | undefined => {
  return scoreNames?.map((scoreName) => {
    const range = SCORE_RANGE_MAP[scoreName];
    if (typeof range === 'boolean') {
      return { no_score: true };
    }
    return {
      start: range?.at(0) ?? 0,
      end: range?.at(1) ?? 0,
    };
  });
};

export const getSurveyFilter = (
  surveyFilter?: SurveyFilter_Input | null
): SurveyFilter_Input | undefined => {
  if (!surveyFilter) return undefined;

  const filteredVisitData = surveyFilter.visit_data
    ?.map((item: VisitDataFilter) => ({
      ...item,
      values: item.values.filter((value) => Boolean(value)),
    }))
    .filter((item: VisitDataFilter) => item.values.length > 0);

  const newSurveyFilter = {
    ids: surveyFilter.ids ?? undefined,
    visit_data: filteredVisitData && filteredVisitData.length > 0 ? filteredVisitData : undefined,
  };

  return Object.keys(newSurveyFilter).length > 0 ? newSurveyFilter : undefined;
};

export const getSorting = (semanticName: string) => SORTING_API_NAMES_MAP[semanticName];

export const getSortingSemanticName = (sorting: string) => SORTING_SEMANTIC_NAMES_MAP[sorting];

export const excludeFalsyFilters = (
  view: ReviewView,
  categoryMapping?: Mapping,
  subcategoryMapping?: Mapping
): ReviewView => {
  if (!view) {
    return {};
  }

  const definedFilters = Object.keys(view).reduce<ReviewView>((acc, key) => {
    const value = view[key as keyof ReviewView];
    const isEmptyArray = Array.isArray(value) && !value.length;
    const isEmptyObject = value !== null && typeof value === 'object' && !Object.keys(value).length;
    // Avoid sending specific filter key if it's falsy.
    if (!value || isEmptyArray || isEmptyObject) {
      return acc;
    }
    return { ...acc, [key]: value };
  }, {});

  const score = view.score ? { score: getScoreRanges(view.score) } : undefined;
  const semaTopics = getSemaTopics(view, categoryMapping, subcategoryMapping);

  return {
    ...definedFilters,
    ...score,
    // ...survey,
    ...semaTopics,
  } as ReviewView;
};

export const formatFieldSlug = (fieldName: string) => fieldName.toLowerCase().replaceAll('_', '-');

export const formatCustomRange = (start: string, end: string): FormattedCustomTimeRange =>
  `${start} - ${end}`;

export const isCustomTimerange = (timerange: string): timerange is FormattedCustomTimeRange => {
  return /^\d{2}\.\d{2}\.\d{4} - \d{2}\.\d{2}\.\d{4}$/.test(timerange);
};

export const getCustomTimerangeValue = (
  formattedCustomTimerange: FormattedCustomTimeRange
): CustomTimerange => {
  const today = dayjs();
  const prevWeek = today.subtract(7, 'day');
  const lastWeek = [prevWeek.startOf('week'), prevWeek.endOf('week')];
  const [start, end] = formattedCustomTimerange.split(' - ');

  if (!end) {
    return {
      timerange: {
        start: dayjs(lastWeek[0]).unix(),
        end: dayjs(lastWeek[1]).unix(),
      },
    };
  }

  return {
    timerange: {
      start: dayjs(start, DATE_FORMAT).unix(),
      end: dayjs(end, DATE_FORMAT).endOf('day').unix(),
    },
  };
};

const getTimerangeValue = (timerange: TimerangeKey): TimerangeValue => {
  if (isCustomTimerange(timerange)) {
    return getCustomTimerangeValue(timerange);
  }
  return TIMERANGE_MAPPING[timerange];
};

const getSemaTopics = (
  viewFilters: ReviewView,
  categoryMapping: Mapping = {},
  subcategoryMapping: Mapping = {}
) => {
  if (!Object.keys(categoryMapping).length || !Object.keys(subcategoryMapping).length) return;

  const categories = viewFilters.sema_categories ?? [];
  const subcategories = viewFilters.sema_subcategories ?? [];

  const topLevelSectors = mapBySectors(categories, categoryMapping);
  const subLevelSectors = mapBySectors(subcategories, subcategoryMapping);

  // top level sectors need to be mapped to the sub levels to filter by correct topic ids
  const mappedTopLevelSectors = mapToSubCategories(topLevelSectors, subcategoryMapping);

  const topicIds = mergeSectorMaps(mappedTopLevelSectors, subLevelSectors);

  return { sema_topics: topicIds };
};

export const mapFiltersToViewFilters = (filters: Filters): ReviewView => {
  const viewFilters: ReviewView = {
    search_terms: filters.keywords,
    ...getTimerangeValue(filters.timerange as TimerangeKey),
    status: filters.statuses,
    score: filters.scores,
    source: filters.sources,
    survey: getSurveyFilter(filters.survey as SurveyFilter_Input),
    language: filters.languages,
    respondable: filters.answerable || undefined,
    entity_ids: filters.entities,
    sema_categories: filters.categories,
    sema_subcategories: filters.subcategories,
  };

  return viewFilters;
};

export const mapViewFiltersToFilters = (viewFilters?: ReviewView | null): Filters => {
  if (!viewFilters) {
    return {};
  }

  const timerangeKey =
    Object.keys(TIMERANGE_MAPPING).find((key) => {
      const value = TIMERANGE_MAPPING[key as TimerangeKey] as TimerangeValue;
      const presetTimerange = (value as PresetTimerange)?.preset_timerange;
      const relativeTimerange = (value as RelativeTimerange)?.relative_timerange;
      const customTimerange = (value as CustomTimerange)?.timerange;
      if (presetTimerange)
        return (
          presetTimerange.start === viewFilters.preset_timerange?.start &&
          presetTimerange.end === viewFilters.preset_timerange?.end
        );
      if (relativeTimerange) return relativeTimerange === viewFilters.relative_timerange;
      if (customTimerange)
        return (
          customTimerange.start === viewFilters.timerange?.start &&
          customTimerange.end === viewFilters.timerange?.end
        );

      return false;
    }) ?? ALL;

  return {
    keywords: viewFilters.search_terms ?? undefined,
    timerange: timerangeKey,
    // @ts-expect-error Type '"unresponded"' is not assignable to type '"read" | "unread" | "confirmed" | "responded" | "deleted" | "marked_as_deleted" | "inappropriate" | "marked_as_inappropriate"'.
    statuses: viewFilters.status ?? (ALL_REVIEWS_FILTERS?.statuses as Filters['statuses']),
    scores: viewFilters.score ?? undefined,
    sources: viewFilters.source ?? undefined,
    survey: (viewFilters.survey as Filters['survey']) ?? undefined,
    languages: viewFilters.language as LanguageEnum[],
    answerable: viewFilters.respondable ?? undefined,
    entities: viewFilters.entity_ids ?? undefined,
    categories: viewFilters.sema_categories ?? undefined,
    subcategories: viewFilters.sema_subcategories ?? undefined,
  };
};

export const mapFetchedViewsToUsableViews = (fetchedViews: ViewSchema_Output[] = []): View[] => {
  return fetchedViews.map((view) => ({
    label: view.label,
    filters: mapViewFiltersToFilters(view.filters),
  }));
};

export const mapUsableViewsToSavableViews = (usableViews: View[]): ViewSchema_Input[] => {
  return usableViews.map((view) => ({
    label: view.label,
    filters: mapFiltersToViewFilters(view.filters ?? {}),
  }));
};
