import { useIntl } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type AccordionProps, type AccordionSummaryProps, styled } from '@mui/material';
import { faChevronDown } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { type FiltersField, useEntityCount } from '@trustyou/shared';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@trustyou/ui';

import useListSurveysPermission from '../../hooks/permissions/use-list-surveys-permission';
import useListVisitDataPermission from '../../hooks/permissions/use-list-visit-data-permission';
import { CategoriesFilterSection } from '../filters/categories';
import { EntityFilter } from '../filters/entities/entity-filter';
import { SurveyFilter } from '../filters/surveys/survey-filter';
import { VisitDataFilter } from '../filters/visit-data/visit-data-filter';

const StyledAccordion = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: 'none',
  '&::before': {
    display: 'none',
  },
  '&.MuiAccordion-root': {
    marginBlockStart: theme.spacing(0),
    marginBlockEnd: theme.spacing(-2),
  },
}));

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  gap: theme.spacing(2),
  paddingInline: theme.spacing(0),
}));

export function AdvancedFilters() {
  const intl = useIntl();
  const { data: entityCount = 0 } = useEntityCount();
  const hasVisitDataPermission = useListVisitDataPermission();
  const hasSurveyPermission = useListSurveysPermission();

  return (
    <StyledAccordion>
      <StyledAccordionSummary>
        <Typography variant="subtitle1" fontWeight={500}>
          {intl.formatMessage({ id: 'inbox.filter.section.advanced', defaultMessage: 'Advanced' })}
        </Typography>
      </StyledAccordionSummary>
      <AccordionDetails sx={{ paddingX: 0 }}>
        <Stack spacing={3}>
          {entityCount > 1 && <EntityFilter />}
          {hasSurveyPermission && <SurveyFilter fieldName={'survey.ids' as FiltersField} />}
          <CategoriesFilterSection />
          {hasVisitDataPermission && <VisitDataFilter />}
        </Stack>
      </AccordionDetails>
    </StyledAccordion>
  );
}
