// https://mui.com/material-ui/react-autocomplete/#country-select
import { type SyntheticEvent, useState } from 'react';
import { useIntl } from 'react-intl';

import { InputAdornment, createFilterOptions } from '@mui/material';
import { useResponsive } from '@trustyou/shared';
import { Autocomplete, Box, TextField } from '@trustyou/ui';

import { type CountryType, countries } from './countries';

import { messages } from '../../../utils/messages';

type CountrySelectProps = {
  initialCountryCode?: string;
  onChange?: (country: CountryType) => void;
};

export function CountrySelect({ initialCountryCode = '', onChange }: CountrySelectProps) {
  const intl = useIntl();
  const { isSmallPhone, isPhone } = useResponsive();

  const initialCountry = countries.find((country) => country.code === initialCountryCode);
  const [country, setCountry] = useState(initialCountry);

  const getMinWidth = () => {
    if (isSmallPhone) return 0;
    if (isPhone) return '50%';
    return 300;
  };

  const handleChange = (event: SyntheticEvent, value: CountryType) => {
    setCountry(value);
    onChange?.(value);
  };

  return (
    <Autocomplete
      id="country-select"
      options={countries}
      autoHighlight
      isOptionEqualToValue={(option, value) => option.code === value.code}
      value={country}
      onChange={handleChange}
      disableClearable
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box {...optionProps} key={key} component="li">
            <Flag country={option} />
            <Box width={16} />
            {option.label}
            <Box flexGrow={1} />
            <Prefix country={option} />
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          slotProps={{
            htmlInput: {
              autoComplete: 'new-password', // disable autocomplete and autofill
              placeholder: intl.formatMessage(messages.phoneCountryCodePlaceholder),
            },
            input: {
              startAdornment: country && (
                <>
                  {params.InputProps.startAdornment}
                  <InputAdornment position="start" sx={{ marginInlineStart: 1 }}>
                    <Flag country={country} />
                  </InputAdornment>
                </>
              ),
              endAdornment: country && (
                <>
                  <InputAdornment position="end">
                    <Prefix country={country} />
                  </InputAdornment>
                  {params.InputProps.endAdornment}
                </>
              ),
            },
          }}
        />
      )}
      filterOptions={createFilterOptions({ stringify: (option) => JSON.stringify(option) })}
      sx={{ minWidth: getMinWidth() }}
    />
  );
}

function Flag({ country }: { country: CountryType }) {
  return (
    <img
      loading="lazy"
      width="20"
      srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
      src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
      alt=""
    />
  );
}

function Prefix({ country }: { country: CountryType }) {
  return (
    <Box component="span" color="text.secondary">
      +{country.phone}
    </Box>
  );
}
