import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Typography } from '@trustyou/ui';

import { InfoDialog } from './info-dialog';
import { detailsDialogMessages, reasonAndNotesMessages } from './messages';
import { useReasonOptions } from './use-reason-options';

import { useReview } from '../../hooks';
import { getHumanDate } from '../../utils/date';

type SurveyModerationDetailsDialogProps = {
  isOpen: boolean;
  onClose?: () => void;
};

export function SurveyModerationDetailsDialog({
  isOpen,
  onClose,
}: SurveyModerationDetailsDialogProps) {
  const intl = useIntl();
  const { reviewId = '' } = useParams();
  const { data: reviewRoot } = useReview({ reviewId });
  const { moderation } = reviewRoot ?? {};
  const reasonOptions = useReasonOptions();
  const reason = reasonOptions.find((option) => option.value === moderation?.request.reason)?.label;

  return (
    <InfoDialog
      open={isOpen}
      onClose={onClose}
      // @ts-expect-error Type 'ReactElement<any, any>' is not assignable to type 'string'.
      title={
        <>
          {intl.formatMessage(detailsDialogMessages.title)}
          <Typography variant="body2" color="text.secondary">
            {intl.formatMessage(detailsDialogMessages.subtitle, {
              user: moderation?.request.username,
              date: getHumanDate(moderation?.request.created_at ?? ''),
            })}
          </Typography>
        </>
      }
      // @ts-expect-error Type 'ReactElement<any, any>' is not assignable to type 'string'.
      content={
        <>
          <Typography variant="subtitle2" sx={{ marginBlockStart: 2 }}>
            {intl.formatMessage(reasonAndNotesMessages.reasonFieldLabel)}
          </Typography>
          <Typography variant="body2">{reason}</Typography>
          <Typography variant="subtitle2" sx={{ marginBlockStart: 3 }}>
            {intl.formatMessage(reasonAndNotesMessages.notesFieldLabel)}
          </Typography>
          <Typography variant="body2">{moderation?.request.notes}</Typography>
        </>
      }
      fullWidth
      maxWidth="sm"
      sx={{ '& .MuiDialog-paper': { maxHeight: 600 } }}
    />
  );
}
