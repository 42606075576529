import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useResponsive } from '@trustyou/shared';
import { Stack } from '@trustyou/ui';

import { useReview } from '../../../../hooks';
import type { ResponseFormSchema } from '../../../../types';
import { isTrustYouFeedback } from '../../../../utils/review';
import { DirectResponseButton } from './submit-buttons/direct-response-button';
import { GoToSourceButton } from './submit-buttons/go-to-source-button';
import { MarkAsRespondedButton } from './submit-buttons/mark-as-responded-button';
import { PrivateSurveyRespondButton } from './submit-buttons/private-survey-respond-button';
import { PublicSurveyRespondButton } from './submit-buttons/public-survey-respond-button';

export type SubmitButtonProps = {
  onSubmit: () => void;
  isDisabled?: boolean;
};

export function SubmitRegion({ onSubmit }: SubmitButtonProps) {
  const { isPhone } = useResponsive();
  const { reviewId = '' } = useParams();
  const { data: reviewRoot } = useReview({ reviewId });
  const { watch } = useFormContext<ResponseFormSchema>();

  const isSurvey = isTrustYouFeedback(reviewRoot);
  const isOnlineReview = !isSurvey;

  const isDirectlyRespondable = reviewRoot?.meta.directly_respondable;

  const isPublicSurvey = reviewRoot?.survey?.privacy_level === 'public';
  const isPrivateSurvey = reviewRoot?.survey?.privacy_level === 'private';

  const watchedResponse = watch('response');
  const isDisabled = !watchedResponse;

  return (
    <Stack direction={isPhone ? 'column' : 'row'} spacing={2}>
      {isPublicSurvey && <PublicSurveyRespondButton onSubmit={onSubmit} isDisabled={isDisabled} />}
      {isPrivateSurvey && (
        <PrivateSurveyRespondButton onSubmit={onSubmit} isDisabled={isDisabled} />
      )}
      {isDirectlyRespondable && (
        <DirectResponseButton onSubmit={onSubmit} isDisabled={isDisabled} />
      )}
      {!isDirectlyRespondable && isOnlineReview && (
        <>
          <GoToSourceButton text={watchedResponse} isDisabled={isDisabled} />
          <MarkAsRespondedButton onSubmit={onSubmit} isDisabled={isDisabled} />
        </>
      )}
    </Stack>
  );
}
