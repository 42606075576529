import { FormattedMessage, useIntl } from 'react-intl';

import { faMemoCircleInfo as faMemoCircleInfoRegular } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { useLanguageStore } from '@trustyou/shared';
import { Button, Chip, List, ListItem, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';
import dayjs from 'dayjs';

import { BooleanAnswer } from './boolean-answer';
import { type GuestType, GuestTypeAnswer } from './guest-type-answer';
import { QuestionWrapper } from './question-wrapper';
import { TextAnswer } from './text-answer';

import type { SurveyAnswer } from '../../client';
import { useLanguage } from '../../hooks';
import { getNPSAnswerColor, getScaleAnswerColor } from '../../utils/review';
import { SelectAnswer } from './select-answer';

const DATE_INPUT_FORMAT = 'YYYY-MM-DD';
const DATE_OUTPUT_FORMAT = 'DD MMM, YYYY';

type SurveyAnswersProps = {
  answers: SurveyAnswer[];
  defaultLanguage: string;
  onPressVisitDataInfo: () => void;
};

export function SurveyAnswers({
  answers,
  defaultLanguage,
  onPressVisitDataInfo,
}: SurveyAnswersProps) {
  const intl = useIntl();
  const { locale } = useLanguageStore();
  const { getFormattedLanguageCode } = useLanguage();
  const userLanguage = getFormattedLanguageCode(locale);

  if (answers.length === 0) {
    return null;
  }

  return (
    <List data-testid="survey-block">
      {answers
        // Only render questions with answers
        .filter((surveyAnswer) => Object.keys(surveyAnswer.answer.value).length > 0)
        .map((surveyAnswer) => (
          <ListItem key={surveyAnswer.question_id} disableGutters sx={{ paddingBlock: 1.5 }}>
            <QuestionWrapper
              question={
                surveyAnswer.internal_name[userLanguage] ??
                surveyAnswer.internal_name[defaultLanguage] ??
                Object.values(surveyAnswer.internal_name).at(0)
              }
            >
              <AnswerItem surveyAnswer={surveyAnswer} userLanguage={userLanguage} />
            </QuestionWrapper>
          </ListItem>
        ))}
      <ListItem
        disableGutters
        sx={{
          paddingBlock: 1.5,
          display: 'flex',
          gap: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          color: 'text.secondary',
        }}
      >
        <>
          <Typography variant="caption">
            {intl.formatMessage({
              id: 'inbox.survey.questions.hint',
              defaultMessage: 'Only answered questions are visible',
            })}
          </Typography>
          <Button
            data-gtm-class="inbox_visit_data"
            data-gtm-id="inbox_visit_data_survey_questions"
            onClick={onPressVisitDataInfo}
            startIcon={<StyledFontAwesomeIcon icon={faMemoCircleInfoRegular} />}
          >
            <FormattedMessage id="inbox.visit-data" defaultMessage="Visit data" />
          </Button>
        </>
      </ListItem>
    </List>
  );
}

function AnswerItem({
  surveyAnswer,
  userLanguage,
}: {
  surveyAnswer: SurveyAnswer;
  userLanguage: string;
}) {
  const intl = useIntl();
  const { answer, handling_type, primitive } = surveyAnswer;

  if (answer.not_applicable) {
    return (
      <Chip
        label={intl.formatMessage({
          id: 'inbox.survey.answer.notApplicable',
          defaultMessage: 'Not applicable',
        })}
        color="default"
        variant="pastel"
      />
    );
  }

  if (answer.type === 'date') {
    return (
      <TextAnswer>
        {dayjs(answer.value.str_val, DATE_INPUT_FORMAT).format(DATE_OUTPUT_FORMAT)}
      </TextAnswer>
    );
  }

  if (handling_type === 'guest_type') {
    return (
      <GuestTypeAnswer
        text={answer.value.array_val?.at(0)?.label[userLanguage] ?? 'Single'}
        optionId={(answer.value.array_val?.at(0)?.option_id as GuestType) ?? 'single'}
      />
    );
  }

  if (primitive.type === 'nps') {
    if (!answer.value.int_val || isNaN(answer.value.int_val)) return null;
    return (
      <Chip
        label={`${Number(answer.value.int_val)} / 10`}
        color={getNPSAnswerColor(answer.value.int_val)}
        variant="pastel"
      />
    );
  }

  if (primitive.type === 'scale') {
    if (!answer.value.int_val || isNaN(answer.value.int_val)) return null;
    return (
      <Chip
        label={`${Number(answer.value.int_val)} / 100`}
        color={getScaleAnswerColor(answer.value.int_val)}
        variant="pastel"
      />
    );
  }

  if (primitive.type === 'select') {
    return <SelectAnswer answers={answer.value.array_val?.map((answer) => answer.label) ?? []} />;
  }

  if (primitive.type === 'boolean') {
    return <BooleanAnswer value={answer.value.bool_val} />;
  }

  const key = Object.keys(answer.value)[0] as keyof SurveyAnswer['answer']['value'];

  return <TextAnswer>{answer.value[key]?.toString()}</TextAnswer>;
}
