import { useIntl } from 'react-intl';

import { isTemporaryHidden, useShowBenchmarks, useShowSurveyInsights } from '@trustyou/shared';

import { reportNameMessages } from '../constants/messages';
import { useDashboardIds } from '../service/hooks';

type Report = {
  id: string;
  label: string;
};

export function useReportList(selected?: string) {
  const intl = useIntl();
  const isShowBenchmarksAllowed = useShowBenchmarks();
  const isShowSurveyInsightsAllowed = useShowSurveyInsights();
  const { data } = useDashboardIds();

  let list: Report[] = [];
  if (data) {
    list = [
      { id: data.review_dashboard_id, label: intl.formatMessage(reportNameMessages.reviews) },
      { id: data.sentiment_dashboard_id, label: intl.formatMessage(reportNameMessages.sentiment) },
    ];
    if (data.competitor_dashboard_id) {
      list.push({
        id: data.competitor_dashboard_id,
        label: intl.formatMessage(reportNameMessages.competitors),
      });
    }
    if (isShowBenchmarksAllowed && data.benchmark_dashboard_id) {
      list.push({
        id: data.benchmark_dashboard_id,
        label: intl.formatMessage(reportNameMessages.benchmarks),
      });
    }
    if (isShowSurveyInsightsAllowed && data.survey_insights_id) {
      list.push({
        id: data.survey_insights_id,
        label: intl.formatMessage(reportNameMessages.surveyReviews),
      });
    }
    if (isShowSurveyInsightsAllowed && data.survey_stats_id) {
      list.push({
        id: data.survey_stats_id,
        label: intl.formatMessage(reportNameMessages.surveyStats),
      });
    }
  }
  if (!isTemporaryHidden('CXP-customers-inbox-reports')) {
    list.push({ id: 'reviews_inbox', label: intl.formatMessage(reportNameMessages.reviewsInbox) });
  }
  return list.filter((row) => !selected || row.id === selected);
}
