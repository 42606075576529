import type { Environment, FeatureFlag } from '@trustyou/shared';

/*
  Usage:
  - 'all': hide everywhere
  - 'none': show everywhere
  - 'local', 'integration', 'staging', 'production': hide only in the specified environment(s)
  
  If a feature flag in not needed anymore, it can be removed from this object and its usages across the codebase.
*/
export const temporaryHiddenEnvironments: Record<FeatureFlag, Environment[]> = {
  'CXP-ops-manage-organizations': ['staging', 'production'],
  'CXP-poc-reviews-dialog': ['staging', 'production'],
  'CXP-customers-inbox-reports': ['production'],
  'CXP-customers-visit-data': ['production'],
  'CXP-customers-survey-management': ['production'],
};
