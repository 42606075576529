import {
  type IconDefinition,
  faBriefcase,
  faCircleHeart,
  faFamily,
  faPeopleGroup,
  faPerson,
} from '@trustyou/fortawesome/pro-solid-svg-icons';
import { Box, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

const ICON_MAP: Record<string, IconDefinition> = {
  business: faBriefcase,
  couple: faCircleHeart,
  family: faFamily,
  friends: faPeopleGroup,
  single: faPerson,
};

const getIcon = (travelerType: string) => ICON_MAP[travelerType] ?? ICON_MAP.single;

export type GuestType = 'business' | 'couple' | 'family' | 'friends' | 'single';

export type QuestTypeAnswerProps = {
  text: string;
  optionId: GuestType;
};

export function GuestTypeAnswer({ text, optionId }: QuestTypeAnswerProps) {
  return (
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      <StyledFontAwesomeIcon
        data-testid="traveler-type-icon"
        size="lg"
        icon={getIcon(optionId)}
        sx={{ color: (theme) => theme.palette.action.active }}
      />
      <Typography variant="body2">{text}</Typography>
    </Box>
  );
}
