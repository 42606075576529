import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import type { USER_STATUSES } from '@trustyou/shared';
import { Chip } from '@trustyou/ui';

const ActiveStatusChip = () => (
  <Chip
    label={<FormattedMessage id="tyDesign.user.activeStatus" defaultMessage="Active" />}
    color="success"
    variant="pastel"
  />
);

const InvitedStatusChip = () => (
  <Chip
    label={<FormattedMessage id="tyDesign.user.invitedStatus" defaultMessage="Invited" />}
    color="info"
    variant="pastel"
  />
);

export const USER_STATUS_CHIP_MAP: { [key in USER_STATUSES]: ReactNode } = {
  active: <ActiveStatusChip />,
  invited: <InvitedStatusChip />,
};
