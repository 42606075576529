import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { faArrowDown, faArrowUp, faLanguage } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Box, FormControl, RadioGroup } from '@trustyou/ui';

import { useLanguage } from '../../../../../hooks';
import type { ResponseFormSchema, ResponseTextFieldId } from '../../../../../types';
import { ReplaceGeneratedResponseDialog } from '../../../../response-ai/replace-generated-response-dialog';
import { ResponseAIActions } from '../../../../response-ai/response-ai-actions/response-ai-actions';
import { UsedAIGuides } from '../../../../response-ai/used-ai-guides';
import { ResponseFormContentLayout } from '../response-form-content-layout';
import { ResponseTextField } from '../response-text-field';
import { TranslateButton } from '../translate-button';
import { useResponseAI } from '../use-response-ai';
import { useTranslateResponseAI } from '../use-translate-response-ai';

type ResponseAIFormContentProps = {
  selectedResponse: ResponseTextFieldId;
  setSelectedResponse: (response: ResponseTextFieldId) => void;
  onSubmit: () => void;
};

export function ResponseAIFormContent({
  selectedResponse,
  setSelectedResponse,
  onSubmit,
}: ResponseAIFormContentProps) {
  const { fallBackLanguage } = useLanguage();
  const { getValues, setValue } = useFormContext<ResponseFormSchema>();

  const [guidesUsed, setGuidesUsed] = useState<string[] | null>();

  const {
    translateViaResponseAI,
    isTranslateCalled,
    firstFieldLanguage,
    setFirstFieldLanguage,
    secondFieldLanguage,
    translate,
    translateSameField,
    updateTranslation,
  } = useTranslateResponseAI({
    getValues,
    setValue,
    selectedResponse,
    setSelectedResponse,
  });

  const {
    generateResponseAI,
    handleGenerateResponseAI,
    isReplaceDialogOpen,
    setIsReplaceDialogOpen,
  } = useResponseAI({ setFirstFieldLanguage, setGuidesUsed });

  const isDisabled = generateResponseAI.isPending || translateViaResponseAI.isPending;

  return (
    <ResponseFormContentLayout onSubmit={onSubmit}>
      {guidesUsed?.length && <UsedAIGuides guides={guidesUsed} />}
      <ResponseAIActions
        isGenerating={generateResponseAI.isPending}
        onGenerateResponseAI={handleGenerateResponseAI}
      />
      <FormControl fullWidth>
        <RadioGroup
          defaultValue="original"
          onChange={(event) => setSelectedResponse(event.target.value as ResponseTextFieldId)}
        >
          <ResponseTextField
            fieldId="response"
            language={firstFieldLanguage ?? fallBackLanguage}
            showRadioButton={isTranslateCalled}
            isSelected={selectedResponse === 'response'}
            isDisabled={isDisabled}
            setSelectedResponse={setSelectedResponse}
            onChangeLanguage={(newLanguage) =>
              translateSameField(newLanguage.value as string, 'response')
            }
          />
          {generateResponseAI.data && (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'center', marginBlock: 2 }}>
                <TranslateButton
                  icon={
                    selectedResponse === 'response'
                      ? isTranslateCalled
                        ? faArrowDown
                        : faLanguage
                      : faArrowUp
                  }
                  loading={translateViaResponseAI.isPending}
                  onClick={!isTranslateCalled ? translate : updateTranslation}
                />
              </Box>
              {isTranslateCalled && (
                <ResponseTextField
                  fieldId="translatedResponse"
                  language={secondFieldLanguage ?? fallBackLanguage}
                  showRadioButton={isTranslateCalled}
                  isSelected={selectedResponse === 'translatedResponse'}
                  isDisabled={isDisabled}
                  setSelectedResponse={setSelectedResponse}
                  onChangeLanguage={(newLanguage) =>
                    translateSameField(newLanguage.value as string, 'translatedResponse')
                  }
                />
              )}
            </>
          )}
        </RadioGroup>
      </FormControl>
      <ReplaceGeneratedResponseDialog
        isOpen={isReplaceDialogOpen}
        onClose={() => setIsReplaceDialogOpen(!isReplaceDialogOpen)}
        onSubmit={(isChecked) =>
          handleGenerateResponseAI({ replace: true, skipDialogNextTime: isChecked })
        }
      />
    </ResponseFormContentLayout>
  );
}
