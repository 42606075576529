import { ResponseFormContentLayout } from '../response-form-content-layout';
import { ResponseTextField } from '../response-text-field';

type ResponseBlankFormContentProps = {
  onSubmit: () => void;
};

export function ResponseBlankFormContent({ onSubmit }: ResponseBlankFormContentProps) {
  return (
    <ResponseFormContentLayout onSubmit={onSubmit}>
      <ResponseTextField fieldId="response" />
    </ResponseFormContentLayout>
  );
}
