import { useState } from 'react';
import { useParams } from 'react-router-dom';

import type { GridPaginationModel } from '@mui/x-data-grid-pro';
import { getPaginationRequest, useDataGridIntl, useLanguageStore } from '@trustyou/shared';
import { DataGridPro } from '@trustyou/ui';

import { useColumns } from './use-columns';

import { PAGE_SIZE_OPTIONS } from '../../../../constants/ui';
import { useQuestionnaires } from '../../../../service/hooks/use-questionnaires';
import { CustomToolbarWithSearch } from '../../../shared/custom-toolbar-with-search';
import styles from '../../../shared/styles';

export function DataGrid() {
  const dataGridIntl = useDataGridIntl();
  const { locale } = useLanguageStore();
  const { surveyId = '' } = useParams();

  const [searchTerms, setSearchTerms] = useState('');
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: PAGE_SIZE_OPTIONS[0],
  });

  const { data, isLoading } = useQuestionnaires({
    surveyId,
    pagination: getPaginationRequest(paginationModel),
    searchTerms,
  });

  const columns = useColumns();
  const rows = data?.data || [];

  const handlePaginationModelChange = (newModel: GridPaginationModel) => {
    if (!isLoading) {
      setPaginationModel(newModel);
    }
  };

  return (
    <DataGridPro
      columns={columns}
      rows={rows}
      rowCount={data?.count ?? 0}
      autoHeight
      loading={isLoading}
      paginationMode="server"
      pageSizeOptions={PAGE_SIZE_OPTIONS}
      pagination
      paginationModel={paginationModel}
      onPaginationModelChange={handlePaginationModelChange}
      disableRowSelectionOnClick
      ignoreDiacritics
      slots={{
        toolbar: () => CustomToolbarWithSearch({ searchTerms, setSearchTerms }),
      }}
      sx={styles.disableCellOutline}
      localeText={dataGridIntl[locale].components.MuiDataGrid.defaultProps.localeText}
      density="standard"
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
    />
  );
}
