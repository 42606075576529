import { $assApi, INBOX_BASE_PATH } from '@trustyou/shared';
import type { AxiosResponse } from 'axios';

import type {
  ForwardReviewIn,
  GetAllSourcesApiInboxOrganizationIdReviewsGetSourcesGetResponse,
  ModerationRequestReasonEnum,
  PaginatedDataResponse_Union_list_ReviewEnvelopeOverviewSchema___list_ReviewEnvelopeDetailSchema___,
  ResponseIn,
  ReviewDeleteIn,
  ReviewEnvelopeDetailSchema,
  ReviewEnvelopeOverviewSchema,
  ReviewIn,
  ReviewIndexInfo,
  ReviewModerationRequestIn,
  ReviewModerationRevisionIn,
} from '../../../client';
import type {
  GetResponseUrlSuccess,
  SetReviewStatusPayload,
  SetReviewStatusSuccess,
} from '../../../types';
import type { Mapping } from '../../../utils/filters';
import { excludeFalsyFilters } from '../../../utils/mappers';

export async function fetchReviewsInfo() {
  const { data } = await $assApi.get<ReviewIndexInfo>(`${INBOX_BASE_PATH}/reviews/get-info`);
  return data;
}

export async function fetchSources() {
  const { data } =
    await $assApi.get<GetAllSourcesApiInboxOrganizationIdReviewsGetSourcesGetResponse>(
      `${INBOX_BASE_PATH}/reviews/get-sources`
    );
  return data;
}

export async function fetchReview({ reviewId }: { reviewId: string }) {
  const { data } = await $assApi.get<ReviewEnvelopeDetailSchema>(
    `${INBOX_BASE_PATH}/reviews/get-review/${reviewId}`
  );
  return data;
}

export async function fetchTranslatedReview({
  reviewId,
  locale,
}: {
  reviewId: string;
  locale: string;
}) {
  const { data } = await $assApi.get<ReviewEnvelopeOverviewSchema>(
    `${INBOX_BASE_PATH}/reviews/translate/${reviewId}`,
    {
      params: {
        target_language: locale,
      },
    }
  );
  return data;
}

export async function saveResponse({
  reviewId,
  payload,
}: {
  reviewId: string;
  payload: ResponseIn;
}) {
  const { data } = await $assApi.post<string>(
    `${INBOX_BASE_PATH}/reviews/save-response/${reviewId}`,
    payload
  );
  return data;
}

export async function fetchReviews(payload: ReviewIn) {
  const { data } =
    await $assApi.post<PaginatedDataResponse_Union_list_ReviewEnvelopeOverviewSchema___list_ReviewEnvelopeDetailSchema___>(
      `${INBOX_BASE_PATH}/reviews/filter`,
      payload
    );
  return data;
}

export async function fetchDetailedReviews(payload: ReviewIn) {
  const { data } =
    await $assApi.post<PaginatedDataResponse_Union_list_ReviewEnvelopeOverviewSchema___list_ReviewEnvelopeDetailSchema___>(
      `${INBOX_BASE_PATH}/reviews/by-ids?detail=true`,
      {
        ids: payload.filters,
        sort: payload.sort,
        offset: payload.offset,
        limit: payload.limit,
      }
    );
  return data;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function downloadStreamFile({
  response,
  type = 'text/csv',
  filename = 'export.csv',
}: {
  response: AxiosResponse;
  type?: string;
  filename?: string;
}) {
  // Convert data into a blob
  const { data } = response;
  const blob = new Blob([data], { type });

  // URL for the blob
  const url = window.URL.createObjectURL(blob);

  // Create a link and click it to download the file
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  // Append the link to the body (required for Firefox)
  document.body.appendChild(link);
  link.click();

  // Clean up
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}

export async function exportReviews(
  { filters, sort, offset }: ReviewIn,
  categoryMapping?: Mapping,
  subcategoryMapping?: Mapping
) {
  return $assApi
    .post(
      `${INBOX_BASE_PATH}/reviews/export`,
      {
        filters: excludeFalsyFilters(
          // @ts-expect-error Type 'null' is not assignable to type 'ReviewView | undefined'.
          filters,
          categoryMapping,
          subcategoryMapping
        ),
        sort,
        offset,
      },
      {
        responseType: 'blob',
      }
    )
    .then(async (response) => {
      const filename = response.headers['content-disposition']?.split('=')[1];
      const type = response.headers['content-type']?.split(';')[0];

      await downloadStreamFile({ response, type, filename });
    });
}

export async function setReviewReadStatus(payload: SetReviewStatusPayload) {
  const { data } = await $assApi.post<SetReviewStatusSuccess>(
    `${INBOX_BASE_PATH}/reviews/set-status-read-unread`,
    payload
  );
  return data;
}

export async function setReviewRespondedStatus(payload: SetReviewStatusPayload) {
  const { data } = await $assApi.post<SetReviewStatusSuccess>(
    `${INBOX_BASE_PATH}/reviews/set-status-responded-unresponded`,
    payload
  );
  return data;
}

export async function fetchResponseUrl(reviewId: string) {
  const { data } = await $assApi.get<GetResponseUrlSuccess>(
    `${INBOX_BASE_PATH}/reviews/get-response-url/${reviewId}`
  );
  return data;
}

export async function markAsDeleted(payload: ReviewDeleteIn) {
  const { data } = await $assApi.post<string>(
    `${INBOX_BASE_PATH}/reviews/mark-as-deleted`,
    payload
  );
  return data;
}

export async function fetchModerationReasons() {
  const { data } = await $assApi.get<Record<ModerationRequestReasonEnum, string>>(
    `${INBOX_BASE_PATH}/reviews/mark-as-inappropriate/reasons`
  );
  return data;
}

export async function requestModeration(payload: ReviewModerationRequestIn) {
  const { data } = await $assApi.post<string>(
    `${INBOX_BASE_PATH}/reviews/mark-as-inappropriate`,
    payload
  );
  return data;
}

export async function rejectModeration(payload: ReviewModerationRevisionIn) {
  const { data } = await $assApi.post<string>(
    `${INBOX_BASE_PATH}/reviews/mark-as-inappropriate/reject`,
    payload
  );
  return data;
}

export async function approveModeration(payload: ReviewModerationRevisionIn) {
  const { data } = await $assApi.post<string>(
    `${INBOX_BASE_PATH}/reviews/mark-as-inappropriate/approve`,
    payload
  );
  return data;
}

export async function forwardReview(reviewId: string, payload: ForwardReviewIn) {
  const { data } = await $assApi.post<string>(
    `${INBOX_BASE_PATH}/reviews/forward/${reviewId}`,
    payload
  );
  return data;
}
