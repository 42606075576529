import type { ReactNode } from 'react';

import MuiSelect, {
  type SelectProps as MuiSelectProps,
  type SelectChangeEvent,
} from '@mui/material/Select';
import { useGTMProps } from '@trustyou/shared';

import type { E2ECouldTest } from '../types/e2e';
import type { GTMTracked } from '../types/gtm';

export interface SelectProps<Value = unknown>
  extends Omit<MuiSelectProps<Value>, 'value' | 'onChange'>,
    E2ECouldTest,
    GTMTracked {
  value?: Value;
  onChange?: (event: SelectChangeEvent<Value>, child: ReactNode) => void;
}

export const Select = <Value,>({
  children,
  'data-testid': testId,
  ...restProps
}: SelectProps<Value>) => {
  const propsWithGtm = useGTMProps<SelectProps<Value>>(restProps, testId);
  return <MuiSelect {...propsWithGtm}>{children}</MuiSelect>;
};
