import { FormattedMessage } from 'react-intl';

import { faCircleCheck, faCircleXmark } from '@trustyou/fortawesome/pro-solid-svg-icons';
import { Box, StyledFontAwesomeIcon, Typography } from '@trustyou/ui';

export type BooleanQuestionProps = {
  value?: boolean | null;
};

export const BooleanAnswer = ({ value }: BooleanQuestionProps) => {
  return (
    <Box
      sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
      data-testid="survey-boolean-question"
    >
      <StyledFontAwesomeIcon
        data-testid="survey-boolean-icon"
        sx={{
          color: (theme) => (value ? theme.palette.success.light : theme.palette.error.light),
        }}
        size="lg"
        icon={value ? faCircleCheck : faCircleXmark}
      />
      <Typography variant="body2">
        {value ? (
          <FormattedMessage id="inbox.yes" defaultMessage="Yes" />
        ) : (
          <FormattedMessage id="inbox.no" defaultMessage="No" />
        )}
      </Typography>
    </Box>
  );
};
