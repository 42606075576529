import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import type { DateRange } from '@mui/x-date-pickers-pro';
import {
  ALL,
  type Filters,
  type FiltersField,
  type FormattedCustomTimeRange,
} from '@trustyou/shared';
import type { Dayjs } from 'dayjs';

import { DropdownRadio } from './dropdown-radio';
import { useTimeRangeGroups } from './use-time-range-groups';

import { useFilters } from '../../hooks/use-filters';
import { formatCustomRange, isCustomTimerange } from '../../utils/mappers';
import { CustomDateRangeDialog } from '../custom-date-range-dialog/custom-date-range-dialog';
import { DATE_FORMAT } from '../custom-date-range-dialog/custom-date-range-picker';
import { StyledMenuItem } from './styled-menu-item';

export type DropdownTimeRangeProps = {
  fieldName: FiltersField;
};

export function DropdownTimeRange({ fieldName }: DropdownTimeRangeProps) {
  const intl = useIntl();
  const { setValue } = useFormContext<Filters>();
  const { currentView } = useFilters();
  const timeRangeGroup = useTimeRangeGroups();

  const [customTimeRange, setCustomTimeRange] = useState('');
  const [isTimeRangeDialogOpen, setIsTimeRangeDialogOpen] = useState(false);

  const handleCustomTimeRangePick = (range: DateRange<Dayjs>) => {
    const [start, end] = range;
    if (!start || !end) {
      return;
    }
    const formattedRange = formatCustomRange(start.format(DATE_FORMAT), end.format(DATE_FORMAT));
    setValue(fieldName, formattedRange);
    setCustomTimeRange(formattedRange);
    setIsTimeRangeDialogOpen(false);
  };

  return (
    <>
      <DropdownRadio
        fieldName={fieldName}
        groups={timeRangeGroup}
        label={intl.formatMessage({
          id: 'inbox.filter.timeRange.label',
          defaultMessage: 'Time range',
        })}
        initialValue={currentView.filters?.timerange ?? ALL}
        customButton={
          <StyledMenuItem
            value={customTimeRange}
            selected={isCustomTimerange(customTimeRange)}
            onClick={() => setIsTimeRangeDialogOpen(true)}
            sx={{
              paddingInline: 2.5,
              color: 'primary.main',
              fontSize: 14,
              fontWeight: 500,
            }}
          >
            {intl.formatMessage({
              id: 'inbox.custom-range',
              defaultMessage: 'Custom range',
            })}
          </StyledMenuItem>
        }
      />
      <CustomDateRangeDialog
        isOpen={isTimeRangeDialogOpen}
        initialValue={(currentView.filters?.timerange ?? ALL) as FormattedCustomTimeRange}
        onApply={handleCustomTimeRangePick}
        onClose={() => setIsTimeRangeDialogOpen(false)}
      />
    </>
  );
}
