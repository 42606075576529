import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import type { ButtonProps } from '@mui/material';
import { faTrash } from '@trustyou/fortawesome/pro-regular-svg-icons';

import { messages } from './messages';

import { useReview } from '../../hooks';
import useDeletionPermission from '../../hooks/permissions/use-deletion-permission';
import { IconButtonWithTooltip } from '../icon-button-with-tooltip';

type TrashIconButtonProps = {
  onClick: ButtonProps['onClick'];
};

export function TrashIconButton({ onClick }: TrashIconButtonProps) {
  const intl = useIntl();
  const hasDeletionPermission = useDeletionPermission();
  const { reviewId = '' } = useParams();
  const { data: reviewRoot } = useReview({ reviewId });
  const { can_be_deleted, deletion } = reviewRoot ?? {};

  const isDeletionProcessStarted = ['requested', 'pending'].includes(deletion?.status ?? '');
  const isDeletionProcessFinished = ['confirmed'].includes(deletion?.status ?? '');

  const isDisabled =
    !hasDeletionPermission ||
    !can_be_deleted ||
    isDeletionProcessStarted ||
    isDeletionProcessFinished;

  return (
    <IconButtonWithTooltip
      data-gtm-class="review_action_on_review"
      data-gtm-id="delete"
      color="primary"
      onClick={onClick}
      icon={faTrash}
      disabled={isDisabled}
      tooltip={intl.formatMessage(messages.markAsDeleted)}
    />
  );
}
