import { groupBy, orderBy, uniqBy } from 'lodash';

import type { Widget } from '../types/widgets';

export const getGroupedSelectedWidgets = (widgets: Widget[], selectedIds: string[]) => {
  const widgetIdMap = widgets.reduce(
    (prev, cur) => ({
      ...prev,
      [cur.id]: cur,
    }),
    {} as Record<string, Widget>
  );
  const selectedWidgets = selectedIds.map((id) => widgetIdMap[id]).filter((row) => !!row);
  const orderedData = orderBy(selectedWidgets, 'order');
  const groupedData = groupBy(orderedData, 'group');
  const uniqData = uniqBy(orderedData, 'group').map((item) => ({
    ...item,
    groupItems: (item.group && groupedData[item.group]) || undefined,
  }));
  const processedData = [
    ...uniqData.filter((row) => row.groupItems),
    ...orderedData.filter((row) => !row.group),
  ];
  const orderedProcessedData: (Widget & {
    groupItems?: Widget[];
  })[] = orderBy(processedData, 'order');
  return orderedProcessedData;
};
