import { type QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useLanguageStore } from '@trustyou/shared';

import {
  approveModeration,
  fetchDetailedReviews,
  fetchModerationReasons,
  fetchResponseUrl,
  fetchReview,
  fetchReviews,
  fetchReviewsInfo,
  fetchSources,
  fetchTranslatedReview,
  forwardReview,
  markAsDeleted,
  rejectModeration,
  requestModeration,
  saveResponse,
  setReviewReadStatus,
  setReviewRespondedStatus,
} from './fetchers';

import type {
  ForwardReviewIn,
  ResponseIn,
  ReviewDeleteIn,
  ReviewModerationRequestIn,
  ReviewModerationRevisionIn,
} from '../../../client';
import { INVALIDATE_REVIEW_ITEM_DELAY_MS } from '../../../constants';
import {
  MODERATION_REASONS,
  PAGINATED_DETAILED_REVIEWS,
  PAGINATED_REVIEWS,
  RESPONSE_URL,
  REVIEWS_FILTERS_DATA,
  REVIEWS_INFO_DATA,
  REVIEW_DETAIL,
  SOURCES,
} from '../../../constants/query-keys';
import type { PrePayload, ReviewRoot, SetReviewStatusPayload } from '../../../types';

export type Sort = { [key: string]: string };

export function useFetchReviewsInfo() {
  return useQuery({
    queryKey: [REVIEWS_INFO_DATA],
    queryFn: fetchReviewsInfo,
    refetchOnWindowFocus: false,
  });
}

export function useFetchSources() {
  return useQuery({
    queryKey: [SOURCES],
    queryFn: fetchSources,
    refetchOnWindowFocus: false,
  });
}

export function useReview({ reviewId }: { reviewId: string }) {
  return useQuery({
    queryKey: [REVIEW_DETAIL, reviewId],
    queryFn: () => fetchReview({ reviewId }),
    refetchOnWindowFocus: false,
  });
}

export function useTranslatedReview({
  reviewId,
  shouldDisplayTranslation,
}: {
  reviewId: string;
  shouldDisplayTranslation: boolean;
}) {
  const { locale } = useLanguageStore();

  return useQuery({
    queryKey: [REVIEW_DETAIL, reviewId, locale],
    queryFn: () => fetchTranslatedReview({ reviewId, locale }),
    refetchOnWindowFocus: false,
    enabled: shouldDisplayTranslation,
  });
}

export function useSaveResponse() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ reviewId, payload }: { reviewId: string; payload: ResponseIn }) =>
      saveResponse({ reviewId, payload }),
    onSuccess: (data, { reviewId }) => {
      setTimeout(
        () => queryClient.invalidateQueries({ queryKey: [REVIEW_DETAIL, reviewId] }),
        INVALIDATE_REVIEW_ITEM_DELAY_MS
      );
    },
  });
}

export function useMarkAsDeleted() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: ReviewDeleteIn) => markAsDeleted(payload),
    onSuccess: (data, { review_id }) => {
      queryClient.invalidateQueries({ queryKey: [REVIEW_DETAIL, review_id] });
    },
  });
}

export function useModerationReasons() {
  return useQuery({
    queryKey: [MODERATION_REASONS],
    queryFn: fetchModerationReasons,
  });
}

export function useRequestModeration() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: ReviewModerationRequestIn) => requestModeration(payload),
    onSuccess: (data, { review_id }) => {
      queryClient.invalidateQueries({ queryKey: [REVIEW_DETAIL, review_id] });
    },
  });
}

export function useRejectModeration() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: ReviewModerationRevisionIn) => rejectModeration(payload),
    onSuccess: (data, { review_id }) => {
      queryClient.invalidateQueries({ queryKey: [REVIEW_DETAIL, review_id] });
    },
  });
}

export function useApproveModeration() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: ReviewModerationRevisionIn) => approveModeration(payload),
    onSuccess: (data, { review_id }) => {
      queryClient.invalidateQueries({ queryKey: [REVIEW_DETAIL, review_id] });
    },
  });
}

export function useForwardReview() {
  return useMutation({
    mutationFn: ({ reviewId, payload }: { reviewId: string; payload: ForwardReviewIn }) =>
      forwardReview(reviewId, payload),
  });
}

export const useFetchPaginatedReviews = ({ filters, page, pageSize, sort }: PrePayload) => {
  const payload = {
    filters: filters,
    sort,
    offset: page * pageSize,
    limit: pageSize,
  };

  return useQuery({
    queryKey: [PAGINATED_REVIEWS, { filters, sort, page, pageSize }],
    // @ts-expect-error Type '"low"' has no properties in common with type 'ScoreRange'.
    queryFn: () => fetchReviews(payload),
    refetchOnWindowFocus: false,
  });
};

export const useFetchPaginatedDetailedReviews = ({ filters, page, pageSize, sort }: PrePayload) => {
  const payload = {
    filters: filters.ids,
    sort,
    offset: page * pageSize,
    limit: pageSize,
  };

  return useQuery({
    queryKey: [PAGINATED_DETAILED_REVIEWS, { filters, sort, page, pageSize }],
    // @ts-expect-error Type '"low"' has no properties in common with type 'ScoreRange'.
    queryFn: () => fetchDetailedReviews(payload),
    refetchOnWindowFocus: false,
  });
};

export function useSetReviewReadStatus() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: SetReviewStatusPayload) => setReviewReadStatus(payload),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: [PAGINATED_REVIEWS] });
    },
  });
}

export function useSetReviewRespondedStatus() {
  return useMutation({
    mutationFn: (payload: SetReviewStatusPayload) => setReviewRespondedStatus(payload),
  });
}

export function updateReviewStatusById(queryClient: QueryClient, id: string, status: string) {
  return queryClient.setQueriesData(
    { queryKey: [REVIEWS_FILTERS_DATA], type: 'active' },

    (prevData) => {
      return {
        // @ts-expect-error Spread types may only be created from object types.ts(2698)
        ...prevData,
        // @ts-expect-error Spread types may only be created from object types.ts(2698)
        pages: prevData.pages.map((page) => ({
          ...page,
          items: page.items.map((reviewItem: ReviewRoot) =>
            id === reviewItem.id
              ? { ...reviewItem, meta: { ...reviewItem.meta, status } }
              : reviewItem
          ),
        })),
      };
    }
  );
}

export function useFetchResponseUrl(reviewId = '', isRespondable = true) {
  return useQuery({
    queryKey: [RESPONSE_URL, { reviewId }],
    queryFn: () => fetchResponseUrl(reviewId),
    refetchOnWindowFocus: false,
    enabled: !!reviewId && isRespondable,
  });
}
