import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { faArrowDownToLine } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { Button, StyledFontAwesomeIcon } from '@trustyou/ui';

import {
  exportReviews,
  useFetchSubcategoryMapping,
  useFetchTopLevelCategoryMapping,
} from '../../hooks';
import { useFilters } from '../../hooks/use-filters';
import { mapFiltersToViewFilters } from '../../utils/mappers';

export function CustomExportButton() {
  const [isExporting, setIsExporting] = useState(false);

  const categoryMappingQuery = useFetchTopLevelCategoryMapping();
  const subcategoryMappingQuery = useFetchSubcategoryMapping();
  const { currentView } = useFilters();

  const handleExportClick = async () => {
    setIsExporting(true);
    await exportReviews(
      {
        // @ts-expect-error Type 'ReviewView_Input' is not assignable to type 'ReviewFilters'.
        filters: mapFiltersToViewFilters(currentView.filters ?? {}),
        offset: 0,
      },
      categoryMappingQuery.data,
      subcategoryMappingQuery.data
    ).finally(() => {
      setIsExporting(false);
    });
  };

  return (
    <Button
      size="small"
      startIcon={<StyledFontAwesomeIcon icon={faArrowDownToLine} />}
      onClick={handleExportClick}
      data-gtm-class="inbox_table_actions"
      data-gtm-id="inbox_table_export_all"
      disabled={isExporting}
    >
      <FormattedMessage id="inbox.table.action.export-all" defaultMessage="Export all" />
    </Button>
  );
}
