import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { Checkbox, FormControl, FormControlLabel } from '@trustyou/ui';

import { manageDrawer } from '../../constants/messages/customAttributes';
import { styles } from './styles';

export const ProtectedController = () => {
  const { control } = useFormContext();
  return (
    <FormControl sx={styles.checkboxControllerWrapper}>
      <Controller
        name="protected"
        control={control}
        render={({ field }) => (
          <FormControlLabel
            control={<Checkbox {...field} checked={field.value} />}
            label={<FormattedMessage {...manageDrawer.protectedLabel} />}
            sx={styles.checkboxFormControlLabel}
          />
        )}
      />
    </FormControl>
  );
};
