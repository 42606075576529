import { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { faChevronDown, faChevronUp } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { actionMessages } from '@trustyou/shared';
import {
  Checkbox,
  Chip,
  Collapse,
  FormControlLabel,
  InfoDialog,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  StyledFontAwesomeIcon,
} from '@trustyou/ui';

import { newReportWizardMessages } from '../../../../constants/messages';
import { useNewReportStore } from '../../../../store';
import type { Widget } from '../../../../types/widgets';
import { getGroupedSelectedWidgets } from '../../../../utils/widgets';
import styles from './styles';

type WidgetsInfoProps = {
  widgetList: Widget[];
  onSelectionsChange: (selectedWidgets: string[]) => void;
};

export const WidgetsInfo = ({ widgetList, onSelectionsChange }: WidgetsInfoProps) => {
  const [isSelectedWidgetsModalOpen, setIsSelectedWidgetsModalOpen] = useState(false);
  const [expandedWidgets, setExpandedWidgets] = useState<Record<string, boolean>>({});
  const { newReport } = useNewReportStore();
  const selectedWidgetIds = newReport?.widgets || [];
  const selectedWidgetsCount = selectedWidgetIds.length || 0;
  const isAllWidgetsSelected = widgetList.length && widgetList.length === selectedWidgetsCount;
  const groupedWidgets = getGroupedSelectedWidgets(widgetList, selectedWidgetIds);

  const selectAllWidgets = () => {
    onSelectionsChange(isAllWidgetsSelected ? [] : widgetList.map((row) => row.id));
  };

  const toggleExpandedGroup = (id: string) => {
    setExpandedWidgets((state) => ({
      ...state,
      [id]: !state[id],
    }));
  };

  return (
    <>
      <Stack direction="row" gap={3} alignItems="center" marginLeft="auto">
        <Chip
          label={
            <FormattedMessage
              {...newReportWizardMessages.widgetsSelectedCount}
              values={{ count: selectedWidgetsCount }}
            />
          }
          color="info"
          variant="pastel"
          onClick={selectedWidgetsCount ? () => setIsSelectedWidgetsModalOpen(true) : undefined}
          sx={selectedWidgetsCount ? styles.selectableChip : undefined}
        />

        <FormControlLabel
          sx={{ marginRight: 1, marginLeft: 0 }}
          control={
            <Checkbox checked={!!isAllWidgetsSelected} onChange={selectAllWidgets} size="small" />
          }
          labelPlacement="start"
          label={<FormattedMessage {...newReportWizardMessages.widgetsSelectAll} />}
        />
      </Stack>
      <InfoDialog
        title={
          <FormattedMessage
            {...newReportWizardMessages.widgetsSelectedHeader}
            values={{ count: selectedWidgetsCount }}
          />
        }
        open={isSelectedWidgetsModalOpen}
        primaryActionText={<FormattedMessage {...actionMessages.okay} />}
        onPrimaryAction={() => setIsSelectedWidgetsModalOpen(false)}
        dataName="selected-widgets"
        maxWidth="sm"
        showHeaderCloseButton
      >
        <List sx={{ marginX: 3 }}>
          {groupedWidgets.map((parent) =>
            parent.groupItems ? (
              <Fragment key={parent.id}>
                <ListItemButton onClick={() => toggleExpandedGroup(parent.id)}>
                  <ListItemText primary={parent.group} />
                  <StyledFontAwesomeIcon
                    icon={expandedWidgets[parent.id] ? faChevronUp : faChevronDown}
                  />
                </ListItemButton>
                <Collapse in={expandedWidgets[parent.id]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {parent.groupItems.map((child) => (
                      <ListItemButton sx={{ pl: 4 }} key={`child-${child.id}`}>
                        <ListItemText primary={child.name} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </Fragment>
            ) : (
              <ListItemButton key={parent.id}>
                <ListItemText primary={parent.name} />
              </ListItemButton>
            )
          )}
        </List>
      </InfoDialog>
    </>
  );
};
