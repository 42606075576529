import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { faPencil } from '@trustyou/fortawesome/pro-regular-svg-icons';
import { stringToShortDate } from '@trustyou/shared';
import { Chip, CustomCell, IconButton } from '@trustyou/ui';

import type { Questionnaire_Output } from '../../../../client';

export function useColumns() {
  const handleClickEdit = (row: Questionnaire_Output) => {
    // TODO: open theme editor with prop `type="edit-survey"` and pass the row (theme version)
    alert(
      `to be implemented: open <ThemeEditor type="edit-theme" /> \n\n ${JSON.stringify({ row })}`
    );
  };

  const columns: GridColDef<Questionnaire_Output>[] = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
    },
    {
      field: 'version',
      headerName: 'Theme version',
      flex: 1,
      valueGetter: ({ row }) => row.internal_name,
      renderCell: ({ value }) => <CustomCell text={value} variant="emphasized" />,
    },
    {
      field: 'entityCount',
      headerName: 'Entities',
      flex: 1,
      valueGetter: ({ row }) => row.metadata.scopes?.length ?? 0,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      // TODO: grab status from backend
      valueGetter: ({ row }) => 'draft',
      renderCell: ({ value }) => (
        <Chip label={value} color={value === 'published' ? 'success' : 'info'} variant="pastel" />
      ),
    },
    {
      field: 'created',
      headerName: 'Created',
      flex: 1,
      valueGetter: ({ row }) => stringToShortDate(row.metadata.created_at ?? ''),
    },
    {
      field: 'modified',
      headerName: 'Last modified',
      flex: 1,
      valueGetter: ({ row }) => stringToShortDate(row.metadata.updated_at ?? ''),
    },
    {
      field: 'edit',
      headerName: 'Edit',
      flex: 1,
      renderCell: ({ row }) => (
        <IconButton onClick={() => handleClickEdit(row)} size="small" sx={{ aspectRatio: '1 / 1' }}>
          <FontAwesomeIcon icon={faPencil} fixedWidth />
        </IconButton>
      ),
    },
  ];

  return columns;
}
