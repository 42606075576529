import { useIntl } from 'react-intl';

import type { ButtonProps } from '@mui/material';
import { Box, Button } from '@trustyou/ui';

type FiltersButtonProps = {
  count: number;
  isDisabled?: boolean;
  onClick?: ButtonProps['onClick'];
  onClear?: ButtonProps['onClick'];
};

export function FiltersButton({ count, isDisabled = false, onClick, onClear }: FiltersButtonProps) {
  const intl = useIntl();
  const hasActiveFilters = count > 0;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Button
        type="button"
        disabled={isDisabled}
        onClick={onClick}
        color="primary"
        variant="contained"
        data-gtm-id="inbox_filters_drawer_icon_button"
      >
        {intl.formatMessage({ id: 'inbox.filters', defaultMessage: 'Filters' })}
        {hasActiveFilters && ` (${count})`}
      </Button>
      {hasActiveFilters && (
        <Button type="button" onClick={onClear} data-gtm-id="inbox_filters_clear_button">
          {intl.formatMessage({ id: 'inbox.filters.clear', defaultMessage: 'Clear filters' })}
        </Button>
      )}
    </Box>
  );
}
