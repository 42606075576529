import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import type { Filters } from '@trustyou/shared';
import { FormControlLabel, Switch, Typography } from '@trustyou/ui';

import { useFilters } from '../../hooks/use-filters';

export function AnswerableReviewsOnlyFilter() {
  const { currentView } = useFilters();
  const { register } = useFormContext<Filters>();

  return (
    <FormControlLabel
      control={
        <Switch
          {...register('answerable')}
          defaultChecked={currentView.filters?.answerable ?? undefined}
        />
      }
      label={
        <>
          <Typography variant="body2" gutterBottom>
            <FormattedMessage
              id="inbox.filter.respondable.label"
              defaultMessage="Answerable reviews only"
            />
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <FormattedMessage
              id="inbox.filter.respondable.description"
              defaultMessage="Excludes reviews that sources do not allow responses to, such as reviews without text"
            />
          </Typography>
        </>
      }
      labelPlacement="start"
      componentsProps={{ typography: { variant: 'body2' } }}
      sx={{ justifyContent: 'space-between', alignItems: 'start' }}
      data-gtm-id="inbox_filters_only_show_respondable_reviews_switch"
    />
  );
}
